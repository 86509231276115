import axios from 'axios';
import { API } from 'aws-amplify';

export const downloadTwilioRecording = async (url, channel) => {
  try {
    const response = await fetch(`${url}?RequestedChannels=${channel}`, {
      method: 'GET',
    });

    if (!response.ok) {
      throw new Error('Network response was not ok.');
    }

    const blob = await response.blob();
    return URL.createObjectURL(blob);
  } catch (error) {
    console.error('Error fetching blob recording:', error);
    throw error;
  }
};

export const fetchTwilioRecording = async (url, channel, clientId, email) => {
  try {
    const token = localStorage.getItem('twilioRecordingAuth');
    const headers = {
      Authorization: `Basic ${token}`,
    };

    const response = await axios.get(url, {
      headers,
      responseType: 'arraybuffer',
    });
    // Convert the arraybuffer to a Blob
    const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });
    // Create a URL for the blob
    const audioUrl = URL.createObjectURL(audioBlob);
    return audioUrl;
  } catch (error) {
    console.error(`Error downloading the recording:`, error);
    return null;
  }
};

export const getRecordingAuth = async (clientId, email) => {
  const auth = await API.post('TwilioAPI', '/twilio/recordingAuthorize', {
    body: {
      clientId,
      email,
    },
  });

  return auth;
};
