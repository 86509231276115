// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const CapacityTypes = {
  "MANUAL": "MANUAL",
  "SERVICETITAN": "SERVICETITAN"
};

const ServiceTitanQueueStatus = {
  "CREATED": "CREATED",
  "COMPLETED": "COMPLETED",
  "FAILED": "FAILED"
};

const ServiceTitanQueueTypes = {
  "BULKIMPORT": "BULKIMPORT",
  "SYNC": "SYNC",
  "CAPACITYSYNC": "CAPACITYSYNC"
};

const UserTypes = {
  "ADMIN": "ADMIN",
  "TENANT": "TENANT",
  "CLIENT": "CLIENT"
};

const MappingTypes = {
  "BU": "BU",
  "CHANNEL": "CHANNEL",
  "CAMPAIGN": "CAMPAIGN",
  "SOCIAL_CAMPAIGN_TO_CHANNEL": "SOCIAL_CAMPAIGN_TO_CHANNEL"
};

const LeadTypes = {
  "EMAIL": "EMAIL",
  "PHONE": "PHONE",
  "SMS": "SMS",
  "WEBCHAT": "WEBCHAT",
  "FORMSUBMIT": "FORMSUBMIT",
  "ONLINEBOOKING": "ONLINEBOOKING"
};

const LeadStatuses = {
  "CREATED": "CREATED",
  "CLOSED": "CLOSED",
  "LOST": "LOST"
};

const JobStatuses = {
  "CREATED": "CREATED",
  "CANCELED": "CANCELED",
  "COMPLETED": "COMPLETED"
};

const IntegrationModelTypes = {
  "TENANT": "TENANT",
  "CLIENT": "CLIENT"
};

const Currencies = {
  "USD": "USD",
  "CAD": "CAD"
};

const { Tenants, Appointments, Clients, Users, Contacts, ContactDetails, Locations, Leads, Jobs, Invoices, InvoiceItems, BusinessUnits, Channels, Campaigns, IntegrationModels, Integrations, DNIPools, ProgrammableDNIPools, PhoneNumbers, Activity, WebSessions, ServiceTitanQueues, Employees, Dashboards, Equipment, Mappings, CapacitySettings, Capacities, BidderSettings, BudgetAdjustments, BudgetLogs, CostSyncSettings, ClientUsers, EmployeeLeads, EmployeeJobs, BusinessUnitMetrics, SocialAdsMetrics, LeadsConnection, Metrics, ChannelMetrics, TopMetrics, RecordingDetails } = initSchema(schema);

export {
  Tenants,
  Appointments,
  Clients,
  Users,
  Contacts,
  ContactDetails,
  Locations,
  Leads,
  Jobs,
  Invoices,
  InvoiceItems,
  BusinessUnits,
  Channels,
  Campaigns,
  IntegrationModels,
  Integrations,
  DNIPools,
  ProgrammableDNIPools,
  PhoneNumbers,
  Activity,
  WebSessions,
  ServiceTitanQueues,
  Employees,
  Dashboards,
  Equipment,
  Mappings,
  CapacitySettings,
  Capacities,
  BidderSettings,
  BudgetAdjustments,
  BudgetLogs,
  CostSyncSettings,
  ClientUsers,
  EmployeeLeads,
  EmployeeJobs,
  CapacityTypes,
  ServiceTitanQueueStatus,
  ServiceTitanQueueTypes,
  UserTypes,
  MappingTypes,
  LeadTypes,
  LeadStatuses,
  JobStatuses,
  IntegrationModelTypes,
  Currencies,
  BusinessUnitMetrics,
  SocialAdsMetrics,
  LeadsConnection,
  Metrics,
  ChannelMetrics,
  TopMetrics,
  RecordingDetails
};