import { useRef, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Avatar, Box, useMediaQuery } from "@mui/material";

// project import
import MainCard from "components/MainCard";
import IconButton from "components/@extended/IconButton";
import Transitions from "components/@extended/Transitions";

// assets

import {
  MailOutlined,
  CloseOutlined,
  EyeTwoTone,
  EyeOutlined,
} from "@ant-design/icons";
import useConfig from "hooks/useConfig";

// ==============================|| HEADER CONTENT - MESSAGES ||============================== //

const Mode = () => {
  const theme = useTheme();
  const config = useConfig();
  const matchesXs = useMediaQuery(theme.breakpoints.down("md"));

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    config.onChangeMode(config.mode === "dark" ? "light" : "dark");
  };

  const iconBackColorOpen =
    theme.palette.mode === "dark" ? "grey.200" : "grey.300";
  const iconBackColor =
    theme.palette.mode === "dark" ? "background.default" : "grey.100";

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <IconButton
        color="secondary"
        variant="light"
        sx={{
          color: config.mode === "dark" ? "gold" : "text.primary",
          bgcolor: open ? iconBackColorOpen : iconBackColor,
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? "profile-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <EyeOutlined />
      </IconButton>
    </Box>
  );
};

export default Mode;
