import { Box, Card, useTheme, Typography } from "@mui/material";
import Chart from "react-apexcharts";
import useAuth from "hooks/useAuth";

const WelcomeCard = () => {
  const theme = useTheme();
  const { user } = useAuth();
  return (
    <Card
      sx={{
        padding: 3,
        height: "100%",
      }}
    >
      <Box
        alignItems="center"
        justifyContent="space-between"
        sx={{
          [theme.breakpoints.down(630)]: {
            textAlign: "center",
            flexDirection: "column-reverse",
            "& img": {
              marginBottom: 1,
            },
          },
        }}
      >
        <Box>
          <Typography mb={0.5} fontWeight={700}>
            {`Hi,`}
            {user?.name ? ` ${user?.name?.split(" ")[0]}!` : null}
          </Typography>
          <Typography color="text.primary" display="block">
            Welcome to Profitfill
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};

export default WelcomeCard;
