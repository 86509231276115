/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Badge,
  Button,
  Divider,
  Flex,
  Grid,
  Icon,
  ScrollView,
  SelectField,
  Text,
  TextAreaField,
  TextField,
  useTheme,
} from "@aws-amplify/ui-react";
import {
  Leads,
  BusinessUnits,
  Campaigns,
  Channels,
  Clients,
  Contacts,
} from "../models";
import {
  fetchByPath,
  getOverrideProps,
  useDataStoreBinding,
  validateField,
} from "./utils";
import { DataStore } from "aws-amplify";
function ArrayField({
  items = [],
  onChange,
  label,
  inputFieldRef,
  children,
  hasError,
  setFieldValue,
  currentFieldValue,
  defaultFieldValue,
  lengthLimit,
  getBadgeText,
  runValidationTasks,
  errorMessage,
}) {
  const labelElement = <Text>{label}</Text>;
  const {
    tokens: {
      components: {
        fieldmessages: { error: errorStyles },
      },
    },
  } = useTheme();
  const [selectedBadgeIndex, setSelectedBadgeIndex] = React.useState();
  const [isEditing, setIsEditing] = React.useState();
  React.useEffect(() => {
    if (isEditing) {
      inputFieldRef?.current?.focus();
    }
  }, [isEditing]);
  const removeItem = async (removeIndex) => {
    const newItems = items.filter((value, index) => index !== removeIndex);
    await onChange(newItems);
    setSelectedBadgeIndex(undefined);
  };
  const addItem = async () => {
    const { hasError } = runValidationTasks();
    if (
      currentFieldValue !== undefined &&
      currentFieldValue !== null &&
      currentFieldValue !== "" &&
      !hasError
    ) {
      const newItems = [...items];
      if (selectedBadgeIndex !== undefined) {
        newItems[selectedBadgeIndex] = currentFieldValue;
        setSelectedBadgeIndex(undefined);
      } else {
        newItems.push(currentFieldValue);
      }
      await onChange(newItems);
      setIsEditing(false);
    }
  };
  const arraySection = (
    <React.Fragment>
      {!!items?.length && (
        <ScrollView height="inherit" width="inherit" maxHeight={"7rem"}>
          {items.map((value, index) => {
            return (
              <Badge
                key={index}
                style={{
                  cursor: "pointer",
                  alignItems: "center",
                  marginRight: 3,
                  marginTop: 3,
                  backgroundColor:
                    index === selectedBadgeIndex ? "#B8CEF9" : "",
                }}
                onClick={() => {
                  setSelectedBadgeIndex(index);
                  setFieldValue(items[index]);
                  setIsEditing(true);
                }}
              >
                {getBadgeText ? getBadgeText(value) : value.toString()}
                <Icon
                  style={{
                    cursor: "pointer",
                    paddingLeft: 3,
                    width: 20,
                    height: 20,
                  }}
                  viewBox={{ width: 20, height: 20 }}
                  paths={[
                    {
                      d: "M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z",
                      stroke: "black",
                    },
                  ]}
                  ariaLabel="button"
                  onClick={(event) => {
                    event.stopPropagation();
                    removeItem(index);
                  }}
                />
              </Badge>
            );
          })}
        </ScrollView>
      )}
      <Divider orientation="horizontal" marginTop={5} />
    </React.Fragment>
  );
  if (lengthLimit !== undefined && items.length >= lengthLimit && !isEditing) {
    return (
      <React.Fragment>
        {labelElement}
        {arraySection}
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      {labelElement}
      {isEditing && children}
      {!isEditing ? (
        <>
          <Button
            onClick={() => {
              setIsEditing(true);
            }}
          >
            Add item
          </Button>
          {errorMessage && hasError && (
            <Text color={errorStyles.color} fontSize={errorStyles.fontSize}>
              {errorMessage}
            </Text>
          )}
        </>
      ) : (
        <Flex justifyContent="flex-end">
          {(currentFieldValue || isEditing) && (
            <Button
              children="Cancel"
              type="button"
              size="small"
              onClick={() => {
                setFieldValue(defaultFieldValue);
                setIsEditing(false);
                setSelectedBadgeIndex(undefined);
              }}
            ></Button>
          )}
          <Button size="small" variation="link" onClick={addItem}>
            {selectedBadgeIndex !== undefined ? "Save" : "Add"}
          </Button>
        </Flex>
      )}
      {arraySection}
    </React.Fragment>
  );
}
export default function CreateLeads2(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    name: "",
    description: "",
    type: "",
    BusinessUnitID: "",
    CampaignID: "",
    ChannelID: "",
    ClientID: "",
    ContactID: "",
    details: "",
    value: "",
    status: "",
    twillioSID: "",
    email: "",
    phoneNumber: "",
    crmID: "",
    source: "",
    createdAt: "",
    callDuration: "",
  };
  const [name, setName] = React.useState(initialValues.name);
  const [description, setDescription] = React.useState(
    initialValues.description
  );
  const [type, setType] = React.useState(initialValues.type);
  const [BusinessUnitID, setBusinessUnitID] = React.useState(
    initialValues.BusinessUnitID
  );
  const [CampaignID, setCampaignID] = React.useState(initialValues.CampaignID);
  const [ChannelID, setChannelID] = React.useState(initialValues.ChannelID);
  const [ClientID, setClientID] = React.useState(initialValues.ClientID);
  const [ContactID, setContactID] = React.useState(initialValues.ContactID);
  const [details, setDetails] = React.useState(initialValues.details);
  const [value, setValue] = React.useState(initialValues.value);
  const [status, setStatus] = React.useState(initialValues.status);
  const [twillioSID, setTwillioSID] = React.useState(initialValues.twillioSID);
  const [email, setEmail] = React.useState(initialValues.email);
  const [phoneNumber, setPhoneNumber] = React.useState(
    initialValues.phoneNumber
  );
  const [crmID, setCrmID] = React.useState(initialValues.crmID);
  const [source, setSource] = React.useState(initialValues.source);
  const [createdAt, setCreatedAt] = React.useState(initialValues.createdAt);
  const [callDuration, setCallDuration] = React.useState(
    initialValues.callDuration
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setName(initialValues.name);
    setDescription(initialValues.description);
    setType(initialValues.type);
    setBusinessUnitID(initialValues.BusinessUnitID);
    setCurrentBusinessUnitIDValue("");
    setCurrentBusinessUnitIDDisplayValue("");
    setCampaignID(initialValues.CampaignID);
    setCurrentCampaignIDValue("");
    setCurrentCampaignIDDisplayValue("");
    setChannelID(initialValues.ChannelID);
    setCurrentChannelIDValue("");
    setCurrentChannelIDDisplayValue("");
    setClientID(initialValues.ClientID);
    setCurrentClientIDValue("");
    setCurrentClientIDDisplayValue("");
    setContactID(initialValues.ContactID);
    setCurrentContactIDValue("");
    setCurrentContactIDDisplayValue("");
    setDetails(initialValues.details);
    setValue(initialValues.value);
    setStatus(initialValues.status);
    setTwillioSID(initialValues.twillioSID);
    setEmail(initialValues.email);
    setPhoneNumber(initialValues.phoneNumber);
    setCrmID(initialValues.crmID);
    setSource(initialValues.source);
    setCreatedAt(initialValues.createdAt);
    setCallDuration(initialValues.callDuration);
    setErrors({});
  };
  const [
    currentBusinessUnitIDDisplayValue,
    setCurrentBusinessUnitIDDisplayValue,
  ] = React.useState("");
  const [currentBusinessUnitIDValue, setCurrentBusinessUnitIDValue] =
    React.useState("");
  const BusinessUnitIDRef = React.createRef();
  const [currentCampaignIDDisplayValue, setCurrentCampaignIDDisplayValue] =
    React.useState("");
  const [currentCampaignIDValue, setCurrentCampaignIDValue] =
    React.useState("");
  const CampaignIDRef = React.createRef();
  const [currentChannelIDDisplayValue, setCurrentChannelIDDisplayValue] =
    React.useState("");
  const [currentChannelIDValue, setCurrentChannelIDValue] = React.useState("");
  const ChannelIDRef = React.createRef();
  const [currentClientIDDisplayValue, setCurrentClientIDDisplayValue] =
    React.useState("");
  const [currentClientIDValue, setCurrentClientIDValue] = React.useState("");
  const ClientIDRef = React.createRef();
  const [currentContactIDDisplayValue, setCurrentContactIDDisplayValue] =
    React.useState("");
  const [currentContactIDValue, setCurrentContactIDValue] = React.useState("");
  const ContactIDRef = React.createRef();
  const businessUnitsRecords = useDataStoreBinding({
    type: "collection",
    model: BusinessUnits,
  }).items;
  const campaignsRecords = useDataStoreBinding({
    type: "collection",
    model: Campaigns,
  }).items;
  const channelsRecords = useDataStoreBinding({
    type: "collection",
    model: Channels,
  }).items;
  const clientsRecords = useDataStoreBinding({
    type: "collection",
    model: Clients,
  }).items;
  const contactsRecords = useDataStoreBinding({
    type: "collection",
    model: Contacts,
  }).items;
  const getDisplayValue = {
    BusinessUnitID: (r) => `${r?.name ? r?.name + " - " : ""}${r?.id}`,
    CampaignID: (r) => `${r?.name ? r?.name + " - " : ""}${r?.id}`,
    ChannelID: (r) => `${r?.name ? r?.name + " - " : ""}${r?.id}`,
    ClientID: (r) => `${r?.name ? r?.name + " - " : ""}${r?.id}`,
    ContactID: (r) => `${r?.name ? r?.name + " - " : ""}${r?.id}`,
  };
  const validations = {
    name: [],
    description: [],
    type: [],
    BusinessUnitID: [],
    CampaignID: [],
    ChannelID: [],
    ClientID: [{ type: "Required" }],
    ContactID: [],
    details: [{ type: "JSON" }],
    value: [],
    status: [],
    twillioSID: [],
    email: [],
    phoneNumber: [],
    crmID: [],
    source: [],
    createdAt: [],
    callDuration: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          name,
          description,
          type,
          BusinessUnitID,
          CampaignID,
          ChannelID,
          ClientID,
          ContactID,
          details,
          value,
          status,
          twillioSID,
          email,
          phoneNumber,
          crmID,
          source,
          createdAt,
          callDuration,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(new Leads(modelFields));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "CreateLeads2")}
      {...rest}
    >
      <TextField
        label="Name"
        isRequired={false}
        isReadOnly={false}
        value={name}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name: value,
              description,
              type,
              BusinessUnitID,
              CampaignID,
              ChannelID,
              ClientID,
              ContactID,
              details,
              value,
              status,
              twillioSID,
              email,
              phoneNumber,
              crmID,
              source,
              createdAt,
              callDuration,
            };
            const result = onChange(modelFields);
            value = result?.name ?? value;
          }
          if (errors.name?.hasError) {
            runValidationTasks("name", value);
          }
          setName(value);
        }}
        onBlur={() => runValidationTasks("name", name)}
        errorMessage={errors.name?.errorMessage}
        hasError={errors.name?.hasError}
        {...getOverrideProps(overrides, "name")}
      ></TextField>
      <TextField
        label="Description"
        isRequired={false}
        isReadOnly={false}
        value={description}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              description: value,
              type,
              BusinessUnitID,
              CampaignID,
              ChannelID,
              ClientID,
              ContactID,
              details,
              value,
              status,
              twillioSID,
              email,
              phoneNumber,
              crmID,
              source,
              createdAt,
              callDuration,
            };
            const result = onChange(modelFields);
            value = result?.description ?? value;
          }
          if (errors.description?.hasError) {
            runValidationTasks("description", value);
          }
          setDescription(value);
        }}
        onBlur={() => runValidationTasks("description", description)}
        errorMessage={errors.description?.errorMessage}
        hasError={errors.description?.hasError}
        {...getOverrideProps(overrides, "description")}
      ></TextField>
      <SelectField
        label="Type"
        placeholder="Please select an option"
        isDisabled={false}
        value={type}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              description,
              type: value,
              BusinessUnitID,
              CampaignID,
              ChannelID,
              ClientID,
              ContactID,
              details,
              value,
              status,
              twillioSID,
              email,
              phoneNumber,
              crmID,
              source,
              createdAt,
              callDuration,
            };
            const result = onChange(modelFields);
            value = result?.type ?? value;
          }
          if (errors.type?.hasError) {
            runValidationTasks("type", value);
          }
          setType(value);
        }}
        onBlur={() => runValidationTasks("type", type)}
        errorMessage={errors.type?.errorMessage}
        hasError={errors.type?.hasError}
        {...getOverrideProps(overrides, "type")}
      >
        <option
          children="Email"
          value="EMAIL"
          {...getOverrideProps(overrides, "typeoption0")}
        ></option>
        <option
          children="Phone"
          value="PHONE"
          {...getOverrideProps(overrides, "typeoption1")}
        ></option>
        <option
          children="Sms"
          value="SMS"
          {...getOverrideProps(overrides, "typeoption2")}
        ></option>
        <option
          children="Webchat"
          value="WEBCHAT"
          {...getOverrideProps(overrides, "typeoption3")}
        ></option>
        <option
          children="Formsubmit"
          value="FORMSUBMIT"
          {...getOverrideProps(overrides, "typeoption4")}
        ></option>
        <option
          children="Onlinebooking"
          value="ONLINEBOOKING"
          {...getOverrideProps(overrides, "typeoption5")}
        ></option>
      </SelectField>
      <ArrayField
        lengthLimit={1}
        onChange={async (items) => {
          let value = items[0];
          if (onChange) {
            const modelFields = {
              name,
              description,
              type,
              BusinessUnitID: value,
              CampaignID,
              ChannelID,
              ClientID,
              ContactID,
              details,
              value,
              status,
              twillioSID,
              email,
              phoneNumber,
              crmID,
              source,
              createdAt,
              callDuration,
            };
            const result = onChange(modelFields);
            value = result?.BusinessUnitID ?? value;
          }
          setBusinessUnitID(value);
          setCurrentBusinessUnitIDValue("");
        }}
        currentFieldValue={currentBusinessUnitIDValue}
        label={"Business unit id"}
        items={BusinessUnitID ? [BusinessUnitID] : []}
        hasError={errors?.BusinessUnitID?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("BusinessUnitID", currentBusinessUnitIDValue)
        }
        errorMessage={errors?.BusinessUnitID?.errorMessage}
        getBadgeText={(value) =>
          value
            ? getDisplayValue.BusinessUnitID(
                businessUnitsRecords.find((r) => r.id === value)
              )
            : ""
        }
        setFieldValue={(value) => {
          setCurrentBusinessUnitIDDisplayValue(
            value
              ? getDisplayValue.BusinessUnitID(
                  businessUnitsRecords.find((r) => r.id === value)
                )
              : ""
          );
          setCurrentBusinessUnitIDValue(value);
        }}
        inputFieldRef={BusinessUnitIDRef}
        defaultFieldValue={""}
      >
        <SelectField
          label="Business unit id"
          placeholder="Search BusinessUnits"
          isDisabled={false}
          value={currentBusinessUnitIDDisplayValue}
          onChange={(e) => {
            let { value } = e.target;
            if (errors.BusinessUnitID?.hasError) {
              runValidationTasks("BusinessUnitID", value);
            }
            setCurrentBusinessUnitIDDisplayValue(value);
            setCurrentBusinessUnitIDValue(undefined);
          }}
          onBlur={() =>
            runValidationTasks("BusinessUnitID", currentBusinessUnitIDValue)
          }
          errorMessage={errors.BusinessUnitID?.errorMessage}
          hasError={errors.BusinessUnitID?.hasError}
          ref={BusinessUnitIDRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "BusinessUnitID")}
        >
          <option
            children={`${r?.name}${" - "}${r?.id}`}
            value={BusinessUnits?.id}
            {...getOverrideProps(overrides, "BusinessUnitIDoption0")}
          ></option>
        </SelectField>
      </ArrayField>
      <ArrayField
        lengthLimit={1}
        onChange={async (items) => {
          let value = items[0];
          if (onChange) {
            const modelFields = {
              name,
              description,
              type,
              BusinessUnitID,
              CampaignID: value,
              ChannelID,
              ClientID,
              ContactID,
              details,
              value,
              status,
              twillioSID,
              email,
              phoneNumber,
              crmID,
              source,
              createdAt,
              callDuration,
            };
            const result = onChange(modelFields);
            value = result?.CampaignID ?? value;
          }
          setCampaignID(value);
          setCurrentCampaignIDValue("");
        }}
        currentFieldValue={currentCampaignIDValue}
        label={"Campaign id"}
        items={CampaignID ? [CampaignID] : []}
        hasError={errors?.CampaignID?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("CampaignID", currentCampaignIDValue)
        }
        errorMessage={errors?.CampaignID?.errorMessage}
        getBadgeText={(value) =>
          value
            ? getDisplayValue.CampaignID(
                campaignsRecords.find((r) => r.id === value)
              )
            : ""
        }
        setFieldValue={(value) => {
          setCurrentCampaignIDDisplayValue(
            value
              ? getDisplayValue.CampaignID(
                  campaignsRecords.find((r) => r.id === value)
                )
              : ""
          );
          setCurrentCampaignIDValue(value);
        }}
        inputFieldRef={CampaignIDRef}
        defaultFieldValue={""}
      >
        <SelectField
          label="Campaign id"
          placeholder="Search Campaigns"
          isDisabled={false}
          value={currentCampaignIDDisplayValue}
          onChange={(e) => {
            let { value } = e.target;
            if (errors.CampaignID?.hasError) {
              runValidationTasks("CampaignID", value);
            }
            setCurrentCampaignIDDisplayValue(value);
            setCurrentCampaignIDValue(undefined);
          }}
          onBlur={() =>
            runValidationTasks("CampaignID", currentCampaignIDValue)
          }
          errorMessage={errors.CampaignID?.errorMessage}
          hasError={errors.CampaignID?.hasError}
          ref={CampaignIDRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "CampaignID")}
        >
          <option
            children={`${r?.name}${" - "}${r?.id}`}
            value={Campaigns?.id}
            {...getOverrideProps(overrides, "CampaignIDoption0")}
          ></option>
        </SelectField>
      </ArrayField>
      <ArrayField
        lengthLimit={1}
        onChange={async (items) => {
          let value = items[0];
          if (onChange) {
            const modelFields = {
              name,
              description,
              type,
              BusinessUnitID,
              CampaignID,
              ChannelID: value,
              ClientID,
              ContactID,
              details,
              value,
              status,
              twillioSID,
              email,
              phoneNumber,
              crmID,
              source,
              createdAt,
              callDuration,
            };
            const result = onChange(modelFields);
            value = result?.ChannelID ?? value;
          }
          setChannelID(value);
          setCurrentChannelIDValue("");
        }}
        currentFieldValue={currentChannelIDValue}
        label={"Channel id"}
        items={ChannelID ? [ChannelID] : []}
        hasError={errors?.ChannelID?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("ChannelID", currentChannelIDValue)
        }
        errorMessage={errors?.ChannelID?.errorMessage}
        getBadgeText={(value) =>
          value
            ? getDisplayValue.ChannelID(
                channelsRecords.find((r) => r.id === value)
              )
            : ""
        }
        setFieldValue={(value) => {
          setCurrentChannelIDDisplayValue(
            value
              ? getDisplayValue.ChannelID(
                  channelsRecords.find((r) => r.id === value)
                )
              : ""
          );
          setCurrentChannelIDValue(value);
        }}
        inputFieldRef={ChannelIDRef}
        defaultFieldValue={""}
      >
        <SelectField
          label="Channel id"
          placeholder="Search Channels"
          isDisabled={false}
          value={currentChannelIDDisplayValue}
          onChange={(e) => {
            let { value } = e.target;
            if (errors.ChannelID?.hasError) {
              runValidationTasks("ChannelID", value);
            }
            setCurrentChannelIDDisplayValue(value);
            setCurrentChannelIDValue(undefined);
          }}
          onBlur={() => runValidationTasks("ChannelID", currentChannelIDValue)}
          errorMessage={errors.ChannelID?.errorMessage}
          hasError={errors.ChannelID?.hasError}
          ref={ChannelIDRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "ChannelID")}
        >
          <option
            children={`${r?.name}${" - "}${r?.id}`}
            value={Channels?.id}
            {...getOverrideProps(overrides, "ChannelIDoption0")}
          ></option>
        </SelectField>
      </ArrayField>
      <ArrayField
        lengthLimit={1}
        onChange={async (items) => {
          let value = items[0];
          if (onChange) {
            const modelFields = {
              name,
              description,
              type,
              BusinessUnitID,
              CampaignID,
              ChannelID,
              ClientID: value,
              ContactID,
              details,
              value,
              status,
              twillioSID,
              email,
              phoneNumber,
              crmID,
              source,
              createdAt,
              callDuration,
            };
            const result = onChange(modelFields);
            value = result?.ClientID ?? value;
          }
          setClientID(value);
          setCurrentClientIDValue("");
        }}
        currentFieldValue={currentClientIDValue}
        label={"Client id"}
        items={ClientID ? [ClientID] : []}
        hasError={errors?.ClientID?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("ClientID", currentClientIDValue)
        }
        errorMessage={errors?.ClientID?.errorMessage}
        getBadgeText={(value) =>
          value
            ? getDisplayValue.ClientID(
                clientsRecords.find((r) => r.id === value)
              )
            : ""
        }
        setFieldValue={(value) => {
          setCurrentClientIDDisplayValue(
            value
              ? getDisplayValue.ClientID(
                  clientsRecords.find((r) => r.id === value)
                )
              : ""
          );
          setCurrentClientIDValue(value);
        }}
        inputFieldRef={ClientIDRef}
        defaultFieldValue={""}
      >
        <SelectField
          label="Client id"
          placeholder="Search Clients"
          isDisabled={false}
          value={currentClientIDDisplayValue}
          onChange={(e) => {
            let { value } = e.target;
            if (errors.ClientID?.hasError) {
              runValidationTasks("ClientID", value);
            }
            setCurrentClientIDDisplayValue(value);
            setCurrentClientIDValue(undefined);
          }}
          onBlur={() => runValidationTasks("ClientID", currentClientIDValue)}
          errorMessage={errors.ClientID?.errorMessage}
          hasError={errors.ClientID?.hasError}
          ref={ClientIDRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "ClientID")}
        >
          <option
            children={`${r?.name}${" - "}${r?.id}`}
            value={Clients?.id}
            {...getOverrideProps(overrides, "ClientIDoption0")}
          ></option>
        </SelectField>
      </ArrayField>
      <ArrayField
        lengthLimit={1}
        onChange={async (items) => {
          let value = items[0];
          if (onChange) {
            const modelFields = {
              name,
              description,
              type,
              BusinessUnitID,
              CampaignID,
              ChannelID,
              ClientID,
              ContactID: value,
              details,
              value,
              status,
              twillioSID,
              email,
              phoneNumber,
              crmID,
              source,
              createdAt,
              callDuration,
            };
            const result = onChange(modelFields);
            value = result?.ContactID ?? value;
          }
          setContactID(value);
          setCurrentContactIDValue("");
        }}
        currentFieldValue={currentContactIDValue}
        label={"Contact id"}
        items={ContactID ? [ContactID] : []}
        hasError={errors?.ContactID?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("ContactID", currentContactIDValue)
        }
        errorMessage={errors?.ContactID?.errorMessage}
        getBadgeText={(value) =>
          value
            ? getDisplayValue.ContactID(
                contactsRecords.find((r) => r.id === value)
              )
            : ""
        }
        setFieldValue={(value) => {
          setCurrentContactIDDisplayValue(
            value
              ? getDisplayValue.ContactID(
                  contactsRecords.find((r) => r.id === value)
                )
              : ""
          );
          setCurrentContactIDValue(value);
        }}
        inputFieldRef={ContactIDRef}
        defaultFieldValue={""}
      >
        <SelectField
          label="Contact id"
          placeholder="Search Contacts"
          isDisabled={false}
          value={currentContactIDDisplayValue}
          onChange={(e) => {
            let { value } = e.target;
            if (errors.ContactID?.hasError) {
              runValidationTasks("ContactID", value);
            }
            setCurrentContactIDDisplayValue(value);
            setCurrentContactIDValue(undefined);
          }}
          onBlur={() => runValidationTasks("ContactID", currentContactIDValue)}
          errorMessage={errors.ContactID?.errorMessage}
          hasError={errors.ContactID?.hasError}
          ref={ContactIDRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "ContactID")}
        >
          <option
            children={`${r?.name}${" - "}${r?.id}`}
            value={Contacts?.id}
            {...getOverrideProps(overrides, "ContactIDoption0")}
          ></option>
        </SelectField>
      </ArrayField>
      <TextAreaField
        label="Details"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              description,
              type,
              BusinessUnitID,
              CampaignID,
              ChannelID,
              ClientID,
              ContactID,
              details: value,
              value,
              status,
              twillioSID,
              email,
              phoneNumber,
              crmID,
              source,
              createdAt,
              callDuration,
            };
            const result = onChange(modelFields);
            value = result?.details ?? value;
          }
          if (errors.details?.hasError) {
            runValidationTasks("details", value);
          }
          setDetails(value);
        }}
        onBlur={() => runValidationTasks("details", details)}
        errorMessage={errors.details?.errorMessage}
        hasError={errors.details?.hasError}
        {...getOverrideProps(overrides, "details")}
      ></TextAreaField>
      <TextField
        label="Value"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={value}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              name,
              description,
              type,
              BusinessUnitID,
              CampaignID,
              ChannelID,
              ClientID,
              ContactID,
              details,
              value: value,
              status,
              twillioSID,
              email,
              phoneNumber,
              crmID,
              source,
              createdAt,
              callDuration,
            };
            const result = onChange(modelFields);
            value = result?.value ?? value;
          }
          if (errors.value?.hasError) {
            runValidationTasks("value", value);
          }
          setValue(value);
        }}
        onBlur={() => runValidationTasks("value", value)}
        errorMessage={errors.value?.errorMessage}
        hasError={errors.value?.hasError}
        {...getOverrideProps(overrides, "value")}
      ></TextField>
      <SelectField
        label="Status"
        placeholder="Please select an option"
        isDisabled={false}
        value={status}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              description,
              type,
              BusinessUnitID,
              CampaignID,
              ChannelID,
              ClientID,
              ContactID,
              details,
              value,
              status: value,
              twillioSID,
              email,
              phoneNumber,
              crmID,
              source,
              createdAt,
              callDuration,
            };
            const result = onChange(modelFields);
            value = result?.status ?? value;
          }
          if (errors.status?.hasError) {
            runValidationTasks("status", value);
          }
          setStatus(value);
        }}
        onBlur={() => runValidationTasks("status", status)}
        errorMessage={errors.status?.errorMessage}
        hasError={errors.status?.hasError}
        {...getOverrideProps(overrides, "status")}
      >
        <option
          children="Created"
          value="CREATED"
          {...getOverrideProps(overrides, "statusoption0")}
        ></option>
        <option
          children="Closed"
          value="CLOSED"
          {...getOverrideProps(overrides, "statusoption1")}
        ></option>
        <option
          children="Lost"
          value="LOST"
          {...getOverrideProps(overrides, "statusoption2")}
        ></option>
      </SelectField>
      <TextField
        label="Twillio sid"
        isRequired={false}
        isReadOnly={false}
        value={twillioSID}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              description,
              type,
              BusinessUnitID,
              CampaignID,
              ChannelID,
              ClientID,
              ContactID,
              details,
              value,
              status,
              twillioSID: value,
              email,
              phoneNumber,
              crmID,
              source,
              createdAt,
              callDuration,
            };
            const result = onChange(modelFields);
            value = result?.twillioSID ?? value;
          }
          if (errors.twillioSID?.hasError) {
            runValidationTasks("twillioSID", value);
          }
          setTwillioSID(value);
        }}
        onBlur={() => runValidationTasks("twillioSID", twillioSID)}
        errorMessage={errors.twillioSID?.errorMessage}
        hasError={errors.twillioSID?.hasError}
        {...getOverrideProps(overrides, "twillioSID")}
      ></TextField>
      <TextField
        label="Email"
        isRequired={false}
        isReadOnly={false}
        value={email}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              description,
              type,
              BusinessUnitID,
              CampaignID,
              ChannelID,
              ClientID,
              ContactID,
              details,
              value,
              status,
              twillioSID,
              email: value,
              phoneNumber,
              crmID,
              source,
              createdAt,
              callDuration,
            };
            const result = onChange(modelFields);
            value = result?.email ?? value;
          }
          if (errors.email?.hasError) {
            runValidationTasks("email", value);
          }
          setEmail(value);
        }}
        onBlur={() => runValidationTasks("email", email)}
        errorMessage={errors.email?.errorMessage}
        hasError={errors.email?.hasError}
        {...getOverrideProps(overrides, "email")}
      ></TextField>
      <TextField
        label="Phone number"
        isRequired={false}
        isReadOnly={false}
        value={phoneNumber}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              description,
              type,
              BusinessUnitID,
              CampaignID,
              ChannelID,
              ClientID,
              ContactID,
              details,
              value,
              status,
              twillioSID,
              email,
              phoneNumber: value,
              crmID,
              source,
              createdAt,
              callDuration,
            };
            const result = onChange(modelFields);
            value = result?.phoneNumber ?? value;
          }
          if (errors.phoneNumber?.hasError) {
            runValidationTasks("phoneNumber", value);
          }
          setPhoneNumber(value);
        }}
        onBlur={() => runValidationTasks("phoneNumber", phoneNumber)}
        errorMessage={errors.phoneNumber?.errorMessage}
        hasError={errors.phoneNumber?.hasError}
        {...getOverrideProps(overrides, "phoneNumber")}
      ></TextField>
      <TextField
        label="Crm id"
        isRequired={false}
        isReadOnly={false}
        value={crmID}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              description,
              type,
              BusinessUnitID,
              CampaignID,
              ChannelID,
              ClientID,
              ContactID,
              details,
              value,
              status,
              twillioSID,
              email,
              phoneNumber,
              crmID: value,
              source,
              createdAt,
              callDuration,
            };
            const result = onChange(modelFields);
            value = result?.crmID ?? value;
          }
          if (errors.crmID?.hasError) {
            runValidationTasks("crmID", value);
          }
          setCrmID(value);
        }}
        onBlur={() => runValidationTasks("crmID", crmID)}
        errorMessage={errors.crmID?.errorMessage}
        hasError={errors.crmID?.hasError}
        {...getOverrideProps(overrides, "crmID")}
      ></TextField>
      <TextField
        label="Source"
        isRequired={false}
        isReadOnly={false}
        value={source}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              description,
              type,
              BusinessUnitID,
              CampaignID,
              ChannelID,
              ClientID,
              ContactID,
              details,
              value,
              status,
              twillioSID,
              email,
              phoneNumber,
              crmID,
              source: value,
              createdAt,
              callDuration,
            };
            const result = onChange(modelFields);
            value = result?.source ?? value;
          }
          if (errors.source?.hasError) {
            runValidationTasks("source", value);
          }
          setSource(value);
        }}
        onBlur={() => runValidationTasks("source", source)}
        errorMessage={errors.source?.errorMessage}
        hasError={errors.source?.hasError}
        {...getOverrideProps(overrides, "source")}
      ></TextField>
      <TextField
        label="Created at"
        isRequired={false}
        isReadOnly={false}
        value={createdAt}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              description,
              type,
              BusinessUnitID,
              CampaignID,
              ChannelID,
              ClientID,
              ContactID,
              details,
              value,
              status,
              twillioSID,
              email,
              phoneNumber,
              crmID,
              source,
              createdAt: value,
              callDuration,
            };
            const result = onChange(modelFields);
            value = result?.createdAt ?? value;
          }
          if (errors.createdAt?.hasError) {
            runValidationTasks("createdAt", value);
          }
          setCreatedAt(value);
        }}
        onBlur={() => runValidationTasks("createdAt", createdAt)}
        errorMessage={errors.createdAt?.errorMessage}
        hasError={errors.createdAt?.hasError}
        {...getOverrideProps(overrides, "createdAt")}
      ></TextField>
      <TextField
        label="Call duration"
        isRequired={false}
        isReadOnly={false}
        value={callDuration}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              description,
              type,
              BusinessUnitID,
              CampaignID,
              ChannelID,
              ClientID,
              ContactID,
              details,
              value,
              status,
              twillioSID,
              email,
              phoneNumber,
              crmID,
              source,
              createdAt,
              callDuration: value,
            };
            const result = onChange(modelFields);
            value = result?.callDuration ?? value;
          }
          if (errors.callDuration?.hasError) {
            runValidationTasks("callDuration", value);
          }
          setCallDuration(value);
        }}
        onBlur={() => runValidationTasks("callDuration", callDuration)}
        errorMessage={errors.callDuration?.errorMessage}
        hasError={errors.callDuration?.hasError}
        {...getOverrideProps(overrides, "callDuration")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
