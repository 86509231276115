import PropTypes from "prop-types";
import { createContext, useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { useLocation, useParams } from "react-router";

// ==============================|| FETCH CONTEXT & PROVIDER ||============================== //

const initialState = {};
const FetchContext = createContext(initialState);

function FetchProvider({ children }) {
  // const location = useLocation();
  const params = useParams();
  const [getData, setGetData] = useState({});
  const [listData, setListData] = useState({});
  const [listLoading, setListLoading] = useState(true);
  const [getLoading, setGetLoading] = useState(true);
  const [tokens, setTokens] = useState({});

  // useEffect(() => {
  //   if (!location) return;
  //   setListData({});
  //   setTokens({});
  // }, [location]);

  const wipeContext = () => {
    setGetData({});
    setListData({});
    setListLoading(true);
    setGetLoading(true);
    setTokens({});
  };

  const executeListQuery = async (query, queryName, variables, next) => {
    console.log(`Fetching: ${queryName}`);
    setListLoading(true);
    const response = await API.graphql(
      graphqlOperation(query, {
        limit: 500,
        ...variables,
        nextToken: next,
      })
    );
    if (response) {
      // console.log({ ...listData, [queryName]: response.data[queryName].items });
      let existing = listData[queryName]?.slice();
      let newNext = response.data[queryName].nextToken;
      let newData = response.data[queryName].items?.filter(
        (item) => !item._deleted
      );
      if (existing?.length && tokens[queryName]) {
        // console.log('running 1')
        existing = existing.concat(newData);
      }
      if (existing?.length && !tokens[queryName]) {
        // console.log('running 2')
        setListData({ [queryName]: existing });
        setTokens({ [queryName]: newNext });
      } else {
        // console.log('running 3')
        setListData({
          ...listData,
          [queryName]: existing ? existing : newData,
        });
      }
      if (newNext) {
        setTokens({
          ...tokens,
          [queryName]: newNext,
        });
      }
      setListLoading(false);
    }
  };

  const executeGetQuery = async (query, queryName, variables) => {
    console.log(`Fetching: ${queryName}`);
    setGetLoading(true);
    const response = await API.graphql(
      graphqlOperation(query, {
        ...variables,
      })
    );
    if (response) {
      // console.log({ ...getData, [queryName]: response.data[queryName] });
      setGetData({ ...getData, [queryName]: response.data[queryName] });
      setGetLoading(false);
    }
  };

  return (
    <FetchContext.Provider
      value={{
        getData,
        listData,
        getLoading,
        listLoading,
        executeGetQuery,
        executeListQuery,
        tokens,
        setTokens,
        wipeContext,
      }}
    >
      {children}
    </FetchContext.Provider>
  );
}

FetchProvider.propTypes = {
  children: PropTypes.node,
};

export { FetchProvider, FetchContext };
