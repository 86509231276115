import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";

// project import
import Logo from "components/logo";
import { Box } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";

import Robot1 from "../../../../assets/profitfill-robot/ProfitFillMascot_Final-01.svg";
import Robot2 from "../../../../assets/profitfill-robot/ProfitFillMascot_Final-02.svg";
import Robot3 from "../../../../assets/profitfill-robot/ProfitFillMascot_Final-03.svg";
import Robot4 from "../../../../assets/profitfill-robot/ProfitFillMascot_Final-04.svg";
import Robot5 from "../../../../assets/profitfill-robot/ProfitFillMascot_Final-05.svg";
import Robot6 from "../../../../assets/profitfill-robot/ProfitFillMascot_Final-06.svg";
import Robot7 from "../../../../assets/profitfill-robot/ProfitFillMascot_Final-07.svg";
import Robot8 from "../../../../assets/profitfill-robot/ProfitFillMascot_Final-08.svg";
import Robot9 from "../../../../assets/profitfill-robot/ProfitFillMascot_Final-09.svg";
import Robot10 from "../../../../assets/profitfill-robot/ProfitFillMascot_Final-10.svg";
import Robot11 from "../../../../assets/profitfill-robot/ProfitFillMascot_Final-11.svg";
import Robot12 from "../../../../assets/profitfill-robot/ProfitFillMascot_Final-12.svg";

// ==============================|| DRAWER HEADER ||============================== //

const Robots = [
  Robot1,
  Robot2,
  Robot3,
  Robot4,
  Robot5,
  Robot6,
  Robot7,
  Robot8,
  Robot9,
  Robot10,
  Robot11,
  Robot12,
];

const DrawerFooter = ({ open }) => {
  const theme = useTheme();
  const [robot, setRobot] = useState();
  function getRandomRobot(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Robots[Math.floor(Math.random() * (max - min + 1) + min)]; // The maximum is inclusive and the minimum is inclusive
  }
  useEffect(() => {
    let rando = getRandomRobot(0, Robots.length);
    setRobot(rando);
  }, []);
  return (
    <Box theme={theme}>
      {robot && <img src={robot} alt="Profitfill 404 Robot" />}
    </Box>
  );
};

DrawerFooter.propTypes = {
  open: PropTypes.bool,
};

export default DrawerFooter;
