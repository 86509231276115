import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";

// project import
import DrawerHeaderStyled from "./DrawerHeaderStyled";
import Logo from "components/logo";

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({ open }) => {
  const theme = useTheme();

  return (
    <DrawerHeaderStyled theme={theme} open={open}>
      <Logo
        theme={theme}
        // isIcon={!open}
        sx={{
          // width: open ? "auto" : "1.25em",
          height: open ? "35px" : "1.25em",
          // placeItems: "start",
          display: "flex",
          justifyContent: open ? "start" : "center",
          alignSelf: "center",
          ml: open ? 1 : 0,

          // opacity: open ? "100%" : "0%",
        }}
      />
    </DrawerHeaderStyled>
  );
};

DrawerHeader.propTypes = {
  open: PropTypes.bool,
};

export default DrawerHeader;
