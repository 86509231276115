import { createContext, useEffect, useReducer } from "react";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { getUsers, listUsers } from "../graphql/queries";
// import { useNavigate } from "react-router";

async function fetchUsers(cognitoUserId) {
  const apiData = await API.graphql(
    graphqlOperation(listUsers, {
      filter: {
        username: { eq: cognitoUserId },
      },
    })
  );
  const user = apiData?.data?.listUsers?.items[0];
  // console.log(user);
  if (!user) return;
  if (user?.avatar) {
    const image = await Storage.get(user?.avatar);
    user.avatar = image;
  }
  return user;
}

async function getUser(userId) {
  const apiData = await API.graphql({
    query: getUsers,
    variables: {
      id: userId,
    },
  });
  const user = apiData?.data?.getUsers;
  // console.log(user);
  if (!user) return;
  return user;
}

var Types;

(function (Types) {
  Types["Init"] = "INIT";
})(Types || (Types = {}));

// ==========================================
const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === "INIT") {
    const { isAuthenticated, user } = action.payload;
    return { ...state, user, isAuthenticated, isInitialized: true };
  }

  return state;
};

const AuthContext = createContext({
  ...initialState,
  method: "amplify",
  register: (email, password, name) => Promise.resolve(),
  login: async (email, password) => Promise.resolve(),
  logout: async () => Promise.resolve(),
  getCurrentAuthenticatedUser: async () => Promise.resolve(),
}); // props type

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const auth = Auth;
  // const navigate = useNavigate();
  useEffect(() => {
    async function getCurrentAuthSession() {
      await Auth.currentAuthenticatedUser()
        .then(async (data) => {
          const signedInUserSession = await Auth.currentSession();
          // console.log({data, signedInUserSession});
          if (data) {
            dispatch({
              type: Types.Init,
              payload: {
                isAuthenticated: true,
                loading: false,
                user: data,
              },
            });
          } else {
            dispatch({
              type: Types.Init,
              payload: {
                isAuthenticated: false,
                user: null,
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch({
            type: Types.Init,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        });
    }
    getCurrentAuthSession();
  }, [dispatch, auth, children]);

  const login = async (email, password) => {
    let result;
    await Auth.signIn(email, password)
      .then((data) => {
        // console.log("success");
        result = {
          status: "success",
          data: data,
        };
      })
      .catch((error) => {
        result = {
          status: "error",
          data: error,
        };
      });
    // window.location.reload(false);
    // console.log(result);
    return result;
  };

  const logout = async () => {
    await Auth.signOut().then(() => {
      window.location.href = "/";
    });
  };

  const getCurrentAuthenticatedUser = async () => {
    async function getCurrentAuthSession() {
      await Auth.currentAuthenticatedUser()
        .then(async (data) => {
          var fetchedUsers = await fetchUsers(data?.username);
          if (fetchedUsers) {
            var fetchedUser = await getUser(fetchedUsers?.id);
          }
          if (fetchedUser) {
            fetchedUser.attributes = data?.attributes;
            // console.log(fetchedUser);
            dispatch({
              type: Types.Init,
              payload: {
                isAuthenticated: true,
                loading: false,
                user: fetchedUser,
              },
            });
          } else {
            dispatch({
              type: Types.Init,
              payload: {
                isAuthenticated: false,
                user: null,
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch({
            type: Types.Init,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        });
    }
    return getCurrentAuthSession();
  };

  if (!state.isInitialized) {
    return null;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "amplify",
        //@ts-ignore
        login,
        logout,
        getCurrentAuthenticatedUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export default AuthContext;
