/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTenants = /* GraphQL */ `
  mutation CreateTenants(
    $input: CreateTenantsInput!
    $condition: ModelTenantsConditionInput
  ) {
    createTenants(input: $input, condition: $condition) {
      id
      name
      refreshToken
      Clients {
        items {
          id
          name
          googleServiceAdsAccounts
          googleAdsCustomerID
          googleAnalyticsViewID
          ga4MeasurementID
          ga4Secret
          ga4PropertyID
          metaAdsAccount
          metaPixelID
          metaConversionsApiAccessToken
          currency
          bingAdsAccount
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientsCapacitySettingsId
          clientsBidderSettingsId
          clientsCostSyncSettingsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Users {
        items {
          id
          name
          username
          email
          phone
          type
          clientView
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      IntegrationModels {
        items {
          id
          serviceName
          description
          integrationKeys
          options
          type
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateTenants = /* GraphQL */ `
  mutation UpdateTenants(
    $input: UpdateTenantsInput!
    $condition: ModelTenantsConditionInput
  ) {
    updateTenants(input: $input, condition: $condition) {
      id
      name
      refreshToken
      Clients {
        items {
          id
          name
          googleServiceAdsAccounts
          googleAdsCustomerID
          googleAnalyticsViewID
          ga4MeasurementID
          ga4Secret
          ga4PropertyID
          metaAdsAccount
          metaPixelID
          metaConversionsApiAccessToken
          currency
          bingAdsAccount
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientsCapacitySettingsId
          clientsBidderSettingsId
          clientsCostSyncSettingsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Users {
        items {
          id
          name
          username
          email
          phone
          type
          clientView
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      IntegrationModels {
        items {
          id
          serviceName
          description
          integrationKeys
          options
          type
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteTenants = /* GraphQL */ `
  mutation DeleteTenants(
    $input: DeleteTenantsInput!
    $condition: ModelTenantsConditionInput
  ) {
    deleteTenants(input: $input, condition: $condition) {
      id
      name
      refreshToken
      Clients {
        items {
          id
          name
          googleServiceAdsAccounts
          googleAdsCustomerID
          googleAnalyticsViewID
          ga4MeasurementID
          ga4Secret
          ga4PropertyID
          metaAdsAccount
          metaPixelID
          metaConversionsApiAccessToken
          currency
          bingAdsAccount
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientsCapacitySettingsId
          clientsBidderSettingsId
          clientsCostSyncSettingsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Users {
        items {
          id
          name
          username
          email
          phone
          type
          clientView
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      IntegrationModels {
        items {
          id
          serviceName
          description
          integrationKeys
          options
          type
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAppointments = /* GraphQL */ `
  mutation CreateAppointments(
    $input: CreateAppointmentsInput!
    $condition: ModelAppointmentsConditionInput
  ) {
    createAppointments(input: $input, condition: $condition) {
      id
      crmID
      source
      appointmentNumber
      start
      end
      arrivalWindowStart
      arrivalWindowEnd
      status
      updatedAt
      createdAt
      JobID
      ClientID
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAppointments = /* GraphQL */ `
  mutation UpdateAppointments(
    $input: UpdateAppointmentsInput!
    $condition: ModelAppointmentsConditionInput
  ) {
    updateAppointments(input: $input, condition: $condition) {
      id
      crmID
      source
      appointmentNumber
      start
      end
      arrivalWindowStart
      arrivalWindowEnd
      status
      updatedAt
      createdAt
      JobID
      ClientID
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAppointments = /* GraphQL */ `
  mutation DeleteAppointments(
    $input: DeleteAppointmentsInput!
    $condition: ModelAppointmentsConditionInput
  ) {
    deleteAppointments(input: $input, condition: $condition) {
      id
      crmID
      source
      appointmentNumber
      start
      end
      arrivalWindowStart
      arrivalWindowEnd
      status
      updatedAt
      createdAt
      JobID
      ClientID
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createClients = /* GraphQL */ `
  mutation CreateClients(
    $input: CreateClientsInput!
    $condition: ModelClientsConditionInput
  ) {
    createClients(input: $input, condition: $condition) {
      id
      name
      googleServiceAdsAccounts
      googleAdsCustomerID
      googleAnalyticsViewID
      ga4MeasurementID
      ga4Secret
      ga4PropertyID
      metaAdsAccount
      metaPixelID
      metaConversionsApiAccessToken
      currency
      bingAdsAccount
      Users {
        items {
          id
          clientsId
          usersId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      WebSessions {
        items {
          id
          ipAddress
          status
          startTime
          endTime
          domain
          gacid
          gclid
          fbclid
          msclkid
          referrer
          utmSource
          utmMedium
          utmCampaign
          utmAdgroup
          utmTerm
          utmContent
          utmId
          utmDevice
          utmAdgroupName
          utmPromotion
          queryParameters
          lastPingedOn
          createdAt
          PhoneNumberID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Contacts {
        items {
          id
          name
          emails
          phones
          crmID
          source
          createdAt
          ClientID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ContactDetails {
        items {
          id
          type
          value
          memo
          crmID
          source
          createdAt
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Locations {
        items {
          id
          name
          street1
          street2
          city
          state
          zip
          crmID
          source
          createdAt
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Leads {
        items {
          id
          name
          description
          type
          details
          value
          status
          state
          twillioSID
          email
          phoneNumber
          crmID
          source
          createdAt
          PhoneNumberID
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          callDuration
          entrySource
          whatconvertsWebSession
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Jobs {
        items {
          id
          name
          description
          status
          value
          completedOn
          crmID
          source
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          LocationID
          LeadID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Invoices {
        items {
          id
          number
          description
          invoiceDate
          createdAt
          ClientID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      InvoiceItems {
        items {
          id
          name
          description
          quantity
          total
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          InvoiceID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      BusinessUnits {
        items {
          id
          name
          ClientID
          active
          maxCPC
          capacityWindow
          jobDuration
          monthlyBudget
          bidderBlockedBy
          bidderBlocked
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Channels {
        items {
          id
          name
          budget
          ClientID
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Campaigns {
        items {
          id
          name
          ClientID
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Integrations {
        items {
          id
          serviceName
          description
          integrationKeys
          options
          IntegrationModelID
          ClientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Activity {
        items {
          id
          title
          description
          eventTime
          queryParameters
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Employees {
        items {
          id
          name
          jobTitle
          ClientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Equipment {
        items {
          id
          name
          installedOn
          type
          modelNumber
          serialNumber
          crmID
          source
          ClientID
          LocationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Dashboards {
        items {
          id
          title
          description
          category
          embedCode
          ClientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      DNIPools {
        items {
          id
          forwardingNumber
          fallbackNumberID
          ClientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ProgrammableDNIPools {
        items {
          id
          name
          forwardingNumber
          fallbackNumberID
          isDefault
          order
          usageLogic
          ClientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      TenantID
      clientView {
        items {
          id
          name
          username
          email
          phone
          type
          clientView
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ServiceTitanQueues {
        items {
          id
          createdAt
          completedOn
          type
          customers
          customerContacts
          locations
          jobs
          invoices
          equipment
          status
          ClientID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      capacitySettings {
        id
        active
        capacityType
        clientID
        client {
          id
          name
          googleServiceAdsAccounts
          googleAdsCustomerID
          googleAnalyticsViewID
          ga4MeasurementID
          ga4Secret
          ga4PropertyID
          metaAdsAccount
          metaPixelID
          metaConversionsApiAccessToken
          currency
          bingAdsAccount
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientsCapacitySettingsId
          clientsBidderSettingsId
          clientsCostSyncSettingsId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      bidderSettings {
        id
        blacklistedBusinessUnits
        blacklistedGoogleAdsCampaigns
        excludeFromCapacityBusinessUnits
        budgetDistributer
        active
        clientID
        client {
          id
          name
          googleServiceAdsAccounts
          googleAdsCustomerID
          googleAnalyticsViewID
          ga4MeasurementID
          ga4Secret
          ga4PropertyID
          metaAdsAccount
          metaPixelID
          metaConversionsApiAccessToken
          currency
          bingAdsAccount
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientsCapacitySettingsId
          clientsBidderSettingsId
          clientsCostSyncSettingsId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      BudgetLogs {
        items {
          id
          bidType
          bidProperty
          propertyId
          previousValue
          updatedValue
          description
          createdAt
          clientID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      costSyncSettings {
        id
        googleAdsActive
        facebookAdsActive
        bingAdsActive
        lsaActive
        googleAdsCpcChannelID
        googleAdsCpmChannelID
        facebookChannelID
        bingChannelID
        lsaChannelID
        clientID
        client {
          id
          name
          googleServiceAdsAccounts
          googleAdsCustomerID
          googleAnalyticsViewID
          ga4MeasurementID
          ga4Secret
          ga4PropertyID
          metaAdsAccount
          metaPixelID
          metaConversionsApiAccessToken
          currency
          bingAdsAccount
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientsCapacitySettingsId
          clientsBidderSettingsId
          clientsCostSyncSettingsId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      clientsCapacitySettingsId
      clientsBidderSettingsId
      clientsCostSyncSettingsId
      __typename
    }
  }
`;
export const updateClients = /* GraphQL */ `
  mutation UpdateClients(
    $input: UpdateClientsInput!
    $condition: ModelClientsConditionInput
  ) {
    updateClients(input: $input, condition: $condition) {
      id
      name
      googleServiceAdsAccounts
      googleAdsCustomerID
      googleAnalyticsViewID
      ga4MeasurementID
      ga4Secret
      ga4PropertyID
      metaAdsAccount
      metaPixelID
      metaConversionsApiAccessToken
      currency
      bingAdsAccount
      Users {
        items {
          id
          clientsId
          usersId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      WebSessions {
        items {
          id
          ipAddress
          status
          startTime
          endTime
          domain
          gacid
          gclid
          fbclid
          msclkid
          referrer
          utmSource
          utmMedium
          utmCampaign
          utmAdgroup
          utmTerm
          utmContent
          utmId
          utmDevice
          utmAdgroupName
          utmPromotion
          queryParameters
          lastPingedOn
          createdAt
          PhoneNumberID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Contacts {
        items {
          id
          name
          emails
          phones
          crmID
          source
          createdAt
          ClientID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ContactDetails {
        items {
          id
          type
          value
          memo
          crmID
          source
          createdAt
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Locations {
        items {
          id
          name
          street1
          street2
          city
          state
          zip
          crmID
          source
          createdAt
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Leads {
        items {
          id
          name
          description
          type
          details
          value
          status
          state
          twillioSID
          email
          phoneNumber
          crmID
          source
          createdAt
          PhoneNumberID
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          callDuration
          entrySource
          whatconvertsWebSession
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Jobs {
        items {
          id
          name
          description
          status
          value
          completedOn
          crmID
          source
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          LocationID
          LeadID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Invoices {
        items {
          id
          number
          description
          invoiceDate
          createdAt
          ClientID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      InvoiceItems {
        items {
          id
          name
          description
          quantity
          total
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          InvoiceID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      BusinessUnits {
        items {
          id
          name
          ClientID
          active
          maxCPC
          capacityWindow
          jobDuration
          monthlyBudget
          bidderBlockedBy
          bidderBlocked
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Channels {
        items {
          id
          name
          budget
          ClientID
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Campaigns {
        items {
          id
          name
          ClientID
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Integrations {
        items {
          id
          serviceName
          description
          integrationKeys
          options
          IntegrationModelID
          ClientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Activity {
        items {
          id
          title
          description
          eventTime
          queryParameters
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Employees {
        items {
          id
          name
          jobTitle
          ClientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Equipment {
        items {
          id
          name
          installedOn
          type
          modelNumber
          serialNumber
          crmID
          source
          ClientID
          LocationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Dashboards {
        items {
          id
          title
          description
          category
          embedCode
          ClientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      DNIPools {
        items {
          id
          forwardingNumber
          fallbackNumberID
          ClientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ProgrammableDNIPools {
        items {
          id
          name
          forwardingNumber
          fallbackNumberID
          isDefault
          order
          usageLogic
          ClientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      TenantID
      clientView {
        items {
          id
          name
          username
          email
          phone
          type
          clientView
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ServiceTitanQueues {
        items {
          id
          createdAt
          completedOn
          type
          customers
          customerContacts
          locations
          jobs
          invoices
          equipment
          status
          ClientID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      capacitySettings {
        id
        active
        capacityType
        clientID
        client {
          id
          name
          googleServiceAdsAccounts
          googleAdsCustomerID
          googleAnalyticsViewID
          ga4MeasurementID
          ga4Secret
          ga4PropertyID
          metaAdsAccount
          metaPixelID
          metaConversionsApiAccessToken
          currency
          bingAdsAccount
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientsCapacitySettingsId
          clientsBidderSettingsId
          clientsCostSyncSettingsId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      bidderSettings {
        id
        blacklistedBusinessUnits
        blacklistedGoogleAdsCampaigns
        excludeFromCapacityBusinessUnits
        budgetDistributer
        active
        clientID
        client {
          id
          name
          googleServiceAdsAccounts
          googleAdsCustomerID
          googleAnalyticsViewID
          ga4MeasurementID
          ga4Secret
          ga4PropertyID
          metaAdsAccount
          metaPixelID
          metaConversionsApiAccessToken
          currency
          bingAdsAccount
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientsCapacitySettingsId
          clientsBidderSettingsId
          clientsCostSyncSettingsId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      BudgetLogs {
        items {
          id
          bidType
          bidProperty
          propertyId
          previousValue
          updatedValue
          description
          createdAt
          clientID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      costSyncSettings {
        id
        googleAdsActive
        facebookAdsActive
        bingAdsActive
        lsaActive
        googleAdsCpcChannelID
        googleAdsCpmChannelID
        facebookChannelID
        bingChannelID
        lsaChannelID
        clientID
        client {
          id
          name
          googleServiceAdsAccounts
          googleAdsCustomerID
          googleAnalyticsViewID
          ga4MeasurementID
          ga4Secret
          ga4PropertyID
          metaAdsAccount
          metaPixelID
          metaConversionsApiAccessToken
          currency
          bingAdsAccount
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientsCapacitySettingsId
          clientsBidderSettingsId
          clientsCostSyncSettingsId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      clientsCapacitySettingsId
      clientsBidderSettingsId
      clientsCostSyncSettingsId
      __typename
    }
  }
`;
export const deleteClients = /* GraphQL */ `
  mutation DeleteClients(
    $input: DeleteClientsInput!
    $condition: ModelClientsConditionInput
  ) {
    deleteClients(input: $input, condition: $condition) {
      id
      name
      googleServiceAdsAccounts
      googleAdsCustomerID
      googleAnalyticsViewID
      ga4MeasurementID
      ga4Secret
      ga4PropertyID
      metaAdsAccount
      metaPixelID
      metaConversionsApiAccessToken
      currency
      bingAdsAccount
      Users {
        items {
          id
          clientsId
          usersId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      WebSessions {
        items {
          id
          ipAddress
          status
          startTime
          endTime
          domain
          gacid
          gclid
          fbclid
          msclkid
          referrer
          utmSource
          utmMedium
          utmCampaign
          utmAdgroup
          utmTerm
          utmContent
          utmId
          utmDevice
          utmAdgroupName
          utmPromotion
          queryParameters
          lastPingedOn
          createdAt
          PhoneNumberID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Contacts {
        items {
          id
          name
          emails
          phones
          crmID
          source
          createdAt
          ClientID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ContactDetails {
        items {
          id
          type
          value
          memo
          crmID
          source
          createdAt
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Locations {
        items {
          id
          name
          street1
          street2
          city
          state
          zip
          crmID
          source
          createdAt
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Leads {
        items {
          id
          name
          description
          type
          details
          value
          status
          state
          twillioSID
          email
          phoneNumber
          crmID
          source
          createdAt
          PhoneNumberID
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          callDuration
          entrySource
          whatconvertsWebSession
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Jobs {
        items {
          id
          name
          description
          status
          value
          completedOn
          crmID
          source
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          LocationID
          LeadID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Invoices {
        items {
          id
          number
          description
          invoiceDate
          createdAt
          ClientID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      InvoiceItems {
        items {
          id
          name
          description
          quantity
          total
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          InvoiceID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      BusinessUnits {
        items {
          id
          name
          ClientID
          active
          maxCPC
          capacityWindow
          jobDuration
          monthlyBudget
          bidderBlockedBy
          bidderBlocked
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Channels {
        items {
          id
          name
          budget
          ClientID
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Campaigns {
        items {
          id
          name
          ClientID
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Integrations {
        items {
          id
          serviceName
          description
          integrationKeys
          options
          IntegrationModelID
          ClientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Activity {
        items {
          id
          title
          description
          eventTime
          queryParameters
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Employees {
        items {
          id
          name
          jobTitle
          ClientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Equipment {
        items {
          id
          name
          installedOn
          type
          modelNumber
          serialNumber
          crmID
          source
          ClientID
          LocationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Dashboards {
        items {
          id
          title
          description
          category
          embedCode
          ClientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      DNIPools {
        items {
          id
          forwardingNumber
          fallbackNumberID
          ClientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ProgrammableDNIPools {
        items {
          id
          name
          forwardingNumber
          fallbackNumberID
          isDefault
          order
          usageLogic
          ClientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      TenantID
      clientView {
        items {
          id
          name
          username
          email
          phone
          type
          clientView
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ServiceTitanQueues {
        items {
          id
          createdAt
          completedOn
          type
          customers
          customerContacts
          locations
          jobs
          invoices
          equipment
          status
          ClientID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      capacitySettings {
        id
        active
        capacityType
        clientID
        client {
          id
          name
          googleServiceAdsAccounts
          googleAdsCustomerID
          googleAnalyticsViewID
          ga4MeasurementID
          ga4Secret
          ga4PropertyID
          metaAdsAccount
          metaPixelID
          metaConversionsApiAccessToken
          currency
          bingAdsAccount
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientsCapacitySettingsId
          clientsBidderSettingsId
          clientsCostSyncSettingsId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      bidderSettings {
        id
        blacklistedBusinessUnits
        blacklistedGoogleAdsCampaigns
        excludeFromCapacityBusinessUnits
        budgetDistributer
        active
        clientID
        client {
          id
          name
          googleServiceAdsAccounts
          googleAdsCustomerID
          googleAnalyticsViewID
          ga4MeasurementID
          ga4Secret
          ga4PropertyID
          metaAdsAccount
          metaPixelID
          metaConversionsApiAccessToken
          currency
          bingAdsAccount
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientsCapacitySettingsId
          clientsBidderSettingsId
          clientsCostSyncSettingsId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      BudgetLogs {
        items {
          id
          bidType
          bidProperty
          propertyId
          previousValue
          updatedValue
          description
          createdAt
          clientID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      costSyncSettings {
        id
        googleAdsActive
        facebookAdsActive
        bingAdsActive
        lsaActive
        googleAdsCpcChannelID
        googleAdsCpmChannelID
        facebookChannelID
        bingChannelID
        lsaChannelID
        clientID
        client {
          id
          name
          googleServiceAdsAccounts
          googleAdsCustomerID
          googleAnalyticsViewID
          ga4MeasurementID
          ga4Secret
          ga4PropertyID
          metaAdsAccount
          metaPixelID
          metaConversionsApiAccessToken
          currency
          bingAdsAccount
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientsCapacitySettingsId
          clientsBidderSettingsId
          clientsCostSyncSettingsId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      clientsCapacitySettingsId
      clientsBidderSettingsId
      clientsCostSyncSettingsId
      __typename
    }
  }
`;
export const createUsers = /* GraphQL */ `
  mutation CreateUsers(
    $input: CreateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    createUsers(input: $input, condition: $condition) {
      id
      name
      username
      email
      phone
      type
      clientView
      TenantID
      Clients {
        items {
          id
          clientsId
          usersId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateUsers = /* GraphQL */ `
  mutation UpdateUsers(
    $input: UpdateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    updateUsers(input: $input, condition: $condition) {
      id
      name
      username
      email
      phone
      type
      clientView
      TenantID
      Clients {
        items {
          id
          clientsId
          usersId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteUsers = /* GraphQL */ `
  mutation DeleteUsers(
    $input: DeleteUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    deleteUsers(input: $input, condition: $condition) {
      id
      name
      username
      email
      phone
      type
      clientView
      TenantID
      Clients {
        items {
          id
          clientsId
          usersId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createContacts = /* GraphQL */ `
  mutation CreateContacts(
    $input: CreateContactsInput!
    $condition: ModelContactsConditionInput
  ) {
    createContacts(input: $input, condition: $condition) {
      id
      name
      emails
      phones
      crmID
      source
      createdAt
      WebSessions {
        items {
          id
          ipAddress
          status
          startTime
          endTime
          domain
          gacid
          gclid
          fbclid
          msclkid
          referrer
          utmSource
          utmMedium
          utmCampaign
          utmAdgroup
          utmTerm
          utmContent
          utmId
          utmDevice
          utmAdgroupName
          utmPromotion
          queryParameters
          lastPingedOn
          createdAt
          PhoneNumberID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Locations {
        items {
          id
          name
          street1
          street2
          city
          state
          zip
          crmID
          source
          createdAt
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Leads {
        items {
          id
          name
          description
          type
          details
          value
          status
          state
          twillioSID
          email
          phoneNumber
          crmID
          source
          createdAt
          PhoneNumberID
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          callDuration
          entrySource
          whatconvertsWebSession
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Activity {
        items {
          id
          title
          description
          eventTime
          queryParameters
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ContactDetails {
        items {
          id
          type
          value
          memo
          crmID
          source
          createdAt
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ClientID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateContacts = /* GraphQL */ `
  mutation UpdateContacts(
    $input: UpdateContactsInput!
    $condition: ModelContactsConditionInput
  ) {
    updateContacts(input: $input, condition: $condition) {
      id
      name
      emails
      phones
      crmID
      source
      createdAt
      WebSessions {
        items {
          id
          ipAddress
          status
          startTime
          endTime
          domain
          gacid
          gclid
          fbclid
          msclkid
          referrer
          utmSource
          utmMedium
          utmCampaign
          utmAdgroup
          utmTerm
          utmContent
          utmId
          utmDevice
          utmAdgroupName
          utmPromotion
          queryParameters
          lastPingedOn
          createdAt
          PhoneNumberID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Locations {
        items {
          id
          name
          street1
          street2
          city
          state
          zip
          crmID
          source
          createdAt
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Leads {
        items {
          id
          name
          description
          type
          details
          value
          status
          state
          twillioSID
          email
          phoneNumber
          crmID
          source
          createdAt
          PhoneNumberID
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          callDuration
          entrySource
          whatconvertsWebSession
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Activity {
        items {
          id
          title
          description
          eventTime
          queryParameters
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ContactDetails {
        items {
          id
          type
          value
          memo
          crmID
          source
          createdAt
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ClientID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteContacts = /* GraphQL */ `
  mutation DeleteContacts(
    $input: DeleteContactsInput!
    $condition: ModelContactsConditionInput
  ) {
    deleteContacts(input: $input, condition: $condition) {
      id
      name
      emails
      phones
      crmID
      source
      createdAt
      WebSessions {
        items {
          id
          ipAddress
          status
          startTime
          endTime
          domain
          gacid
          gclid
          fbclid
          msclkid
          referrer
          utmSource
          utmMedium
          utmCampaign
          utmAdgroup
          utmTerm
          utmContent
          utmId
          utmDevice
          utmAdgroupName
          utmPromotion
          queryParameters
          lastPingedOn
          createdAt
          PhoneNumberID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Locations {
        items {
          id
          name
          street1
          street2
          city
          state
          zip
          crmID
          source
          createdAt
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Leads {
        items {
          id
          name
          description
          type
          details
          value
          status
          state
          twillioSID
          email
          phoneNumber
          crmID
          source
          createdAt
          PhoneNumberID
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          callDuration
          entrySource
          whatconvertsWebSession
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Activity {
        items {
          id
          title
          description
          eventTime
          queryParameters
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ContactDetails {
        items {
          id
          type
          value
          memo
          crmID
          source
          createdAt
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ClientID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createContactDetails = /* GraphQL */ `
  mutation CreateContactDetails(
    $input: CreateContactDetailsInput!
    $condition: ModelContactDetailsConditionInput
  ) {
    createContactDetails(input: $input, condition: $condition) {
      id
      type
      value
      memo
      crmID
      source
      createdAt
      ClientID
      ContactID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateContactDetails = /* GraphQL */ `
  mutation UpdateContactDetails(
    $input: UpdateContactDetailsInput!
    $condition: ModelContactDetailsConditionInput
  ) {
    updateContactDetails(input: $input, condition: $condition) {
      id
      type
      value
      memo
      crmID
      source
      createdAt
      ClientID
      ContactID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteContactDetails = /* GraphQL */ `
  mutation DeleteContactDetails(
    $input: DeleteContactDetailsInput!
    $condition: ModelContactDetailsConditionInput
  ) {
    deleteContactDetails(input: $input, condition: $condition) {
      id
      type
      value
      memo
      crmID
      source
      createdAt
      ClientID
      ContactID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createLocations = /* GraphQL */ `
  mutation CreateLocations(
    $input: CreateLocationsInput!
    $condition: ModelLocationsConditionInput
  ) {
    createLocations(input: $input, condition: $condition) {
      id
      name
      street1
      street2
      city
      state
      zip
      crmID
      source
      createdAt
      Jobs {
        items {
          id
          name
          description
          status
          value
          completedOn
          crmID
          source
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          LocationID
          LeadID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Equipment {
        items {
          id
          name
          installedOn
          type
          modelNumber
          serialNumber
          crmID
          source
          ClientID
          LocationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ClientID
      ContactID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateLocations = /* GraphQL */ `
  mutation UpdateLocations(
    $input: UpdateLocationsInput!
    $condition: ModelLocationsConditionInput
  ) {
    updateLocations(input: $input, condition: $condition) {
      id
      name
      street1
      street2
      city
      state
      zip
      crmID
      source
      createdAt
      Jobs {
        items {
          id
          name
          description
          status
          value
          completedOn
          crmID
          source
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          LocationID
          LeadID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Equipment {
        items {
          id
          name
          installedOn
          type
          modelNumber
          serialNumber
          crmID
          source
          ClientID
          LocationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ClientID
      ContactID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteLocations = /* GraphQL */ `
  mutation DeleteLocations(
    $input: DeleteLocationsInput!
    $condition: ModelLocationsConditionInput
  ) {
    deleteLocations(input: $input, condition: $condition) {
      id
      name
      street1
      street2
      city
      state
      zip
      crmID
      source
      createdAt
      Jobs {
        items {
          id
          name
          description
          status
          value
          completedOn
          crmID
          source
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          LocationID
          LeadID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Equipment {
        items {
          id
          name
          installedOn
          type
          modelNumber
          serialNumber
          crmID
          source
          ClientID
          LocationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ClientID
      ContactID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createLeads = /* GraphQL */ `
  mutation CreateLeads(
    $input: CreateLeadsInput!
    $condition: ModelLeadsConditionInput
  ) {
    createLeads(input: $input, condition: $condition) {
      id
      name
      description
      type
      details
      value
      status
      state
      twillioSID
      email
      phoneNumber
      crmID
      source
      createdAt
      PhoneNumberID
      Employees {
        items {
          id
          leadsId
          employeesId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      WebSessionID
      WebSession {
        id
        ipAddress
        status
        startTime
        endTime
        domain
        gacid
        gclid
        fbclid
        msclkid
        referrer
        utmSource
        utmMedium
        utmCampaign
        utmAdgroup
        utmTerm
        utmContent
        utmId
        utmDevice
        utmAdgroupName
        utmPromotion
        queryParameters
        lastPingedOn
        createdAt
        Activity {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        PhoneNumberID
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        ContactID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      BusinessUnitID
      ChannelID
      Channel {
        id
        name
        budget
        Leads {
          nextToken
          startedAt
          __typename
        }
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        ClientID
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      CampaignID
      ClientID
      ContactID
      Contact {
        id
        name
        emails
        phones
        crmID
        source
        createdAt
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        Locations {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        ContactDetails {
          nextToken
          startedAt
          __typename
        }
        ClientID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Locations {
        id
        name
        street1
        street2
        city
        state
        zip
        crmID
        source
        createdAt
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Equipment {
          nextToken
          startedAt
          __typename
        }
        ClientID
        ContactID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Jobs {
        id
        name
        description
        status
        value
        completedOn
        crmID
        source
        createdAt
        Employees {
          nextToken
          startedAt
          __typename
        }
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        LocationID
        LeadID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      callDuration
      recordingDetails {
        recordingURL
        recordingDuration
        recordingSID
        callRating
        __typename
      }
      entrySource
      whatconvertsWebSession
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateLeads = /* GraphQL */ `
  mutation UpdateLeads(
    $input: UpdateLeadsInput!
    $condition: ModelLeadsConditionInput
  ) {
    updateLeads(input: $input, condition: $condition) {
      id
      name
      description
      type
      details
      value
      status
      state
      twillioSID
      email
      phoneNumber
      crmID
      source
      createdAt
      PhoneNumberID
      Employees {
        items {
          id
          leadsId
          employeesId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      WebSessionID
      WebSession {
        id
        ipAddress
        status
        startTime
        endTime
        domain
        gacid
        gclid
        fbclid
        msclkid
        referrer
        utmSource
        utmMedium
        utmCampaign
        utmAdgroup
        utmTerm
        utmContent
        utmId
        utmDevice
        utmAdgroupName
        utmPromotion
        queryParameters
        lastPingedOn
        createdAt
        Activity {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        PhoneNumberID
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        ContactID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      BusinessUnitID
      ChannelID
      Channel {
        id
        name
        budget
        Leads {
          nextToken
          startedAt
          __typename
        }
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        ClientID
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      CampaignID
      ClientID
      ContactID
      Contact {
        id
        name
        emails
        phones
        crmID
        source
        createdAt
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        Locations {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        ContactDetails {
          nextToken
          startedAt
          __typename
        }
        ClientID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Locations {
        id
        name
        street1
        street2
        city
        state
        zip
        crmID
        source
        createdAt
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Equipment {
          nextToken
          startedAt
          __typename
        }
        ClientID
        ContactID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Jobs {
        id
        name
        description
        status
        value
        completedOn
        crmID
        source
        createdAt
        Employees {
          nextToken
          startedAt
          __typename
        }
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        LocationID
        LeadID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      callDuration
      recordingDetails {
        recordingURL
        recordingDuration
        recordingSID
        callRating
        __typename
      }
      entrySource
      whatconvertsWebSession
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteLeads = /* GraphQL */ `
  mutation DeleteLeads(
    $input: DeleteLeadsInput!
    $condition: ModelLeadsConditionInput
  ) {
    deleteLeads(input: $input, condition: $condition) {
      id
      name
      description
      type
      details
      value
      status
      state
      twillioSID
      email
      phoneNumber
      crmID
      source
      createdAt
      PhoneNumberID
      Employees {
        items {
          id
          leadsId
          employeesId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      WebSessionID
      WebSession {
        id
        ipAddress
        status
        startTime
        endTime
        domain
        gacid
        gclid
        fbclid
        msclkid
        referrer
        utmSource
        utmMedium
        utmCampaign
        utmAdgroup
        utmTerm
        utmContent
        utmId
        utmDevice
        utmAdgroupName
        utmPromotion
        queryParameters
        lastPingedOn
        createdAt
        Activity {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        PhoneNumberID
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        ContactID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      BusinessUnitID
      ChannelID
      Channel {
        id
        name
        budget
        Leads {
          nextToken
          startedAt
          __typename
        }
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        ClientID
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      CampaignID
      ClientID
      ContactID
      Contact {
        id
        name
        emails
        phones
        crmID
        source
        createdAt
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        Locations {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        ContactDetails {
          nextToken
          startedAt
          __typename
        }
        ClientID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Locations {
        id
        name
        street1
        street2
        city
        state
        zip
        crmID
        source
        createdAt
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Equipment {
          nextToken
          startedAt
          __typename
        }
        ClientID
        ContactID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Jobs {
        id
        name
        description
        status
        value
        completedOn
        crmID
        source
        createdAt
        Employees {
          nextToken
          startedAt
          __typename
        }
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        LocationID
        LeadID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      callDuration
      recordingDetails {
        recordingURL
        recordingDuration
        recordingSID
        callRating
        __typename
      }
      entrySource
      whatconvertsWebSession
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createJobs = /* GraphQL */ `
  mutation CreateJobs(
    $input: CreateJobsInput!
    $condition: ModelJobsConditionInput
  ) {
    createJobs(input: $input, condition: $condition) {
      id
      name
      description
      status
      value
      completedOn
      crmID
      source
      createdAt
      Employees {
        items {
          id
          jobsId
          employeesId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      BusinessUnitID
      ChannelID
      CampaignID
      ClientID
      LocationID
      LeadID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateJobs = /* GraphQL */ `
  mutation UpdateJobs(
    $input: UpdateJobsInput!
    $condition: ModelJobsConditionInput
  ) {
    updateJobs(input: $input, condition: $condition) {
      id
      name
      description
      status
      value
      completedOn
      crmID
      source
      createdAt
      Employees {
        items {
          id
          jobsId
          employeesId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      BusinessUnitID
      ChannelID
      CampaignID
      ClientID
      LocationID
      LeadID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteJobs = /* GraphQL */ `
  mutation DeleteJobs(
    $input: DeleteJobsInput!
    $condition: ModelJobsConditionInput
  ) {
    deleteJobs(input: $input, condition: $condition) {
      id
      name
      description
      status
      value
      completedOn
      crmID
      source
      createdAt
      Employees {
        items {
          id
          jobsId
          employeesId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      BusinessUnitID
      ChannelID
      CampaignID
      ClientID
      LocationID
      LeadID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createInvoices = /* GraphQL */ `
  mutation CreateInvoices(
    $input: CreateInvoicesInput!
    $condition: ModelInvoicesConditionInput
  ) {
    createInvoices(input: $input, condition: $condition) {
      id
      number
      description
      invoiceDate
      InvoiceItems {
        items {
          id
          name
          description
          quantity
          total
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          InvoiceID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      ClientID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateInvoices = /* GraphQL */ `
  mutation UpdateInvoices(
    $input: UpdateInvoicesInput!
    $condition: ModelInvoicesConditionInput
  ) {
    updateInvoices(input: $input, condition: $condition) {
      id
      number
      description
      invoiceDate
      InvoiceItems {
        items {
          id
          name
          description
          quantity
          total
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          InvoiceID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      ClientID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteInvoices = /* GraphQL */ `
  mutation DeleteInvoices(
    $input: DeleteInvoicesInput!
    $condition: ModelInvoicesConditionInput
  ) {
    deleteInvoices(input: $input, condition: $condition) {
      id
      number
      description
      invoiceDate
      InvoiceItems {
        items {
          id
          name
          description
          quantity
          total
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          InvoiceID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      ClientID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createInvoiceItems = /* GraphQL */ `
  mutation CreateInvoiceItems(
    $input: CreateInvoiceItemsInput!
    $condition: ModelInvoiceItemsConditionInput
  ) {
    createInvoiceItems(input: $input, condition: $condition) {
      id
      name
      description
      quantity
      total
      createdAt
      BusinessUnitID
      ChannelID
      CampaignID
      ClientID
      InvoiceID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateInvoiceItems = /* GraphQL */ `
  mutation UpdateInvoiceItems(
    $input: UpdateInvoiceItemsInput!
    $condition: ModelInvoiceItemsConditionInput
  ) {
    updateInvoiceItems(input: $input, condition: $condition) {
      id
      name
      description
      quantity
      total
      createdAt
      BusinessUnitID
      ChannelID
      CampaignID
      ClientID
      InvoiceID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteInvoiceItems = /* GraphQL */ `
  mutation DeleteInvoiceItems(
    $input: DeleteInvoiceItemsInput!
    $condition: ModelInvoiceItemsConditionInput
  ) {
    deleteInvoiceItems(input: $input, condition: $condition) {
      id
      name
      description
      quantity
      total
      createdAt
      BusinessUnitID
      ChannelID
      CampaignID
      ClientID
      InvoiceID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createBusinessUnits = /* GraphQL */ `
  mutation CreateBusinessUnits(
    $input: CreateBusinessUnitsInput!
    $condition: ModelBusinessUnitsConditionInput
  ) {
    createBusinessUnits(input: $input, condition: $condition) {
      id
      name
      Leads {
        items {
          id
          name
          description
          type
          details
          value
          status
          state
          twillioSID
          email
          phoneNumber
          crmID
          source
          createdAt
          PhoneNumberID
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          callDuration
          entrySource
          whatconvertsWebSession
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Jobs {
        items {
          id
          name
          description
          status
          value
          completedOn
          crmID
          source
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          LocationID
          LeadID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Activity {
        items {
          id
          title
          description
          eventTime
          queryParameters
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      WebSessions {
        items {
          id
          ipAddress
          status
          startTime
          endTime
          domain
          gacid
          gclid
          fbclid
          msclkid
          referrer
          utmSource
          utmMedium
          utmCampaign
          utmAdgroup
          utmTerm
          utmContent
          utmId
          utmDevice
          utmAdgroupName
          utmPromotion
          queryParameters
          lastPingedOn
          createdAt
          PhoneNumberID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      InvoiceItems {
        items {
          id
          name
          description
          quantity
          total
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          InvoiceID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ClientID
      active
      maxCPC
      capacityWindow
      jobDuration
      monthlyBudget
      bidderBlockedBy
      bidderBlocked
      Capacities {
        items {
          id
          date
          openHours
          totalHours
          businessUnitID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      BudgetAdjustments {
        items {
          id
          businessUnitID
          adjustment
          reason
          dateApplied
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateBusinessUnits = /* GraphQL */ `
  mutation UpdateBusinessUnits(
    $input: UpdateBusinessUnitsInput!
    $condition: ModelBusinessUnitsConditionInput
  ) {
    updateBusinessUnits(input: $input, condition: $condition) {
      id
      name
      Leads {
        items {
          id
          name
          description
          type
          details
          value
          status
          state
          twillioSID
          email
          phoneNumber
          crmID
          source
          createdAt
          PhoneNumberID
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          callDuration
          entrySource
          whatconvertsWebSession
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Jobs {
        items {
          id
          name
          description
          status
          value
          completedOn
          crmID
          source
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          LocationID
          LeadID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Activity {
        items {
          id
          title
          description
          eventTime
          queryParameters
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      WebSessions {
        items {
          id
          ipAddress
          status
          startTime
          endTime
          domain
          gacid
          gclid
          fbclid
          msclkid
          referrer
          utmSource
          utmMedium
          utmCampaign
          utmAdgroup
          utmTerm
          utmContent
          utmId
          utmDevice
          utmAdgroupName
          utmPromotion
          queryParameters
          lastPingedOn
          createdAt
          PhoneNumberID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      InvoiceItems {
        items {
          id
          name
          description
          quantity
          total
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          InvoiceID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ClientID
      active
      maxCPC
      capacityWindow
      jobDuration
      monthlyBudget
      bidderBlockedBy
      bidderBlocked
      Capacities {
        items {
          id
          date
          openHours
          totalHours
          businessUnitID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      BudgetAdjustments {
        items {
          id
          businessUnitID
          adjustment
          reason
          dateApplied
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteBusinessUnits = /* GraphQL */ `
  mutation DeleteBusinessUnits(
    $input: DeleteBusinessUnitsInput!
    $condition: ModelBusinessUnitsConditionInput
  ) {
    deleteBusinessUnits(input: $input, condition: $condition) {
      id
      name
      Leads {
        items {
          id
          name
          description
          type
          details
          value
          status
          state
          twillioSID
          email
          phoneNumber
          crmID
          source
          createdAt
          PhoneNumberID
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          callDuration
          entrySource
          whatconvertsWebSession
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Jobs {
        items {
          id
          name
          description
          status
          value
          completedOn
          crmID
          source
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          LocationID
          LeadID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Activity {
        items {
          id
          title
          description
          eventTime
          queryParameters
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      WebSessions {
        items {
          id
          ipAddress
          status
          startTime
          endTime
          domain
          gacid
          gclid
          fbclid
          msclkid
          referrer
          utmSource
          utmMedium
          utmCampaign
          utmAdgroup
          utmTerm
          utmContent
          utmId
          utmDevice
          utmAdgroupName
          utmPromotion
          queryParameters
          lastPingedOn
          createdAt
          PhoneNumberID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      InvoiceItems {
        items {
          id
          name
          description
          quantity
          total
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          InvoiceID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ClientID
      active
      maxCPC
      capacityWindow
      jobDuration
      monthlyBudget
      bidderBlockedBy
      bidderBlocked
      Capacities {
        items {
          id
          date
          openHours
          totalHours
          businessUnitID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      BudgetAdjustments {
        items {
          id
          businessUnitID
          adjustment
          reason
          dateApplied
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createChannels = /* GraphQL */ `
  mutation CreateChannels(
    $input: CreateChannelsInput!
    $condition: ModelChannelsConditionInput
  ) {
    createChannels(input: $input, condition: $condition) {
      id
      name
      budget
      Leads {
        items {
          id
          name
          description
          type
          details
          value
          status
          state
          twillioSID
          email
          phoneNumber
          crmID
          source
          createdAt
          PhoneNumberID
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          callDuration
          entrySource
          whatconvertsWebSession
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Jobs {
        items {
          id
          name
          description
          status
          value
          completedOn
          crmID
          source
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          LocationID
          LeadID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Activity {
        items {
          id
          title
          description
          eventTime
          queryParameters
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      WebSessions {
        items {
          id
          ipAddress
          status
          startTime
          endTime
          domain
          gacid
          gclid
          fbclid
          msclkid
          referrer
          utmSource
          utmMedium
          utmCampaign
          utmAdgroup
          utmTerm
          utmContent
          utmId
          utmDevice
          utmAdgroupName
          utmPromotion
          queryParameters
          lastPingedOn
          createdAt
          PhoneNumberID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      InvoiceItems {
        items {
          id
          name
          description
          quantity
          total
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          InvoiceID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ClientID
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateChannels = /* GraphQL */ `
  mutation UpdateChannels(
    $input: UpdateChannelsInput!
    $condition: ModelChannelsConditionInput
  ) {
    updateChannels(input: $input, condition: $condition) {
      id
      name
      budget
      Leads {
        items {
          id
          name
          description
          type
          details
          value
          status
          state
          twillioSID
          email
          phoneNumber
          crmID
          source
          createdAt
          PhoneNumberID
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          callDuration
          entrySource
          whatconvertsWebSession
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Jobs {
        items {
          id
          name
          description
          status
          value
          completedOn
          crmID
          source
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          LocationID
          LeadID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Activity {
        items {
          id
          title
          description
          eventTime
          queryParameters
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      WebSessions {
        items {
          id
          ipAddress
          status
          startTime
          endTime
          domain
          gacid
          gclid
          fbclid
          msclkid
          referrer
          utmSource
          utmMedium
          utmCampaign
          utmAdgroup
          utmTerm
          utmContent
          utmId
          utmDevice
          utmAdgroupName
          utmPromotion
          queryParameters
          lastPingedOn
          createdAt
          PhoneNumberID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      InvoiceItems {
        items {
          id
          name
          description
          quantity
          total
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          InvoiceID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ClientID
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteChannels = /* GraphQL */ `
  mutation DeleteChannels(
    $input: DeleteChannelsInput!
    $condition: ModelChannelsConditionInput
  ) {
    deleteChannels(input: $input, condition: $condition) {
      id
      name
      budget
      Leads {
        items {
          id
          name
          description
          type
          details
          value
          status
          state
          twillioSID
          email
          phoneNumber
          crmID
          source
          createdAt
          PhoneNumberID
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          callDuration
          entrySource
          whatconvertsWebSession
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Jobs {
        items {
          id
          name
          description
          status
          value
          completedOn
          crmID
          source
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          LocationID
          LeadID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Activity {
        items {
          id
          title
          description
          eventTime
          queryParameters
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      WebSessions {
        items {
          id
          ipAddress
          status
          startTime
          endTime
          domain
          gacid
          gclid
          fbclid
          msclkid
          referrer
          utmSource
          utmMedium
          utmCampaign
          utmAdgroup
          utmTerm
          utmContent
          utmId
          utmDevice
          utmAdgroupName
          utmPromotion
          queryParameters
          lastPingedOn
          createdAt
          PhoneNumberID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      InvoiceItems {
        items {
          id
          name
          description
          quantity
          total
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          InvoiceID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ClientID
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createCampaigns = /* GraphQL */ `
  mutation CreateCampaigns(
    $input: CreateCampaignsInput!
    $condition: ModelCampaignsConditionInput
  ) {
    createCampaigns(input: $input, condition: $condition) {
      id
      name
      Jobs {
        items {
          id
          name
          description
          status
          value
          completedOn
          crmID
          source
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          LocationID
          LeadID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Activity {
        items {
          id
          title
          description
          eventTime
          queryParameters
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      WebSessions {
        items {
          id
          ipAddress
          status
          startTime
          endTime
          domain
          gacid
          gclid
          fbclid
          msclkid
          referrer
          utmSource
          utmMedium
          utmCampaign
          utmAdgroup
          utmTerm
          utmContent
          utmId
          utmDevice
          utmAdgroupName
          utmPromotion
          queryParameters
          lastPingedOn
          createdAt
          PhoneNumberID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      InvoiceItems {
        items {
          id
          name
          description
          quantity
          total
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          InvoiceID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Leads {
        items {
          id
          name
          description
          type
          details
          value
          status
          state
          twillioSID
          email
          phoneNumber
          crmID
          source
          createdAt
          PhoneNumberID
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          callDuration
          entrySource
          whatconvertsWebSession
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ClientID
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateCampaigns = /* GraphQL */ `
  mutation UpdateCampaigns(
    $input: UpdateCampaignsInput!
    $condition: ModelCampaignsConditionInput
  ) {
    updateCampaigns(input: $input, condition: $condition) {
      id
      name
      Jobs {
        items {
          id
          name
          description
          status
          value
          completedOn
          crmID
          source
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          LocationID
          LeadID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Activity {
        items {
          id
          title
          description
          eventTime
          queryParameters
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      WebSessions {
        items {
          id
          ipAddress
          status
          startTime
          endTime
          domain
          gacid
          gclid
          fbclid
          msclkid
          referrer
          utmSource
          utmMedium
          utmCampaign
          utmAdgroup
          utmTerm
          utmContent
          utmId
          utmDevice
          utmAdgroupName
          utmPromotion
          queryParameters
          lastPingedOn
          createdAt
          PhoneNumberID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      InvoiceItems {
        items {
          id
          name
          description
          quantity
          total
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          InvoiceID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Leads {
        items {
          id
          name
          description
          type
          details
          value
          status
          state
          twillioSID
          email
          phoneNumber
          crmID
          source
          createdAt
          PhoneNumberID
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          callDuration
          entrySource
          whatconvertsWebSession
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ClientID
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteCampaigns = /* GraphQL */ `
  mutation DeleteCampaigns(
    $input: DeleteCampaignsInput!
    $condition: ModelCampaignsConditionInput
  ) {
    deleteCampaigns(input: $input, condition: $condition) {
      id
      name
      Jobs {
        items {
          id
          name
          description
          status
          value
          completedOn
          crmID
          source
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          LocationID
          LeadID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Activity {
        items {
          id
          title
          description
          eventTime
          queryParameters
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      WebSessions {
        items {
          id
          ipAddress
          status
          startTime
          endTime
          domain
          gacid
          gclid
          fbclid
          msclkid
          referrer
          utmSource
          utmMedium
          utmCampaign
          utmAdgroup
          utmTerm
          utmContent
          utmId
          utmDevice
          utmAdgroupName
          utmPromotion
          queryParameters
          lastPingedOn
          createdAt
          PhoneNumberID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      InvoiceItems {
        items {
          id
          name
          description
          quantity
          total
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          InvoiceID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Leads {
        items {
          id
          name
          description
          type
          details
          value
          status
          state
          twillioSID
          email
          phoneNumber
          crmID
          source
          createdAt
          PhoneNumberID
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          callDuration
          entrySource
          whatconvertsWebSession
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ClientID
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createIntegrationModels = /* GraphQL */ `
  mutation CreateIntegrationModels(
    $input: CreateIntegrationModelsInput!
    $condition: ModelIntegrationModelsConditionInput
  ) {
    createIntegrationModels(input: $input, condition: $condition) {
      id
      serviceName
      description
      integrationKeys
      options
      type
      Integrations {
        items {
          id
          serviceName
          description
          integrationKeys
          options
          IntegrationModelID
          ClientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      TenantID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateIntegrationModels = /* GraphQL */ `
  mutation UpdateIntegrationModels(
    $input: UpdateIntegrationModelsInput!
    $condition: ModelIntegrationModelsConditionInput
  ) {
    updateIntegrationModels(input: $input, condition: $condition) {
      id
      serviceName
      description
      integrationKeys
      options
      type
      Integrations {
        items {
          id
          serviceName
          description
          integrationKeys
          options
          IntegrationModelID
          ClientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      TenantID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteIntegrationModels = /* GraphQL */ `
  mutation DeleteIntegrationModels(
    $input: DeleteIntegrationModelsInput!
    $condition: ModelIntegrationModelsConditionInput
  ) {
    deleteIntegrationModels(input: $input, condition: $condition) {
      id
      serviceName
      description
      integrationKeys
      options
      type
      Integrations {
        items {
          id
          serviceName
          description
          integrationKeys
          options
          IntegrationModelID
          ClientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      TenantID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createIntegrations = /* GraphQL */ `
  mutation CreateIntegrations(
    $input: CreateIntegrationsInput!
    $condition: ModelIntegrationsConditionInput
  ) {
    createIntegrations(input: $input, condition: $condition) {
      id
      serviceName
      description
      integrationKeys
      options
      IntegrationModelID
      ClientID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateIntegrations = /* GraphQL */ `
  mutation UpdateIntegrations(
    $input: UpdateIntegrationsInput!
    $condition: ModelIntegrationsConditionInput
  ) {
    updateIntegrations(input: $input, condition: $condition) {
      id
      serviceName
      description
      integrationKeys
      options
      IntegrationModelID
      ClientID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteIntegrations = /* GraphQL */ `
  mutation DeleteIntegrations(
    $input: DeleteIntegrationsInput!
    $condition: ModelIntegrationsConditionInput
  ) {
    deleteIntegrations(input: $input, condition: $condition) {
      id
      serviceName
      description
      integrationKeys
      options
      IntegrationModelID
      ClientID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createDNIPools = /* GraphQL */ `
  mutation CreateDNIPools(
    $input: CreateDNIPoolsInput!
    $condition: ModelDNIPoolsConditionInput
  ) {
    createDNIPools(input: $input, condition: $condition) {
      id
      forwardingNumber
      fallbackNumberID
      ClientID
      PhoneNumbers {
        items {
          id
          number
          twilioID
          poolID
          pDniPoolID
          reserved
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateDNIPools = /* GraphQL */ `
  mutation UpdateDNIPools(
    $input: UpdateDNIPoolsInput!
    $condition: ModelDNIPoolsConditionInput
  ) {
    updateDNIPools(input: $input, condition: $condition) {
      id
      forwardingNumber
      fallbackNumberID
      ClientID
      PhoneNumbers {
        items {
          id
          number
          twilioID
          poolID
          pDniPoolID
          reserved
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteDNIPools = /* GraphQL */ `
  mutation DeleteDNIPools(
    $input: DeleteDNIPoolsInput!
    $condition: ModelDNIPoolsConditionInput
  ) {
    deleteDNIPools(input: $input, condition: $condition) {
      id
      forwardingNumber
      fallbackNumberID
      ClientID
      PhoneNumbers {
        items {
          id
          number
          twilioID
          poolID
          pDniPoolID
          reserved
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createProgrammableDNIPools = /* GraphQL */ `
  mutation CreateProgrammableDNIPools(
    $input: CreateProgrammableDNIPoolsInput!
    $condition: ModelProgrammableDNIPoolsConditionInput
  ) {
    createProgrammableDNIPools(input: $input, condition: $condition) {
      id
      name
      forwardingNumber
      fallbackNumberID
      isDefault
      order
      usageLogic
      ClientID
      PhoneNumbers {
        items {
          id
          number
          twilioID
          poolID
          pDniPoolID
          reserved
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateProgrammableDNIPools = /* GraphQL */ `
  mutation UpdateProgrammableDNIPools(
    $input: UpdateProgrammableDNIPoolsInput!
    $condition: ModelProgrammableDNIPoolsConditionInput
  ) {
    updateProgrammableDNIPools(input: $input, condition: $condition) {
      id
      name
      forwardingNumber
      fallbackNumberID
      isDefault
      order
      usageLogic
      ClientID
      PhoneNumbers {
        items {
          id
          number
          twilioID
          poolID
          pDniPoolID
          reserved
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteProgrammableDNIPools = /* GraphQL */ `
  mutation DeleteProgrammableDNIPools(
    $input: DeleteProgrammableDNIPoolsInput!
    $condition: ModelProgrammableDNIPoolsConditionInput
  ) {
    deleteProgrammableDNIPools(input: $input, condition: $condition) {
      id
      name
      forwardingNumber
      fallbackNumberID
      isDefault
      order
      usageLogic
      ClientID
      PhoneNumbers {
        items {
          id
          number
          twilioID
          poolID
          pDniPoolID
          reserved
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createPhoneNumbers = /* GraphQL */ `
  mutation CreatePhoneNumbers(
    $input: CreatePhoneNumbersInput!
    $condition: ModelPhoneNumbersConditionInput
  ) {
    createPhoneNumbers(input: $input, condition: $condition) {
      id
      number
      twilioID
      poolID
      pDniPoolID
      reserved
      Leads {
        items {
          id
          name
          description
          type
          details
          value
          status
          state
          twillioSID
          email
          phoneNumber
          crmID
          source
          createdAt
          PhoneNumberID
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          callDuration
          entrySource
          whatconvertsWebSession
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      WebSessions {
        items {
          id
          ipAddress
          status
          startTime
          endTime
          domain
          gacid
          gclid
          fbclid
          msclkid
          referrer
          utmSource
          utmMedium
          utmCampaign
          utmAdgroup
          utmTerm
          utmContent
          utmId
          utmDevice
          utmAdgroupName
          utmPromotion
          queryParameters
          lastPingedOn
          createdAt
          PhoneNumberID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updatePhoneNumbers = /* GraphQL */ `
  mutation UpdatePhoneNumbers(
    $input: UpdatePhoneNumbersInput!
    $condition: ModelPhoneNumbersConditionInput
  ) {
    updatePhoneNumbers(input: $input, condition: $condition) {
      id
      number
      twilioID
      poolID
      pDniPoolID
      reserved
      Leads {
        items {
          id
          name
          description
          type
          details
          value
          status
          state
          twillioSID
          email
          phoneNumber
          crmID
          source
          createdAt
          PhoneNumberID
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          callDuration
          entrySource
          whatconvertsWebSession
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      WebSessions {
        items {
          id
          ipAddress
          status
          startTime
          endTime
          domain
          gacid
          gclid
          fbclid
          msclkid
          referrer
          utmSource
          utmMedium
          utmCampaign
          utmAdgroup
          utmTerm
          utmContent
          utmId
          utmDevice
          utmAdgroupName
          utmPromotion
          queryParameters
          lastPingedOn
          createdAt
          PhoneNumberID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deletePhoneNumbers = /* GraphQL */ `
  mutation DeletePhoneNumbers(
    $input: DeletePhoneNumbersInput!
    $condition: ModelPhoneNumbersConditionInput
  ) {
    deletePhoneNumbers(input: $input, condition: $condition) {
      id
      number
      twilioID
      poolID
      pDniPoolID
      reserved
      Leads {
        items {
          id
          name
          description
          type
          details
          value
          status
          state
          twillioSID
          email
          phoneNumber
          crmID
          source
          createdAt
          PhoneNumberID
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          callDuration
          entrySource
          whatconvertsWebSession
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      WebSessions {
        items {
          id
          ipAddress
          status
          startTime
          endTime
          domain
          gacid
          gclid
          fbclid
          msclkid
          referrer
          utmSource
          utmMedium
          utmCampaign
          utmAdgroup
          utmTerm
          utmContent
          utmId
          utmDevice
          utmAdgroupName
          utmPromotion
          queryParameters
          lastPingedOn
          createdAt
          PhoneNumberID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createActivity = /* GraphQL */ `
  mutation CreateActivity(
    $input: CreateActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    createActivity(input: $input, condition: $condition) {
      id
      title
      description
      eventTime
      queryParameters
      WebSessionID
      BusinessUnitID
      ChannelID
      CampaignID
      ClientID
      ContactID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateActivity = /* GraphQL */ `
  mutation UpdateActivity(
    $input: UpdateActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    updateActivity(input: $input, condition: $condition) {
      id
      title
      description
      eventTime
      queryParameters
      WebSessionID
      BusinessUnitID
      ChannelID
      CampaignID
      ClientID
      ContactID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteActivity = /* GraphQL */ `
  mutation DeleteActivity(
    $input: DeleteActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    deleteActivity(input: $input, condition: $condition) {
      id
      title
      description
      eventTime
      queryParameters
      WebSessionID
      BusinessUnitID
      ChannelID
      CampaignID
      ClientID
      ContactID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createWebSessions = /* GraphQL */ `
  mutation CreateWebSessions(
    $input: CreateWebSessionsInput!
    $condition: ModelWebSessionsConditionInput
  ) {
    createWebSessions(input: $input, condition: $condition) {
      id
      ipAddress
      status
      startTime
      endTime
      domain
      gacid
      gclid
      fbclid
      msclkid
      referrer
      utmSource
      utmMedium
      utmCampaign
      utmAdgroup
      utmTerm
      utmContent
      utmId
      utmDevice
      utmAdgroupName
      utmPromotion
      queryParameters
      lastPingedOn
      createdAt
      Activity {
        items {
          id
          title
          description
          eventTime
          queryParameters
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Leads {
        items {
          id
          name
          description
          type
          details
          value
          status
          state
          twillioSID
          email
          phoneNumber
          crmID
          source
          createdAt
          PhoneNumberID
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          callDuration
          entrySource
          whatconvertsWebSession
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      PhoneNumberID
      BusinessUnitID
      ChannelID
      CampaignID
      ClientID
      ContactID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateWebSessions = /* GraphQL */ `
  mutation UpdateWebSessions(
    $input: UpdateWebSessionsInput!
    $condition: ModelWebSessionsConditionInput
  ) {
    updateWebSessions(input: $input, condition: $condition) {
      id
      ipAddress
      status
      startTime
      endTime
      domain
      gacid
      gclid
      fbclid
      msclkid
      referrer
      utmSource
      utmMedium
      utmCampaign
      utmAdgroup
      utmTerm
      utmContent
      utmId
      utmDevice
      utmAdgroupName
      utmPromotion
      queryParameters
      lastPingedOn
      createdAt
      Activity {
        items {
          id
          title
          description
          eventTime
          queryParameters
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Leads {
        items {
          id
          name
          description
          type
          details
          value
          status
          state
          twillioSID
          email
          phoneNumber
          crmID
          source
          createdAt
          PhoneNumberID
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          callDuration
          entrySource
          whatconvertsWebSession
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      PhoneNumberID
      BusinessUnitID
      ChannelID
      CampaignID
      ClientID
      ContactID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteWebSessions = /* GraphQL */ `
  mutation DeleteWebSessions(
    $input: DeleteWebSessionsInput!
    $condition: ModelWebSessionsConditionInput
  ) {
    deleteWebSessions(input: $input, condition: $condition) {
      id
      ipAddress
      status
      startTime
      endTime
      domain
      gacid
      gclid
      fbclid
      msclkid
      referrer
      utmSource
      utmMedium
      utmCampaign
      utmAdgroup
      utmTerm
      utmContent
      utmId
      utmDevice
      utmAdgroupName
      utmPromotion
      queryParameters
      lastPingedOn
      createdAt
      Activity {
        items {
          id
          title
          description
          eventTime
          queryParameters
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Leads {
        items {
          id
          name
          description
          type
          details
          value
          status
          state
          twillioSID
          email
          phoneNumber
          crmID
          source
          createdAt
          PhoneNumberID
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          callDuration
          entrySource
          whatconvertsWebSession
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      PhoneNumberID
      BusinessUnitID
      ChannelID
      CampaignID
      ClientID
      ContactID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createServiceTitanQueues = /* GraphQL */ `
  mutation CreateServiceTitanQueues(
    $input: CreateServiceTitanQueuesInput!
    $condition: ModelServiceTitanQueuesConditionInput
  ) {
    createServiceTitanQueues(input: $input, condition: $condition) {
      id
      createdAt
      completedOn
      type
      customers
      customerContacts
      locations
      jobs
      invoices
      equipment
      status
      ClientID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateServiceTitanQueues = /* GraphQL */ `
  mutation UpdateServiceTitanQueues(
    $input: UpdateServiceTitanQueuesInput!
    $condition: ModelServiceTitanQueuesConditionInput
  ) {
    updateServiceTitanQueues(input: $input, condition: $condition) {
      id
      createdAt
      completedOn
      type
      customers
      customerContacts
      locations
      jobs
      invoices
      equipment
      status
      ClientID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteServiceTitanQueues = /* GraphQL */ `
  mutation DeleteServiceTitanQueues(
    $input: DeleteServiceTitanQueuesInput!
    $condition: ModelServiceTitanQueuesConditionInput
  ) {
    deleteServiceTitanQueues(input: $input, condition: $condition) {
      id
      createdAt
      completedOn
      type
      customers
      customerContacts
      locations
      jobs
      invoices
      equipment
      status
      ClientID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createEmployees = /* GraphQL */ `
  mutation CreateEmployees(
    $input: CreateEmployeesInput!
    $condition: ModelEmployeesConditionInput
  ) {
    createEmployees(input: $input, condition: $condition) {
      id
      name
      jobTitle
      Leads {
        items {
          id
          leadsId
          employeesId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Job {
        items {
          id
          jobsId
          employeesId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ClientID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateEmployees = /* GraphQL */ `
  mutation UpdateEmployees(
    $input: UpdateEmployeesInput!
    $condition: ModelEmployeesConditionInput
  ) {
    updateEmployees(input: $input, condition: $condition) {
      id
      name
      jobTitle
      Leads {
        items {
          id
          leadsId
          employeesId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Job {
        items {
          id
          jobsId
          employeesId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ClientID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteEmployees = /* GraphQL */ `
  mutation DeleteEmployees(
    $input: DeleteEmployeesInput!
    $condition: ModelEmployeesConditionInput
  ) {
    deleteEmployees(input: $input, condition: $condition) {
      id
      name
      jobTitle
      Leads {
        items {
          id
          leadsId
          employeesId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Job {
        items {
          id
          jobsId
          employeesId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ClientID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createDashboards = /* GraphQL */ `
  mutation CreateDashboards(
    $input: CreateDashboardsInput!
    $condition: ModelDashboardsConditionInput
  ) {
    createDashboards(input: $input, condition: $condition) {
      id
      title
      description
      category
      embedCode
      ClientID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateDashboards = /* GraphQL */ `
  mutation UpdateDashboards(
    $input: UpdateDashboardsInput!
    $condition: ModelDashboardsConditionInput
  ) {
    updateDashboards(input: $input, condition: $condition) {
      id
      title
      description
      category
      embedCode
      ClientID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteDashboards = /* GraphQL */ `
  mutation DeleteDashboards(
    $input: DeleteDashboardsInput!
    $condition: ModelDashboardsConditionInput
  ) {
    deleteDashboards(input: $input, condition: $condition) {
      id
      title
      description
      category
      embedCode
      ClientID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createEquipment = /* GraphQL */ `
  mutation CreateEquipment(
    $input: CreateEquipmentInput!
    $condition: ModelEquipmentConditionInput
  ) {
    createEquipment(input: $input, condition: $condition) {
      id
      name
      installedOn
      type
      modelNumber
      serialNumber
      crmID
      source
      ClientID
      LocationID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateEquipment = /* GraphQL */ `
  mutation UpdateEquipment(
    $input: UpdateEquipmentInput!
    $condition: ModelEquipmentConditionInput
  ) {
    updateEquipment(input: $input, condition: $condition) {
      id
      name
      installedOn
      type
      modelNumber
      serialNumber
      crmID
      source
      ClientID
      LocationID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteEquipment = /* GraphQL */ `
  mutation DeleteEquipment(
    $input: DeleteEquipmentInput!
    $condition: ModelEquipmentConditionInput
  ) {
    deleteEquipment(input: $input, condition: $condition) {
      id
      name
      installedOn
      type
      modelNumber
      serialNumber
      crmID
      source
      ClientID
      LocationID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createMappings = /* GraphQL */ `
  mutation CreateMappings(
    $input: CreateMappingsInput!
    $condition: ModelMappingsConditionInput
  ) {
    createMappings(input: $input, condition: $condition) {
      id
      type
      service
      propertyIDs
      BusinessUnitID
      ChannelID
      CampaignID
      ClientID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateMappings = /* GraphQL */ `
  mutation UpdateMappings(
    $input: UpdateMappingsInput!
    $condition: ModelMappingsConditionInput
  ) {
    updateMappings(input: $input, condition: $condition) {
      id
      type
      service
      propertyIDs
      BusinessUnitID
      ChannelID
      CampaignID
      ClientID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteMappings = /* GraphQL */ `
  mutation DeleteMappings(
    $input: DeleteMappingsInput!
    $condition: ModelMappingsConditionInput
  ) {
    deleteMappings(input: $input, condition: $condition) {
      id
      type
      service
      propertyIDs
      BusinessUnitID
      ChannelID
      CampaignID
      ClientID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createCapacitySettings = /* GraphQL */ `
  mutation CreateCapacitySettings(
    $input: CreateCapacitySettingsInput!
    $condition: ModelCapacitySettingsConditionInput
  ) {
    createCapacitySettings(input: $input, condition: $condition) {
      id
      active
      capacityType
      clientID
      client {
        id
        name
        googleServiceAdsAccounts
        googleAdsCustomerID
        googleAnalyticsViewID
        ga4MeasurementID
        ga4Secret
        ga4PropertyID
        metaAdsAccount
        metaPixelID
        metaConversionsApiAccessToken
        currency
        bingAdsAccount
        Users {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        Contacts {
          nextToken
          startedAt
          __typename
        }
        ContactDetails {
          nextToken
          startedAt
          __typename
        }
        Locations {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Invoices {
          nextToken
          startedAt
          __typename
        }
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        BusinessUnits {
          nextToken
          startedAt
          __typename
        }
        Channels {
          nextToken
          startedAt
          __typename
        }
        Campaigns {
          nextToken
          startedAt
          __typename
        }
        Integrations {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        Employees {
          nextToken
          startedAt
          __typename
        }
        Equipment {
          nextToken
          startedAt
          __typename
        }
        Dashboards {
          nextToken
          startedAt
          __typename
        }
        DNIPools {
          nextToken
          startedAt
          __typename
        }
        ProgrammableDNIPools {
          nextToken
          startedAt
          __typename
        }
        TenantID
        clientView {
          nextToken
          startedAt
          __typename
        }
        ServiceTitanQueues {
          nextToken
          startedAt
          __typename
        }
        capacitySettings {
          id
          active
          capacityType
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bidderSettings {
          id
          blacklistedBusinessUnits
          blacklistedGoogleAdsCampaigns
          excludeFromCapacityBusinessUnits
          budgetDistributer
          active
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BudgetLogs {
          nextToken
          startedAt
          __typename
        }
        costSyncSettings {
          id
          googleAdsActive
          facebookAdsActive
          bingAdsActive
          lsaActive
          googleAdsCpcChannelID
          googleAdsCpmChannelID
          facebookChannelID
          bingChannelID
          lsaChannelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        clientsCapacitySettingsId
        clientsBidderSettingsId
        clientsCostSyncSettingsId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateCapacitySettings = /* GraphQL */ `
  mutation UpdateCapacitySettings(
    $input: UpdateCapacitySettingsInput!
    $condition: ModelCapacitySettingsConditionInput
  ) {
    updateCapacitySettings(input: $input, condition: $condition) {
      id
      active
      capacityType
      clientID
      client {
        id
        name
        googleServiceAdsAccounts
        googleAdsCustomerID
        googleAnalyticsViewID
        ga4MeasurementID
        ga4Secret
        ga4PropertyID
        metaAdsAccount
        metaPixelID
        metaConversionsApiAccessToken
        currency
        bingAdsAccount
        Users {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        Contacts {
          nextToken
          startedAt
          __typename
        }
        ContactDetails {
          nextToken
          startedAt
          __typename
        }
        Locations {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Invoices {
          nextToken
          startedAt
          __typename
        }
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        BusinessUnits {
          nextToken
          startedAt
          __typename
        }
        Channels {
          nextToken
          startedAt
          __typename
        }
        Campaigns {
          nextToken
          startedAt
          __typename
        }
        Integrations {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        Employees {
          nextToken
          startedAt
          __typename
        }
        Equipment {
          nextToken
          startedAt
          __typename
        }
        Dashboards {
          nextToken
          startedAt
          __typename
        }
        DNIPools {
          nextToken
          startedAt
          __typename
        }
        ProgrammableDNIPools {
          nextToken
          startedAt
          __typename
        }
        TenantID
        clientView {
          nextToken
          startedAt
          __typename
        }
        ServiceTitanQueues {
          nextToken
          startedAt
          __typename
        }
        capacitySettings {
          id
          active
          capacityType
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bidderSettings {
          id
          blacklistedBusinessUnits
          blacklistedGoogleAdsCampaigns
          excludeFromCapacityBusinessUnits
          budgetDistributer
          active
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BudgetLogs {
          nextToken
          startedAt
          __typename
        }
        costSyncSettings {
          id
          googleAdsActive
          facebookAdsActive
          bingAdsActive
          lsaActive
          googleAdsCpcChannelID
          googleAdsCpmChannelID
          facebookChannelID
          bingChannelID
          lsaChannelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        clientsCapacitySettingsId
        clientsBidderSettingsId
        clientsCostSyncSettingsId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteCapacitySettings = /* GraphQL */ `
  mutation DeleteCapacitySettings(
    $input: DeleteCapacitySettingsInput!
    $condition: ModelCapacitySettingsConditionInput
  ) {
    deleteCapacitySettings(input: $input, condition: $condition) {
      id
      active
      capacityType
      clientID
      client {
        id
        name
        googleServiceAdsAccounts
        googleAdsCustomerID
        googleAnalyticsViewID
        ga4MeasurementID
        ga4Secret
        ga4PropertyID
        metaAdsAccount
        metaPixelID
        metaConversionsApiAccessToken
        currency
        bingAdsAccount
        Users {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        Contacts {
          nextToken
          startedAt
          __typename
        }
        ContactDetails {
          nextToken
          startedAt
          __typename
        }
        Locations {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Invoices {
          nextToken
          startedAt
          __typename
        }
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        BusinessUnits {
          nextToken
          startedAt
          __typename
        }
        Channels {
          nextToken
          startedAt
          __typename
        }
        Campaigns {
          nextToken
          startedAt
          __typename
        }
        Integrations {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        Employees {
          nextToken
          startedAt
          __typename
        }
        Equipment {
          nextToken
          startedAt
          __typename
        }
        Dashboards {
          nextToken
          startedAt
          __typename
        }
        DNIPools {
          nextToken
          startedAt
          __typename
        }
        ProgrammableDNIPools {
          nextToken
          startedAt
          __typename
        }
        TenantID
        clientView {
          nextToken
          startedAt
          __typename
        }
        ServiceTitanQueues {
          nextToken
          startedAt
          __typename
        }
        capacitySettings {
          id
          active
          capacityType
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bidderSettings {
          id
          blacklistedBusinessUnits
          blacklistedGoogleAdsCampaigns
          excludeFromCapacityBusinessUnits
          budgetDistributer
          active
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BudgetLogs {
          nextToken
          startedAt
          __typename
        }
        costSyncSettings {
          id
          googleAdsActive
          facebookAdsActive
          bingAdsActive
          lsaActive
          googleAdsCpcChannelID
          googleAdsCpmChannelID
          facebookChannelID
          bingChannelID
          lsaChannelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        clientsCapacitySettingsId
        clientsBidderSettingsId
        clientsCostSyncSettingsId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createCapacities = /* GraphQL */ `
  mutation CreateCapacities(
    $input: CreateCapacitiesInput!
    $condition: ModelCapacitiesConditionInput
  ) {
    createCapacities(input: $input, condition: $condition) {
      id
      date
      openHours
      totalHours
      businessUnitID
      businessUnit {
        id
        name
        Leads {
          nextToken
          startedAt
          __typename
        }
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        ClientID
        active
        maxCPC
        capacityWindow
        jobDuration
        monthlyBudget
        bidderBlockedBy
        bidderBlocked
        Capacities {
          nextToken
          startedAt
          __typename
        }
        BudgetAdjustments {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateCapacities = /* GraphQL */ `
  mutation UpdateCapacities(
    $input: UpdateCapacitiesInput!
    $condition: ModelCapacitiesConditionInput
  ) {
    updateCapacities(input: $input, condition: $condition) {
      id
      date
      openHours
      totalHours
      businessUnitID
      businessUnit {
        id
        name
        Leads {
          nextToken
          startedAt
          __typename
        }
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        ClientID
        active
        maxCPC
        capacityWindow
        jobDuration
        monthlyBudget
        bidderBlockedBy
        bidderBlocked
        Capacities {
          nextToken
          startedAt
          __typename
        }
        BudgetAdjustments {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteCapacities = /* GraphQL */ `
  mutation DeleteCapacities(
    $input: DeleteCapacitiesInput!
    $condition: ModelCapacitiesConditionInput
  ) {
    deleteCapacities(input: $input, condition: $condition) {
      id
      date
      openHours
      totalHours
      businessUnitID
      businessUnit {
        id
        name
        Leads {
          nextToken
          startedAt
          __typename
        }
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        ClientID
        active
        maxCPC
        capacityWindow
        jobDuration
        monthlyBudget
        bidderBlockedBy
        bidderBlocked
        Capacities {
          nextToken
          startedAt
          __typename
        }
        BudgetAdjustments {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createBidderSettings = /* GraphQL */ `
  mutation CreateBidderSettings(
    $input: CreateBidderSettingsInput!
    $condition: ModelBidderSettingsConditionInput
  ) {
    createBidderSettings(input: $input, condition: $condition) {
      id
      blacklistedBusinessUnits
      blacklistedGoogleAdsCampaigns
      excludeFromCapacityBusinessUnits
      budgetDistributer
      active
      clientID
      client {
        id
        name
        googleServiceAdsAccounts
        googleAdsCustomerID
        googleAnalyticsViewID
        ga4MeasurementID
        ga4Secret
        ga4PropertyID
        metaAdsAccount
        metaPixelID
        metaConversionsApiAccessToken
        currency
        bingAdsAccount
        Users {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        Contacts {
          nextToken
          startedAt
          __typename
        }
        ContactDetails {
          nextToken
          startedAt
          __typename
        }
        Locations {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Invoices {
          nextToken
          startedAt
          __typename
        }
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        BusinessUnits {
          nextToken
          startedAt
          __typename
        }
        Channels {
          nextToken
          startedAt
          __typename
        }
        Campaigns {
          nextToken
          startedAt
          __typename
        }
        Integrations {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        Employees {
          nextToken
          startedAt
          __typename
        }
        Equipment {
          nextToken
          startedAt
          __typename
        }
        Dashboards {
          nextToken
          startedAt
          __typename
        }
        DNIPools {
          nextToken
          startedAt
          __typename
        }
        ProgrammableDNIPools {
          nextToken
          startedAt
          __typename
        }
        TenantID
        clientView {
          nextToken
          startedAt
          __typename
        }
        ServiceTitanQueues {
          nextToken
          startedAt
          __typename
        }
        capacitySettings {
          id
          active
          capacityType
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bidderSettings {
          id
          blacklistedBusinessUnits
          blacklistedGoogleAdsCampaigns
          excludeFromCapacityBusinessUnits
          budgetDistributer
          active
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BudgetLogs {
          nextToken
          startedAt
          __typename
        }
        costSyncSettings {
          id
          googleAdsActive
          facebookAdsActive
          bingAdsActive
          lsaActive
          googleAdsCpcChannelID
          googleAdsCpmChannelID
          facebookChannelID
          bingChannelID
          lsaChannelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        clientsCapacitySettingsId
        clientsBidderSettingsId
        clientsCostSyncSettingsId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateBidderSettings = /* GraphQL */ `
  mutation UpdateBidderSettings(
    $input: UpdateBidderSettingsInput!
    $condition: ModelBidderSettingsConditionInput
  ) {
    updateBidderSettings(input: $input, condition: $condition) {
      id
      blacklistedBusinessUnits
      blacklistedGoogleAdsCampaigns
      excludeFromCapacityBusinessUnits
      budgetDistributer
      active
      clientID
      client {
        id
        name
        googleServiceAdsAccounts
        googleAdsCustomerID
        googleAnalyticsViewID
        ga4MeasurementID
        ga4Secret
        ga4PropertyID
        metaAdsAccount
        metaPixelID
        metaConversionsApiAccessToken
        currency
        bingAdsAccount
        Users {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        Contacts {
          nextToken
          startedAt
          __typename
        }
        ContactDetails {
          nextToken
          startedAt
          __typename
        }
        Locations {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Invoices {
          nextToken
          startedAt
          __typename
        }
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        BusinessUnits {
          nextToken
          startedAt
          __typename
        }
        Channels {
          nextToken
          startedAt
          __typename
        }
        Campaigns {
          nextToken
          startedAt
          __typename
        }
        Integrations {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        Employees {
          nextToken
          startedAt
          __typename
        }
        Equipment {
          nextToken
          startedAt
          __typename
        }
        Dashboards {
          nextToken
          startedAt
          __typename
        }
        DNIPools {
          nextToken
          startedAt
          __typename
        }
        ProgrammableDNIPools {
          nextToken
          startedAt
          __typename
        }
        TenantID
        clientView {
          nextToken
          startedAt
          __typename
        }
        ServiceTitanQueues {
          nextToken
          startedAt
          __typename
        }
        capacitySettings {
          id
          active
          capacityType
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bidderSettings {
          id
          blacklistedBusinessUnits
          blacklistedGoogleAdsCampaigns
          excludeFromCapacityBusinessUnits
          budgetDistributer
          active
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BudgetLogs {
          nextToken
          startedAt
          __typename
        }
        costSyncSettings {
          id
          googleAdsActive
          facebookAdsActive
          bingAdsActive
          lsaActive
          googleAdsCpcChannelID
          googleAdsCpmChannelID
          facebookChannelID
          bingChannelID
          lsaChannelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        clientsCapacitySettingsId
        clientsBidderSettingsId
        clientsCostSyncSettingsId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteBidderSettings = /* GraphQL */ `
  mutation DeleteBidderSettings(
    $input: DeleteBidderSettingsInput!
    $condition: ModelBidderSettingsConditionInput
  ) {
    deleteBidderSettings(input: $input, condition: $condition) {
      id
      blacklistedBusinessUnits
      blacklistedGoogleAdsCampaigns
      excludeFromCapacityBusinessUnits
      budgetDistributer
      active
      clientID
      client {
        id
        name
        googleServiceAdsAccounts
        googleAdsCustomerID
        googleAnalyticsViewID
        ga4MeasurementID
        ga4Secret
        ga4PropertyID
        metaAdsAccount
        metaPixelID
        metaConversionsApiAccessToken
        currency
        bingAdsAccount
        Users {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        Contacts {
          nextToken
          startedAt
          __typename
        }
        ContactDetails {
          nextToken
          startedAt
          __typename
        }
        Locations {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Invoices {
          nextToken
          startedAt
          __typename
        }
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        BusinessUnits {
          nextToken
          startedAt
          __typename
        }
        Channels {
          nextToken
          startedAt
          __typename
        }
        Campaigns {
          nextToken
          startedAt
          __typename
        }
        Integrations {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        Employees {
          nextToken
          startedAt
          __typename
        }
        Equipment {
          nextToken
          startedAt
          __typename
        }
        Dashboards {
          nextToken
          startedAt
          __typename
        }
        DNIPools {
          nextToken
          startedAt
          __typename
        }
        ProgrammableDNIPools {
          nextToken
          startedAt
          __typename
        }
        TenantID
        clientView {
          nextToken
          startedAt
          __typename
        }
        ServiceTitanQueues {
          nextToken
          startedAt
          __typename
        }
        capacitySettings {
          id
          active
          capacityType
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bidderSettings {
          id
          blacklistedBusinessUnits
          blacklistedGoogleAdsCampaigns
          excludeFromCapacityBusinessUnits
          budgetDistributer
          active
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BudgetLogs {
          nextToken
          startedAt
          __typename
        }
        costSyncSettings {
          id
          googleAdsActive
          facebookAdsActive
          bingAdsActive
          lsaActive
          googleAdsCpcChannelID
          googleAdsCpmChannelID
          facebookChannelID
          bingChannelID
          lsaChannelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        clientsCapacitySettingsId
        clientsBidderSettingsId
        clientsCostSyncSettingsId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createBudgetAdjustments = /* GraphQL */ `
  mutation CreateBudgetAdjustments(
    $input: CreateBudgetAdjustmentsInput!
    $condition: ModelBudgetAdjustmentsConditionInput
  ) {
    createBudgetAdjustments(input: $input, condition: $condition) {
      id
      businessUnitID
      businessUnit {
        id
        name
        Leads {
          nextToken
          startedAt
          __typename
        }
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        ClientID
        active
        maxCPC
        capacityWindow
        jobDuration
        monthlyBudget
        bidderBlockedBy
        bidderBlocked
        Capacities {
          nextToken
          startedAt
          __typename
        }
        BudgetAdjustments {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      adjustment
      reason
      dateApplied
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateBudgetAdjustments = /* GraphQL */ `
  mutation UpdateBudgetAdjustments(
    $input: UpdateBudgetAdjustmentsInput!
    $condition: ModelBudgetAdjustmentsConditionInput
  ) {
    updateBudgetAdjustments(input: $input, condition: $condition) {
      id
      businessUnitID
      businessUnit {
        id
        name
        Leads {
          nextToken
          startedAt
          __typename
        }
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        ClientID
        active
        maxCPC
        capacityWindow
        jobDuration
        monthlyBudget
        bidderBlockedBy
        bidderBlocked
        Capacities {
          nextToken
          startedAt
          __typename
        }
        BudgetAdjustments {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      adjustment
      reason
      dateApplied
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteBudgetAdjustments = /* GraphQL */ `
  mutation DeleteBudgetAdjustments(
    $input: DeleteBudgetAdjustmentsInput!
    $condition: ModelBudgetAdjustmentsConditionInput
  ) {
    deleteBudgetAdjustments(input: $input, condition: $condition) {
      id
      businessUnitID
      businessUnit {
        id
        name
        Leads {
          nextToken
          startedAt
          __typename
        }
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        ClientID
        active
        maxCPC
        capacityWindow
        jobDuration
        monthlyBudget
        bidderBlockedBy
        bidderBlocked
        Capacities {
          nextToken
          startedAt
          __typename
        }
        BudgetAdjustments {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      adjustment
      reason
      dateApplied
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createBudgetLogs = /* GraphQL */ `
  mutation CreateBudgetLogs(
    $input: CreateBudgetLogsInput!
    $condition: ModelBudgetLogsConditionInput
  ) {
    createBudgetLogs(input: $input, condition: $condition) {
      id
      bidType
      bidProperty
      propertyId
      previousValue
      updatedValue
      description
      createdAt
      clientID
      client {
        id
        name
        googleServiceAdsAccounts
        googleAdsCustomerID
        googleAnalyticsViewID
        ga4MeasurementID
        ga4Secret
        ga4PropertyID
        metaAdsAccount
        metaPixelID
        metaConversionsApiAccessToken
        currency
        bingAdsAccount
        Users {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        Contacts {
          nextToken
          startedAt
          __typename
        }
        ContactDetails {
          nextToken
          startedAt
          __typename
        }
        Locations {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Invoices {
          nextToken
          startedAt
          __typename
        }
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        BusinessUnits {
          nextToken
          startedAt
          __typename
        }
        Channels {
          nextToken
          startedAt
          __typename
        }
        Campaigns {
          nextToken
          startedAt
          __typename
        }
        Integrations {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        Employees {
          nextToken
          startedAt
          __typename
        }
        Equipment {
          nextToken
          startedAt
          __typename
        }
        Dashboards {
          nextToken
          startedAt
          __typename
        }
        DNIPools {
          nextToken
          startedAt
          __typename
        }
        ProgrammableDNIPools {
          nextToken
          startedAt
          __typename
        }
        TenantID
        clientView {
          nextToken
          startedAt
          __typename
        }
        ServiceTitanQueues {
          nextToken
          startedAt
          __typename
        }
        capacitySettings {
          id
          active
          capacityType
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bidderSettings {
          id
          blacklistedBusinessUnits
          blacklistedGoogleAdsCampaigns
          excludeFromCapacityBusinessUnits
          budgetDistributer
          active
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BudgetLogs {
          nextToken
          startedAt
          __typename
        }
        costSyncSettings {
          id
          googleAdsActive
          facebookAdsActive
          bingAdsActive
          lsaActive
          googleAdsCpcChannelID
          googleAdsCpmChannelID
          facebookChannelID
          bingChannelID
          lsaChannelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        clientsCapacitySettingsId
        clientsBidderSettingsId
        clientsCostSyncSettingsId
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateBudgetLogs = /* GraphQL */ `
  mutation UpdateBudgetLogs(
    $input: UpdateBudgetLogsInput!
    $condition: ModelBudgetLogsConditionInput
  ) {
    updateBudgetLogs(input: $input, condition: $condition) {
      id
      bidType
      bidProperty
      propertyId
      previousValue
      updatedValue
      description
      createdAt
      clientID
      client {
        id
        name
        googleServiceAdsAccounts
        googleAdsCustomerID
        googleAnalyticsViewID
        ga4MeasurementID
        ga4Secret
        ga4PropertyID
        metaAdsAccount
        metaPixelID
        metaConversionsApiAccessToken
        currency
        bingAdsAccount
        Users {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        Contacts {
          nextToken
          startedAt
          __typename
        }
        ContactDetails {
          nextToken
          startedAt
          __typename
        }
        Locations {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Invoices {
          nextToken
          startedAt
          __typename
        }
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        BusinessUnits {
          nextToken
          startedAt
          __typename
        }
        Channels {
          nextToken
          startedAt
          __typename
        }
        Campaigns {
          nextToken
          startedAt
          __typename
        }
        Integrations {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        Employees {
          nextToken
          startedAt
          __typename
        }
        Equipment {
          nextToken
          startedAt
          __typename
        }
        Dashboards {
          nextToken
          startedAt
          __typename
        }
        DNIPools {
          nextToken
          startedAt
          __typename
        }
        ProgrammableDNIPools {
          nextToken
          startedAt
          __typename
        }
        TenantID
        clientView {
          nextToken
          startedAt
          __typename
        }
        ServiceTitanQueues {
          nextToken
          startedAt
          __typename
        }
        capacitySettings {
          id
          active
          capacityType
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bidderSettings {
          id
          blacklistedBusinessUnits
          blacklistedGoogleAdsCampaigns
          excludeFromCapacityBusinessUnits
          budgetDistributer
          active
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BudgetLogs {
          nextToken
          startedAt
          __typename
        }
        costSyncSettings {
          id
          googleAdsActive
          facebookAdsActive
          bingAdsActive
          lsaActive
          googleAdsCpcChannelID
          googleAdsCpmChannelID
          facebookChannelID
          bingChannelID
          lsaChannelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        clientsCapacitySettingsId
        clientsBidderSettingsId
        clientsCostSyncSettingsId
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteBudgetLogs = /* GraphQL */ `
  mutation DeleteBudgetLogs(
    $input: DeleteBudgetLogsInput!
    $condition: ModelBudgetLogsConditionInput
  ) {
    deleteBudgetLogs(input: $input, condition: $condition) {
      id
      bidType
      bidProperty
      propertyId
      previousValue
      updatedValue
      description
      createdAt
      clientID
      client {
        id
        name
        googleServiceAdsAccounts
        googleAdsCustomerID
        googleAnalyticsViewID
        ga4MeasurementID
        ga4Secret
        ga4PropertyID
        metaAdsAccount
        metaPixelID
        metaConversionsApiAccessToken
        currency
        bingAdsAccount
        Users {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        Contacts {
          nextToken
          startedAt
          __typename
        }
        ContactDetails {
          nextToken
          startedAt
          __typename
        }
        Locations {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Invoices {
          nextToken
          startedAt
          __typename
        }
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        BusinessUnits {
          nextToken
          startedAt
          __typename
        }
        Channels {
          nextToken
          startedAt
          __typename
        }
        Campaigns {
          nextToken
          startedAt
          __typename
        }
        Integrations {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        Employees {
          nextToken
          startedAt
          __typename
        }
        Equipment {
          nextToken
          startedAt
          __typename
        }
        Dashboards {
          nextToken
          startedAt
          __typename
        }
        DNIPools {
          nextToken
          startedAt
          __typename
        }
        ProgrammableDNIPools {
          nextToken
          startedAt
          __typename
        }
        TenantID
        clientView {
          nextToken
          startedAt
          __typename
        }
        ServiceTitanQueues {
          nextToken
          startedAt
          __typename
        }
        capacitySettings {
          id
          active
          capacityType
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bidderSettings {
          id
          blacklistedBusinessUnits
          blacklistedGoogleAdsCampaigns
          excludeFromCapacityBusinessUnits
          budgetDistributer
          active
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BudgetLogs {
          nextToken
          startedAt
          __typename
        }
        costSyncSettings {
          id
          googleAdsActive
          facebookAdsActive
          bingAdsActive
          lsaActive
          googleAdsCpcChannelID
          googleAdsCpmChannelID
          facebookChannelID
          bingChannelID
          lsaChannelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        clientsCapacitySettingsId
        clientsBidderSettingsId
        clientsCostSyncSettingsId
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createCostSyncSettings = /* GraphQL */ `
  mutation CreateCostSyncSettings(
    $input: CreateCostSyncSettingsInput!
    $condition: ModelCostSyncSettingsConditionInput
  ) {
    createCostSyncSettings(input: $input, condition: $condition) {
      id
      googleAdsActive
      facebookAdsActive
      bingAdsActive
      lsaActive
      googleAdsCpcChannelID
      googleAdsCpmChannelID
      facebookChannelID
      bingChannelID
      lsaChannelID
      clientID
      client {
        id
        name
        googleServiceAdsAccounts
        googleAdsCustomerID
        googleAnalyticsViewID
        ga4MeasurementID
        ga4Secret
        ga4PropertyID
        metaAdsAccount
        metaPixelID
        metaConversionsApiAccessToken
        currency
        bingAdsAccount
        Users {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        Contacts {
          nextToken
          startedAt
          __typename
        }
        ContactDetails {
          nextToken
          startedAt
          __typename
        }
        Locations {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Invoices {
          nextToken
          startedAt
          __typename
        }
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        BusinessUnits {
          nextToken
          startedAt
          __typename
        }
        Channels {
          nextToken
          startedAt
          __typename
        }
        Campaigns {
          nextToken
          startedAt
          __typename
        }
        Integrations {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        Employees {
          nextToken
          startedAt
          __typename
        }
        Equipment {
          nextToken
          startedAt
          __typename
        }
        Dashboards {
          nextToken
          startedAt
          __typename
        }
        DNIPools {
          nextToken
          startedAt
          __typename
        }
        ProgrammableDNIPools {
          nextToken
          startedAt
          __typename
        }
        TenantID
        clientView {
          nextToken
          startedAt
          __typename
        }
        ServiceTitanQueues {
          nextToken
          startedAt
          __typename
        }
        capacitySettings {
          id
          active
          capacityType
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bidderSettings {
          id
          blacklistedBusinessUnits
          blacklistedGoogleAdsCampaigns
          excludeFromCapacityBusinessUnits
          budgetDistributer
          active
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BudgetLogs {
          nextToken
          startedAt
          __typename
        }
        costSyncSettings {
          id
          googleAdsActive
          facebookAdsActive
          bingAdsActive
          lsaActive
          googleAdsCpcChannelID
          googleAdsCpmChannelID
          facebookChannelID
          bingChannelID
          lsaChannelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        clientsCapacitySettingsId
        clientsBidderSettingsId
        clientsCostSyncSettingsId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateCostSyncSettings = /* GraphQL */ `
  mutation UpdateCostSyncSettings(
    $input: UpdateCostSyncSettingsInput!
    $condition: ModelCostSyncSettingsConditionInput
  ) {
    updateCostSyncSettings(input: $input, condition: $condition) {
      id
      googleAdsActive
      facebookAdsActive
      bingAdsActive
      lsaActive
      googleAdsCpcChannelID
      googleAdsCpmChannelID
      facebookChannelID
      bingChannelID
      lsaChannelID
      clientID
      client {
        id
        name
        googleServiceAdsAccounts
        googleAdsCustomerID
        googleAnalyticsViewID
        ga4MeasurementID
        ga4Secret
        ga4PropertyID
        metaAdsAccount
        metaPixelID
        metaConversionsApiAccessToken
        currency
        bingAdsAccount
        Users {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        Contacts {
          nextToken
          startedAt
          __typename
        }
        ContactDetails {
          nextToken
          startedAt
          __typename
        }
        Locations {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Invoices {
          nextToken
          startedAt
          __typename
        }
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        BusinessUnits {
          nextToken
          startedAt
          __typename
        }
        Channels {
          nextToken
          startedAt
          __typename
        }
        Campaigns {
          nextToken
          startedAt
          __typename
        }
        Integrations {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        Employees {
          nextToken
          startedAt
          __typename
        }
        Equipment {
          nextToken
          startedAt
          __typename
        }
        Dashboards {
          nextToken
          startedAt
          __typename
        }
        DNIPools {
          nextToken
          startedAt
          __typename
        }
        ProgrammableDNIPools {
          nextToken
          startedAt
          __typename
        }
        TenantID
        clientView {
          nextToken
          startedAt
          __typename
        }
        ServiceTitanQueues {
          nextToken
          startedAt
          __typename
        }
        capacitySettings {
          id
          active
          capacityType
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bidderSettings {
          id
          blacklistedBusinessUnits
          blacklistedGoogleAdsCampaigns
          excludeFromCapacityBusinessUnits
          budgetDistributer
          active
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BudgetLogs {
          nextToken
          startedAt
          __typename
        }
        costSyncSettings {
          id
          googleAdsActive
          facebookAdsActive
          bingAdsActive
          lsaActive
          googleAdsCpcChannelID
          googleAdsCpmChannelID
          facebookChannelID
          bingChannelID
          lsaChannelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        clientsCapacitySettingsId
        clientsBidderSettingsId
        clientsCostSyncSettingsId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteCostSyncSettings = /* GraphQL */ `
  mutation DeleteCostSyncSettings(
    $input: DeleteCostSyncSettingsInput!
    $condition: ModelCostSyncSettingsConditionInput
  ) {
    deleteCostSyncSettings(input: $input, condition: $condition) {
      id
      googleAdsActive
      facebookAdsActive
      bingAdsActive
      lsaActive
      googleAdsCpcChannelID
      googleAdsCpmChannelID
      facebookChannelID
      bingChannelID
      lsaChannelID
      clientID
      client {
        id
        name
        googleServiceAdsAccounts
        googleAdsCustomerID
        googleAnalyticsViewID
        ga4MeasurementID
        ga4Secret
        ga4PropertyID
        metaAdsAccount
        metaPixelID
        metaConversionsApiAccessToken
        currency
        bingAdsAccount
        Users {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        Contacts {
          nextToken
          startedAt
          __typename
        }
        ContactDetails {
          nextToken
          startedAt
          __typename
        }
        Locations {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Invoices {
          nextToken
          startedAt
          __typename
        }
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        BusinessUnits {
          nextToken
          startedAt
          __typename
        }
        Channels {
          nextToken
          startedAt
          __typename
        }
        Campaigns {
          nextToken
          startedAt
          __typename
        }
        Integrations {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        Employees {
          nextToken
          startedAt
          __typename
        }
        Equipment {
          nextToken
          startedAt
          __typename
        }
        Dashboards {
          nextToken
          startedAt
          __typename
        }
        DNIPools {
          nextToken
          startedAt
          __typename
        }
        ProgrammableDNIPools {
          nextToken
          startedAt
          __typename
        }
        TenantID
        clientView {
          nextToken
          startedAt
          __typename
        }
        ServiceTitanQueues {
          nextToken
          startedAt
          __typename
        }
        capacitySettings {
          id
          active
          capacityType
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bidderSettings {
          id
          blacklistedBusinessUnits
          blacklistedGoogleAdsCampaigns
          excludeFromCapacityBusinessUnits
          budgetDistributer
          active
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BudgetLogs {
          nextToken
          startedAt
          __typename
        }
        costSyncSettings {
          id
          googleAdsActive
          facebookAdsActive
          bingAdsActive
          lsaActive
          googleAdsCpcChannelID
          googleAdsCpmChannelID
          facebookChannelID
          bingChannelID
          lsaChannelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        clientsCapacitySettingsId
        clientsBidderSettingsId
        clientsCostSyncSettingsId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createClientUsers = /* GraphQL */ `
  mutation CreateClientUsers(
    $input: CreateClientUsersInput!
    $condition: ModelClientUsersConditionInput
  ) {
    createClientUsers(input: $input, condition: $condition) {
      id
      clientsId
      usersId
      clients {
        id
        name
        googleServiceAdsAccounts
        googleAdsCustomerID
        googleAnalyticsViewID
        ga4MeasurementID
        ga4Secret
        ga4PropertyID
        metaAdsAccount
        metaPixelID
        metaConversionsApiAccessToken
        currency
        bingAdsAccount
        Users {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        Contacts {
          nextToken
          startedAt
          __typename
        }
        ContactDetails {
          nextToken
          startedAt
          __typename
        }
        Locations {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Invoices {
          nextToken
          startedAt
          __typename
        }
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        BusinessUnits {
          nextToken
          startedAt
          __typename
        }
        Channels {
          nextToken
          startedAt
          __typename
        }
        Campaigns {
          nextToken
          startedAt
          __typename
        }
        Integrations {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        Employees {
          nextToken
          startedAt
          __typename
        }
        Equipment {
          nextToken
          startedAt
          __typename
        }
        Dashboards {
          nextToken
          startedAt
          __typename
        }
        DNIPools {
          nextToken
          startedAt
          __typename
        }
        ProgrammableDNIPools {
          nextToken
          startedAt
          __typename
        }
        TenantID
        clientView {
          nextToken
          startedAt
          __typename
        }
        ServiceTitanQueues {
          nextToken
          startedAt
          __typename
        }
        capacitySettings {
          id
          active
          capacityType
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bidderSettings {
          id
          blacklistedBusinessUnits
          blacklistedGoogleAdsCampaigns
          excludeFromCapacityBusinessUnits
          budgetDistributer
          active
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BudgetLogs {
          nextToken
          startedAt
          __typename
        }
        costSyncSettings {
          id
          googleAdsActive
          facebookAdsActive
          bingAdsActive
          lsaActive
          googleAdsCpcChannelID
          googleAdsCpmChannelID
          facebookChannelID
          bingChannelID
          lsaChannelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        clientsCapacitySettingsId
        clientsBidderSettingsId
        clientsCostSyncSettingsId
        __typename
      }
      users {
        id
        name
        username
        email
        phone
        type
        clientView
        TenantID
        Clients {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateClientUsers = /* GraphQL */ `
  mutation UpdateClientUsers(
    $input: UpdateClientUsersInput!
    $condition: ModelClientUsersConditionInput
  ) {
    updateClientUsers(input: $input, condition: $condition) {
      id
      clientsId
      usersId
      clients {
        id
        name
        googleServiceAdsAccounts
        googleAdsCustomerID
        googleAnalyticsViewID
        ga4MeasurementID
        ga4Secret
        ga4PropertyID
        metaAdsAccount
        metaPixelID
        metaConversionsApiAccessToken
        currency
        bingAdsAccount
        Users {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        Contacts {
          nextToken
          startedAt
          __typename
        }
        ContactDetails {
          nextToken
          startedAt
          __typename
        }
        Locations {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Invoices {
          nextToken
          startedAt
          __typename
        }
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        BusinessUnits {
          nextToken
          startedAt
          __typename
        }
        Channels {
          nextToken
          startedAt
          __typename
        }
        Campaigns {
          nextToken
          startedAt
          __typename
        }
        Integrations {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        Employees {
          nextToken
          startedAt
          __typename
        }
        Equipment {
          nextToken
          startedAt
          __typename
        }
        Dashboards {
          nextToken
          startedAt
          __typename
        }
        DNIPools {
          nextToken
          startedAt
          __typename
        }
        ProgrammableDNIPools {
          nextToken
          startedAt
          __typename
        }
        TenantID
        clientView {
          nextToken
          startedAt
          __typename
        }
        ServiceTitanQueues {
          nextToken
          startedAt
          __typename
        }
        capacitySettings {
          id
          active
          capacityType
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bidderSettings {
          id
          blacklistedBusinessUnits
          blacklistedGoogleAdsCampaigns
          excludeFromCapacityBusinessUnits
          budgetDistributer
          active
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BudgetLogs {
          nextToken
          startedAt
          __typename
        }
        costSyncSettings {
          id
          googleAdsActive
          facebookAdsActive
          bingAdsActive
          lsaActive
          googleAdsCpcChannelID
          googleAdsCpmChannelID
          facebookChannelID
          bingChannelID
          lsaChannelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        clientsCapacitySettingsId
        clientsBidderSettingsId
        clientsCostSyncSettingsId
        __typename
      }
      users {
        id
        name
        username
        email
        phone
        type
        clientView
        TenantID
        Clients {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteClientUsers = /* GraphQL */ `
  mutation DeleteClientUsers(
    $input: DeleteClientUsersInput!
    $condition: ModelClientUsersConditionInput
  ) {
    deleteClientUsers(input: $input, condition: $condition) {
      id
      clientsId
      usersId
      clients {
        id
        name
        googleServiceAdsAccounts
        googleAdsCustomerID
        googleAnalyticsViewID
        ga4MeasurementID
        ga4Secret
        ga4PropertyID
        metaAdsAccount
        metaPixelID
        metaConversionsApiAccessToken
        currency
        bingAdsAccount
        Users {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        Contacts {
          nextToken
          startedAt
          __typename
        }
        ContactDetails {
          nextToken
          startedAt
          __typename
        }
        Locations {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Invoices {
          nextToken
          startedAt
          __typename
        }
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        BusinessUnits {
          nextToken
          startedAt
          __typename
        }
        Channels {
          nextToken
          startedAt
          __typename
        }
        Campaigns {
          nextToken
          startedAt
          __typename
        }
        Integrations {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        Employees {
          nextToken
          startedAt
          __typename
        }
        Equipment {
          nextToken
          startedAt
          __typename
        }
        Dashboards {
          nextToken
          startedAt
          __typename
        }
        DNIPools {
          nextToken
          startedAt
          __typename
        }
        ProgrammableDNIPools {
          nextToken
          startedAt
          __typename
        }
        TenantID
        clientView {
          nextToken
          startedAt
          __typename
        }
        ServiceTitanQueues {
          nextToken
          startedAt
          __typename
        }
        capacitySettings {
          id
          active
          capacityType
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bidderSettings {
          id
          blacklistedBusinessUnits
          blacklistedGoogleAdsCampaigns
          excludeFromCapacityBusinessUnits
          budgetDistributer
          active
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BudgetLogs {
          nextToken
          startedAt
          __typename
        }
        costSyncSettings {
          id
          googleAdsActive
          facebookAdsActive
          bingAdsActive
          lsaActive
          googleAdsCpcChannelID
          googleAdsCpmChannelID
          facebookChannelID
          bingChannelID
          lsaChannelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        clientsCapacitySettingsId
        clientsBidderSettingsId
        clientsCostSyncSettingsId
        __typename
      }
      users {
        id
        name
        username
        email
        phone
        type
        clientView
        TenantID
        Clients {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createEmployeeLeads = /* GraphQL */ `
  mutation CreateEmployeeLeads(
    $input: CreateEmployeeLeadsInput!
    $condition: ModelEmployeeLeadsConditionInput
  ) {
    createEmployeeLeads(input: $input, condition: $condition) {
      id
      leadsId
      employeesId
      leads {
        id
        name
        description
        type
        details
        value
        status
        state
        twillioSID
        email
        phoneNumber
        crmID
        source
        createdAt
        PhoneNumberID
        Employees {
          nextToken
          startedAt
          __typename
        }
        WebSessionID
        WebSession {
          id
          ipAddress
          status
          startTime
          endTime
          domain
          gacid
          gclid
          fbclid
          msclkid
          referrer
          utmSource
          utmMedium
          utmCampaign
          utmAdgroup
          utmTerm
          utmContent
          utmId
          utmDevice
          utmAdgroupName
          utmPromotion
          queryParameters
          lastPingedOn
          createdAt
          PhoneNumberID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BusinessUnitID
        ChannelID
        Channel {
          id
          name
          budget
          ClientID
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        CampaignID
        ClientID
        ContactID
        Contact {
          id
          name
          emails
          phones
          crmID
          source
          createdAt
          ClientID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Locations {
          id
          name
          street1
          street2
          city
          state
          zip
          crmID
          source
          createdAt
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Jobs {
          id
          name
          description
          status
          value
          completedOn
          crmID
          source
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          LocationID
          LeadID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        callDuration
        recordingDetails {
          recordingURL
          recordingDuration
          recordingSID
          callRating
          __typename
        }
        entrySource
        whatconvertsWebSession
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      employees {
        id
        name
        jobTitle
        Leads {
          nextToken
          startedAt
          __typename
        }
        Job {
          nextToken
          startedAt
          __typename
        }
        ClientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateEmployeeLeads = /* GraphQL */ `
  mutation UpdateEmployeeLeads(
    $input: UpdateEmployeeLeadsInput!
    $condition: ModelEmployeeLeadsConditionInput
  ) {
    updateEmployeeLeads(input: $input, condition: $condition) {
      id
      leadsId
      employeesId
      leads {
        id
        name
        description
        type
        details
        value
        status
        state
        twillioSID
        email
        phoneNumber
        crmID
        source
        createdAt
        PhoneNumberID
        Employees {
          nextToken
          startedAt
          __typename
        }
        WebSessionID
        WebSession {
          id
          ipAddress
          status
          startTime
          endTime
          domain
          gacid
          gclid
          fbclid
          msclkid
          referrer
          utmSource
          utmMedium
          utmCampaign
          utmAdgroup
          utmTerm
          utmContent
          utmId
          utmDevice
          utmAdgroupName
          utmPromotion
          queryParameters
          lastPingedOn
          createdAt
          PhoneNumberID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BusinessUnitID
        ChannelID
        Channel {
          id
          name
          budget
          ClientID
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        CampaignID
        ClientID
        ContactID
        Contact {
          id
          name
          emails
          phones
          crmID
          source
          createdAt
          ClientID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Locations {
          id
          name
          street1
          street2
          city
          state
          zip
          crmID
          source
          createdAt
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Jobs {
          id
          name
          description
          status
          value
          completedOn
          crmID
          source
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          LocationID
          LeadID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        callDuration
        recordingDetails {
          recordingURL
          recordingDuration
          recordingSID
          callRating
          __typename
        }
        entrySource
        whatconvertsWebSession
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      employees {
        id
        name
        jobTitle
        Leads {
          nextToken
          startedAt
          __typename
        }
        Job {
          nextToken
          startedAt
          __typename
        }
        ClientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteEmployeeLeads = /* GraphQL */ `
  mutation DeleteEmployeeLeads(
    $input: DeleteEmployeeLeadsInput!
    $condition: ModelEmployeeLeadsConditionInput
  ) {
    deleteEmployeeLeads(input: $input, condition: $condition) {
      id
      leadsId
      employeesId
      leads {
        id
        name
        description
        type
        details
        value
        status
        state
        twillioSID
        email
        phoneNumber
        crmID
        source
        createdAt
        PhoneNumberID
        Employees {
          nextToken
          startedAt
          __typename
        }
        WebSessionID
        WebSession {
          id
          ipAddress
          status
          startTime
          endTime
          domain
          gacid
          gclid
          fbclid
          msclkid
          referrer
          utmSource
          utmMedium
          utmCampaign
          utmAdgroup
          utmTerm
          utmContent
          utmId
          utmDevice
          utmAdgroupName
          utmPromotion
          queryParameters
          lastPingedOn
          createdAt
          PhoneNumberID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BusinessUnitID
        ChannelID
        Channel {
          id
          name
          budget
          ClientID
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        CampaignID
        ClientID
        ContactID
        Contact {
          id
          name
          emails
          phones
          crmID
          source
          createdAt
          ClientID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Locations {
          id
          name
          street1
          street2
          city
          state
          zip
          crmID
          source
          createdAt
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Jobs {
          id
          name
          description
          status
          value
          completedOn
          crmID
          source
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          LocationID
          LeadID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        callDuration
        recordingDetails {
          recordingURL
          recordingDuration
          recordingSID
          callRating
          __typename
        }
        entrySource
        whatconvertsWebSession
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      employees {
        id
        name
        jobTitle
        Leads {
          nextToken
          startedAt
          __typename
        }
        Job {
          nextToken
          startedAt
          __typename
        }
        ClientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createEmployeeJobs = /* GraphQL */ `
  mutation CreateEmployeeJobs(
    $input: CreateEmployeeJobsInput!
    $condition: ModelEmployeeJobsConditionInput
  ) {
    createEmployeeJobs(input: $input, condition: $condition) {
      id
      jobsId
      employeesId
      jobs {
        id
        name
        description
        status
        value
        completedOn
        crmID
        source
        createdAt
        Employees {
          nextToken
          startedAt
          __typename
        }
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        LocationID
        LeadID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      employees {
        id
        name
        jobTitle
        Leads {
          nextToken
          startedAt
          __typename
        }
        Job {
          nextToken
          startedAt
          __typename
        }
        ClientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateEmployeeJobs = /* GraphQL */ `
  mutation UpdateEmployeeJobs(
    $input: UpdateEmployeeJobsInput!
    $condition: ModelEmployeeJobsConditionInput
  ) {
    updateEmployeeJobs(input: $input, condition: $condition) {
      id
      jobsId
      employeesId
      jobs {
        id
        name
        description
        status
        value
        completedOn
        crmID
        source
        createdAt
        Employees {
          nextToken
          startedAt
          __typename
        }
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        LocationID
        LeadID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      employees {
        id
        name
        jobTitle
        Leads {
          nextToken
          startedAt
          __typename
        }
        Job {
          nextToken
          startedAt
          __typename
        }
        ClientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteEmployeeJobs = /* GraphQL */ `
  mutation DeleteEmployeeJobs(
    $input: DeleteEmployeeJobsInput!
    $condition: ModelEmployeeJobsConditionInput
  ) {
    deleteEmployeeJobs(input: $input, condition: $condition) {
      id
      jobsId
      employeesId
      jobs {
        id
        name
        description
        status
        value
        completedOn
        crmID
        source
        createdAt
        Employees {
          nextToken
          startedAt
          __typename
        }
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        LocationID
        LeadID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      employees {
        id
        name
        jobTitle
        Leads {
          nextToken
          startedAt
          __typename
        }
        Job {
          nextToken
          startedAt
          __typename
        }
        ClientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
