import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

//queries
import { v4 as uuidv4 } from "uuid";
import { API, DataStore, graphqlOperation } from "aws-amplify";
import { listTenants } from "graphql/queries";
import { createUsers } from "graphql/mutations";
import CreateUsers2 from "ui-components/CreateUsers2";
import CreateUser from "./CreateUserForm";
import UpdateUser from "ui-components/UpdateUser";

// material-ui
import { useTheme } from "@mui/material/styles";
import { openSnackbar } from "store/reducers/snackbar";
import { useNavigate } from "react-router";

const avatarImage = require.context("assets/images/users", true);

// const fetchTenants = async (next) => {
//   const apiData = await API.graphql(
//     graphqlOperation(listTenants, {
//       limit: 30000,
//       nextToken: next,
//     })
//   );
//   var tenants = apiData.data.listTenants.items;
//   return tenants;
// };

//creating user in Cognito vvvvvv
const updateUserCog = async (username, name, email, phone, type, TenantID) => {
  // console.log(username, name, email, phone, type, TenantID);
  if (!TenantID || !name || !email || !username || !type) return;
  var cognitoUserObject = await API.post(
    "userManagementRest",
    "/users/update",
    {
      body: { name, email, username },
    }
  );
  if (cognitoUserObject?.status !== "success") {
    return {};
  } else {
    // console.log(cognitoUserObject);
    return cognitoUserObject;
  }
};

// ==============================|| USER ADD / EDIT / DELETE ||============================== //

const UpdateUsers = ({
  user,
  onCancel,
  add,
  reload,
  setReload,
  selectedUser,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isCreating = !user;
  const [selectedImage, setSelectedImage] = useState(undefined);
  const [tenants, setTenants] = useState(undefined);
  // const [avatar, setAvatar] = useState(
  //   avatarImage(`./avatar-${isCreating && !user?.avatar ? 1 : user.avatar}.png`)
  // );

  // useEffect(() => {
  // console.log(selectedUser);
  //   if (selectedImage) {
  //     setAvatar(URL.createObjectURL(selectedImage));
  //   }
  // }, [selectedImage]);
  return (
    <UpdateUser
      // overrides={{type: {value}}}
      id={selectedUser?.id}
      users={selectedUser}
      onSubmit={(fields) => {
        // Example function to trim all string inputs
        const updatedFields = {};
        Object.keys(fields).forEach((key) => {
          // console.log(fields[key]);
          if (typeof fields[key] === "string") {
            updatedFields[key] = fields[key].trim();
          } else {
            updatedFields[key] = fields[key];
          }
        });
        updatedFields.username = selectedUser.username;
        updatedFields.TenantID = selectedUser.TenantID;
        if (updatedFields) {
        }
        updateUserCog(
          updatedFields.username,
          updatedFields.name,
          updatedFields.email,
          updatedFields?.phone?.length ? updatedFields.phone : null,
          updatedFields.type,
          updatedFields.TenantID
        )
          .then((res) => {
            // console.log(res);
            // onCancel();
            return updatedFields;
          })
          .catch((err) => {
            console.log(err);
          });
      }}
      onSuccess={async () => {
        await DataStore.start().then(() => {
          dispatch(
            openSnackbar({
              open: true,
              message: `User Updated Successfully!`,
              variant: "alert",
              alert: {
                color: "success",
              },
              close: false,
            })
          );
          // setTimeout(() => {
          //   navigate(0);
          // }, 2000);

          onCancel();
        });
      }}
      onError={(err) => {
        dispatch(
          openSnackbar({
            open: true,
            message: `Error Updating User!`,
            variant: "alert",
            alert: {
              color: "error",
            },
            close: false,
          })
        );
        console.log(err);
      }}
      onCancel={() => {
        // setReload(reload + 1);
        onCancel();
      }}
    />
  );
};

export default UpdateUsers;
