import { green, yellow, red } from "@mui/material/colors";

export const ratingColors = {
  Good: green[500],
  Bad: yellow[700],
  Spam: red[500],
  default: "gray", // Default color when no rating is available
};

// Define options for the dropdown
export const ratingOptions = [
  { value: "Good", label: "Good", color: green[500] },
  { value: "Bad", label: "Bad", color: yellow[700] },
  { value: "Spam", label: "Spam", color: red[500] },
];

export const adminUserTypes = [{ value: "ADMIN", label: "Admin" }];

export const userTypes = [
  { value: "TENANT", label: "Tenant" },
  { value: "CLIENT", label: "Client" },
];

export const PROFITFILL_USER_TYPES = {
  ADMIN: "ADMIN",
  TENANT: "TENANT",
  CLIENT: "CLIENT",
};