import { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  useMediaQuery,
  Box,
  Container,
  Toolbar,
  CircularProgress,
} from "@mui/material";

// project import
import Drawer from "./Drawer";
import Header from "./Header";
import Footer from "./Footer";
import Menu from "menu-items";
import navigation from "menu-items";
import useConfig from "hooks/useConfig";
import Breadcrumbs from "components/@extended/Breadcrumbs";
import { openDrawer } from "store/reducers/menu";
import useAuth from "hooks/useAuth";
import { studioTheme } from "ui-components";
import { ThemeProvider } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import WelcomeCard from "components/dashboards/WelcomeCard";
import { useLocation } from "react-router-dom";

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const { login, logout, getCurrentAuthenticatedUser, user } = useAuth();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function getAuthUser() {
      await getCurrentAuthenticatedUser();
    }
    setLoading(false);
    getAuthUser();
  }, []);
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down("xl"));

  const { container, miniDrawer } = useConfig();
  const dispatch = useDispatch();

  const menu = useSelector((state) => state.menu);
  const { drawerOpen } = menu;

  // drawer toggler
  const [open, setOpen] = useState(!miniDrawer || drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  // set media wise responsive drawer
  useEffect(() => {
    if (!miniDrawer) {
      setOpen(!matchDownLG);
      dispatch(openDrawer({ drawerOpen: !matchDownLG }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);

  // useEffect(() => {
  //   console.log(location);
  // }, [location]);

  if (!loading) {
    return (
      <ThemeProvider theme={studioTheme}>
        <Box sx={{ display: "flex", width: "100%" }}>
          <Header open={open} handleDrawerToggle={handleDrawerToggle} />
          <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
          <Box
            component="main"
            sx={{
              width: "calc(100% - 260px)",
              flexGrow: 1,
              p: { xs: 2, sm: 3 },
            }}
          >
            <Toolbar />
            {container && (
              <Container
                maxWidth="xl"
                sx={{
                  px: { xs: 0, sm: 2 },
                  position: "relative",
                  minHeight: "calc(100vh - 110px)",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {!user?.clientView && location?.pathname === "/" ? (
                  <WelcomeCard />
                ) : null}
                <Outlet />
                <Footer />
              </Container>
            )}
            {!container && (
              <Box
                sx={{
                  position: "relative",
                  minHeight: "calc(100vh - 110px)",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Outlet />
                <Footer />
              </Box>
            )}
          </Box>
        </Box>
      </ThemeProvider>
    );
  } else {
    return <CircularProgress />;
  }
};

export default MainLayout;
