import useAuth from "hooks/useAuth";
// import Login from "pages/authentication/Login";
import React, { Fragment, useState, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom"; // component props interface
import { withAuthenticator } from "@aws-amplify/ui-react"; //remove before launch
import { Box, Container, Grid } from "@mui/material";
import { Authenticator, Flex, View, Image } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

const components = {
  Header() {
    return (
      <Box textAlign="center" marginTop={2}>
        <img
          alt="logo"
          src="/logo-full.svg"
          style={{ width: "50%", paddingTop: "5%", paddingBottom: "5%" }}
        />
      </Box>
    );
  },
};

const AuthGuard = ({ children }) => {
  const { isAuthenticated, currentSession } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  useEffect(() => {}, [
    isAuthenticated,
    requestedLocation,
    pathname,
    currentSession,
  ]);

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }

    //return <Login />;

    // return <Fragment>{children}</Fragment>;
    return (
      <Box>
        <Grid container spacing={3}>
          <Grid
            item
            lg={5}
            md={12}
            sm={12}
            xs={12}
            sx={{ background: "#EBF0F4", zIndex: 1100 }}
          >
            <View height="100vh">
              <Box
                marginTop={{ lg: "-10vh", md: "0vh" }}
                sx={{
                  display: "grid",
                  placeItems: "center",
                  height: "100%",
                }}
              >
                <Authenticator
                  hideSignUp={true}
                  components={components}
                  style={{ width: "50%", height: "50%" }}
                >
                  {({ user }) => children}
                </Authenticator>
              </Box>
            </View>
          </Grid>
          <Grid item lg={6} md={0} sm={0} xs={0} sx={{ zIndex: 1050 }}>
            <Box
              display={{ lg: "inline", md: "none", xs: "none" }}
              sx={{ width: "100%" }}
            >
              <View height="100vh">
                <img
                  style={{
                    objectFit: "cover",
                    overflowX: "visible",
                    backgroundColor: "white",
                    color: "primary",
                  }}
                  alt="Office Splash Art"
                  src="/office-frames-with-logos.2dc3cd62.svg"
                  width="100%"
                  height="99%"
                />
              </View>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }

  if (requestedLocation && pathname !== requestedLocation) {
    // console.log(requestedLocation);
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <Fragment>{children}</Fragment>;
};

export default AuthGuard;
