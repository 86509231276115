import { useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useFormik } from "formik";
import * as yup from "yup";
import { v4 as uuidv4 } from "uuid";
import { API } from "aws-amplify";
import useAuth from "hooks/useAuth";
import { useParams } from "react-router";
import { adminUserTypes, userTypes } from "utils/constants";

// customize for your use-case
import { createUsers, updateUsers, deleteUsers } from "graphql/mutations";
const FormFor = "User"; //for mutations, singular
const ReadableFormFor = "User"; //for UI, singular
const GQLCreate = createUsers;
const GQLUpdate = updateUsers;
const GQLDelete = deleteUsers;

const FCUsers = ({
  open,
  onClose,
  handleSnacks,
  data,
  dataUsers,
  isDelete,
  inital,
}) => {
  const params = useParams();
  const queryClient = useQueryClient();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const validationSchema = yup.object({
    // customize for your use-case
    name: yup.string("Enter a name").required("Name is required"),
    username: yup.string("Enter a username").required("Username is required"),
    email: yup.string("Enter a email").required("Email is required"),
    phone: yup.string("Enter a phone"),
    type: yup.string("Enter a type").required("Type is required"),
  });
  const initialValues = {
    name: data?.name || "",
    username: data?.username || "",
    email: data?.email || "",
    phone: data?.phone || "",
    type: data?.type || "",
  };

  // creates
  const amplifyCreate = async ({ username, name, email, phone, type }) => {
    let id = uuidv4();
    let TenantID = user?.TenantID;
    var ampObj = {
      // customize for your use-case
      id,
      username,
      name,
      email,
      phone,
      type,
      TenantID,
    };
    const apiData = await API.graphql({
      query: GQLCreate,
      variables: {
        input: ampObj,
      },
    });
    return apiData?.data[`create${FormFor}s`];
  };
  const rqCreate = useMutation({
    mutationFn: (d) => amplifyCreate(d),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [`${FormFor}s`] });
      setError();
      formik.values.username = "";
      formik.values.name = "";
      formik.values.email = "";
      formik.values.phone = "";
      formik.values.type = "";
    },
    onError: (err) => {
      console.log(err);
      setError(err);
    },
    onSettled: ({ data, error }) => {
      setLoading(false);
      onClose();
      handleSnacks(ReadableFormFor, "Add", error ? error : null);
    },
  });
  const handleCreate = async () => {
    // customize for your use-case
    // function validateInput(input) {
    //   var regex = /^[a-z0-9]+$/;
    //   return regex.test(input);
    // }
    let input = formik.values.username.slice();
    input = input.replace(/[^A-Za-z0-9]+/g, "")?.toLowerCase();
    if (dataUsers?.find((u) => u.username === input)) {
      setError("Username Taken!");
      handleSnacks(ReadableFormFor, "Add", "Username Taken!");
      return;
    } else {
      var cognitoUserObject = await API.post("userManagementRest", "/users", {
        body: {
          username: input,
          name: formik.values.name.trim(),
          email: formik.values.email.trim(),
          phone: formik.values.phone.length ? formik.values.phone : null,
          type: formik.values.type,
          TenantID: user?.TenantID,
        },
      });
    }
    console.log(cognitoUserObject);
    if (cognitoUserObject?.status !== "success") {
      setError("Cognito Error!");
      handleSnacks(ReadableFormFor, "Add", "Cognito Error!");
    } else {
      setLoading(false);
      setError();
      queryClient.invalidateQueries({ queryKey: [`${FormFor}s`] });
      onClose();
      handleSnacks(ReadableFormFor, "Add", null);
    }
  };

  // updates
  const amplifyUpdate = async ({
    id,
    username,
    name,
    email,
    phone,
    type,
    _version,
  }) => {
    var ampObj = {
      // customize for your use-case
      id,
      username,
      name,
      email,
      phone,
      type,
      _version,
    };
    const apiData = await API.graphql({
      query: GQLUpdate,
      variables: {
        input: ampObj,
      },
    });
    return apiData?.data[`update${FormFor}s`];
  };
  const rqUpdate = useMutation({
    mutationFn: (d) => amplifyUpdate(d),
    onSuccess: (data) => {
      queryClient.setQueryData([FormFor, data.id], data);
      queryClient.invalidateQueries({ queryKey: [`${FormFor}s`] });
      setError();
    },
    onError: (err) => {
      console.log(err);
      setError(err);
    },
    onSettled: ({ data, error }) => {
      setLoading(false);
      onClose();
      handleSnacks(ReadableFormFor, "Update", error ? error : null);
    },
  });
  const handleUpdate = async () => {
    var cognitoUserObject = await API.post(
      "userManagementRest",
      "/users/update",
      {
        body: {
          name: formik.values.name.trim(),
          email: formik.values.email.trim(),
          username: formik.values.username,
        },
      }
    );
    if (cognitoUserObject?.status !== "success") {
      setError("Cognito Error!");
      handleSnacks(ReadableFormFor, "Update", "Cognito Error!");
    } else {
      rqUpdate.mutate({
        // customize for your use-case
        id: data?.id,
        username: formik.values.username,
        name: formik.values.name.trim(),
        email: formik.values.email.trim(),
        phone: formik.values.phone.length ? formik.values.phone : null,
        type: formik.values.type,
        _version: data?._version,
      });
    }
  };

  // deletes
  const amplifyDelete = async ({ id, _version }) => {
    var ampObj = {
      id,
      _version,
    };
    const apiData = await API.graphql({
      query: GQLDelete,
      variables: {
        input: ampObj,
      },
    });
    return apiData?.data[`delete${FormFor}s`];
  };
  const rqDelete = useMutation({
    mutationFn: (d) => amplifyDelete(d),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [`${FormFor}s`] });
      setError();
    },
    onError: (err) => {
      console.log(err);
      setError(err);
    },
    onSettled: ({ data, error }) => {
      setLoading(false);
      onClose();
      handleSnacks(ReadableFormFor, "Del", error ? error : null);
    },
  });
  const handleDelete = async () => {
    var apiObject = await API.post("userManagementRest", "/users/status", {
      body: { username: data.username },
    });
    if (apiObject?.status === "success") {
      rqDelete.mutate({
        id: data?.id,
        _version: data?._version,
      });
    } else {
      setError("Cognito Error!");
      handleSnacks(ReadableFormFor, "Del", "Cognito Error!");
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      if (isDelete) {
        handleDelete();
      } else if (data) {
        handleUpdate();
      } else {
        handleCreate();
      }
    },
  });

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>
          {isDelete
            ? `Delete ${ReadableFormFor}`
            : data
            ? `Update ${ReadableFormFor}`
            : `Create a New ${ReadableFormFor}`}
        </DialogTitle>
        <Divider />
        <DialogContent>
          {isDelete ? (
            <DialogContentText>
              Are you sure you want to delete this {ReadableFormFor}?
            </DialogContentText>
          ) : null}
          {!isDelete && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  disabled={data}
                  id="username"
                  name="username"
                  label={`Username`}
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.username && Boolean(formik.errors.username)
                  }
                  helperText={formik.touched.username && formik.errors.username}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="name"
                  name="name"
                  label={`Name`}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  label={`Email`}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="phone"
                  name="phone"
                  label={`Phone`}
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Type</InputLabel>
                  <Select
                    fullWidth
                    name="type"
                    labelId="demo-simple-select-label"
                    // sx={{ width: "175px" }}
                    value={formik.values.type}
                    label={"Type"}
                    // defaultValue=""
                    onChange={formik.handleChange}
                    error={formik.touched.type && Boolean(formik.errors.type)}
                    helperText={formik.touched.type && formik.errors.type}
                  >
                    <MenuItem disabled key="placeholder" value="">
                      Type
                    </MenuItem>

                    {user?.type === "ADMIN"
                      ? adminUserTypes.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))
                      : userTypes.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions sx={{ m: 1 }}>
          <Button
            variant="outlined"
            onClick={() => {
              onClose();
              formik.values.username = "";
              formik.values.name = "";
              formik.values.email = "";
              formik.values.phone = "";
              formik.values.type = "";
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={loading && !error}
            color={isDelete ? "error" : "primary"}
            variant="contained"
            type="submit"
          >
            {isDelete ? "Delete" : data ? "Update" : "Create"}
            {loading && !error && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                }}
              />
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default FCUsers;
