/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBusinessUnitMetrics = /* GraphQL */ `
  query GetBusinessUnitMetrics(
    $clientId: String!
    $businessUnitId: String!
    $from: String!
    $until: String!
  ) {
    getBusinessUnitMetrics(
      clientId: $clientId
      businessUnitId: $businessUnitId
      from: $from
      until: $until
    ) {
      spend
      clicks
      cpc
      impressions
      cpl
      leads {
        id
        name
        description
        type
        details
        value
        status
        state
        twillioSID
        email
        phoneNumber
        crmID
        source
        createdAt
        PhoneNumberID
        Employees {
          nextToken
          startedAt
          __typename
        }
        WebSessionID
        WebSession {
          id
          ipAddress
          status
          startTime
          endTime
          domain
          gacid
          gclid
          fbclid
          msclkid
          referrer
          utmSource
          utmMedium
          utmCampaign
          utmAdgroup
          utmTerm
          utmContent
          utmId
          utmDevice
          utmAdgroupName
          utmPromotion
          queryParameters
          lastPingedOn
          createdAt
          PhoneNumberID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BusinessUnitID
        ChannelID
        Channel {
          id
          name
          budget
          ClientID
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        CampaignID
        ClientID
        ContactID
        Contact {
          id
          name
          emails
          phones
          crmID
          source
          createdAt
          ClientID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Locations {
          id
          name
          street1
          street2
          city
          state
          zip
          crmID
          source
          createdAt
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Jobs {
          id
          name
          description
          status
          value
          completedOn
          crmID
          source
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          LocationID
          LeadID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        callDuration
        recordingDetails {
          recordingURL
          recordingDuration
          recordingSID
          callRating
          __typename
        }
        entrySource
        whatconvertsWebSession
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdJobs {
        id
        name
        description
        status
        value
        completedOn
        crmID
        source
        createdAt
        Employees {
          nextToken
          startedAt
          __typename
        }
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        LocationID
        LeadID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      completedJobs {
        id
        name
        description
        status
        value
        completedOn
        crmID
        source
        createdAt
        Employees {
          nextToken
          startedAt
          __typename
        }
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        LocationID
        LeadID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      canceledJobs {
        id
        name
        description
        status
        value
        completedOn
        crmID
        source
        createdAt
        Employees {
          nextToken
          startedAt
          __typename
        }
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        LocationID
        LeadID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      expectedRevenue
      revenue
      revenuePerCompletedJob
      revenuePerClick
      totalBookedJobs
      totalCompletedJobs
      totalPaidLeads
      costPerBookedJob
      costPerJobCompleted
      __typename
    }
  }
`;
export const getGoogleAdsMetrics = /* GraphQL */ `
  query GetGoogleAdsMetrics(
    $clientId: String!
    $from: String!
    $until: String!
  ) {
    getGoogleAdsMetrics(clientId: $clientId, from: $from, until: $until) {
      spend
      clicks
      impressions
      revenue
      cpl
      totalPaidLeads
      totalBookedJobs
      totalCompletedJobs
      campaignMetrics {
        id
        name
        buId
        spend
        impressions
        clicks
        cpc
        conversions
        costPerConversion
        conversionRate
        expectedRevenue
        revenue
        ctr
        searchImpressionShare
        totalPaidLeads
        totalBookedJobs
        totalCompletedJobs
        __typename
      }
      topCampaigns {
        labels
        data
        __typename
      }
      buMetrics {
        id
        name
        buId
        spend
        impressions
        clicks
        cpc
        conversions
        costPerConversion
        conversionRate
        expectedRevenue
        revenue
        ctr
        searchImpressionShare
        totalPaidLeads
        totalBookedJobs
        totalCompletedJobs
        __typename
      }
      topBUs {
        labels
        data
        __typename
      }
      channelMetrics {
        id
        name
        totalLeads
        totalBookedJobs
        totalCompletedJobs
        expectedRevenue
        revenue
        spend
        __typename
      }
      leads {
        items {
          id
          name
          description
          type
          details
          value
          status
          state
          twillioSID
          email
          phoneNumber
          crmID
          source
          createdAt
          PhoneNumberID
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          callDuration
          entrySource
          whatconvertsWebSession
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const getFacebookAdsMetrics = /* GraphQL */ `
  query GetFacebookAdsMetrics(
    $clientId: String!
    $from: String!
    $until: String!
  ) {
    getFacebookAdsMetrics(clientId: $clientId, from: $from, until: $until) {
      spend
      clicks
      impressions
      revenue
      cpl
      totalPaidLeads
      totalBookedJobs
      totalCompletedJobs
      campaignMetrics {
        id
        name
        buId
        spend
        impressions
        clicks
        cpc
        conversions
        costPerConversion
        conversionRate
        expectedRevenue
        revenue
        ctr
        searchImpressionShare
        totalPaidLeads
        totalBookedJobs
        totalCompletedJobs
        __typename
      }
      topCampaigns {
        labels
        data
        __typename
      }
      buMetrics {
        id
        name
        buId
        spend
        impressions
        clicks
        cpc
        conversions
        costPerConversion
        conversionRate
        expectedRevenue
        revenue
        ctr
        searchImpressionShare
        totalPaidLeads
        totalBookedJobs
        totalCompletedJobs
        __typename
      }
      topBUs {
        labels
        data
        __typename
      }
      channelMetrics {
        id
        name
        totalLeads
        totalBookedJobs
        totalCompletedJobs
        expectedRevenue
        revenue
        spend
        __typename
      }
      leads {
        items {
          id
          name
          description
          type
          details
          value
          status
          state
          twillioSID
          email
          phoneNumber
          crmID
          source
          createdAt
          PhoneNumberID
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          callDuration
          entrySource
          whatconvertsWebSession
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const getMarketOverviewMetrics = /* GraphQL */ `
  query GetMarketOverviewMetrics(
    $clientId: String!
    $from: String!
    $until: String!
    $channelIds: [String!]!
    $leadsLimit: Int
    $leadsNextToken: String
  ) {
    getMarketOverviewMetrics(
      clientId: $clientId
      from: $from
      until: $until
      channelIds: $channelIds
      leadsLimit: $leadsLimit
      leadsNextToken: $leadsNextToken
    ) {
      spend
      clicks
      impressions
      revenue
      cpl
      totalPaidLeads
      totalBookedJobs
      totalCompletedJobs
      campaignMetrics {
        id
        name
        buId
        spend
        impressions
        clicks
        cpc
        conversions
        costPerConversion
        conversionRate
        expectedRevenue
        revenue
        ctr
        searchImpressionShare
        totalPaidLeads
        totalBookedJobs
        totalCompletedJobs
        __typename
      }
      topCampaigns {
        labels
        data
        __typename
      }
      buMetrics {
        id
        name
        buId
        spend
        impressions
        clicks
        cpc
        conversions
        costPerConversion
        conversionRate
        expectedRevenue
        revenue
        ctr
        searchImpressionShare
        totalPaidLeads
        totalBookedJobs
        totalCompletedJobs
        __typename
      }
      topBUs {
        labels
        data
        __typename
      }
      channelMetrics {
        id
        name
        totalLeads
        totalBookedJobs
        totalCompletedJobs
        expectedRevenue
        revenue
        spend
        __typename
      }
      leads {
        items {
          id
          name
          description
          type
          details
          value
          status
          state
          twillioSID
          email
          phoneNumber
          crmID
          source
          createdAt
          PhoneNumberID
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          callDuration
          entrySource
          whatconvertsWebSession
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const getTenants = /* GraphQL */ `
  query GetTenants($id: ID!) {
    getTenants(id: $id) {
      id
      name
      refreshToken
      Clients {
        items {
          id
          name
          googleServiceAdsAccounts
          googleAdsCustomerID
          googleAnalyticsViewID
          ga4MeasurementID
          ga4Secret
          ga4PropertyID
          metaAdsAccount
          metaPixelID
          metaConversionsApiAccessToken
          currency
          bingAdsAccount
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientsCapacitySettingsId
          clientsBidderSettingsId
          clientsCostSyncSettingsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Users {
        items {
          id
          name
          username
          email
          phone
          type
          clientView
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      IntegrationModels {
        items {
          id
          serviceName
          description
          integrationKeys
          options
          type
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listTenants = /* GraphQL */ `
  query ListTenants(
    $filter: ModelTenantsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTenants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        refreshToken
        Clients {
          nextToken
          startedAt
          __typename
        }
        Users {
          nextToken
          startedAt
          __typename
        }
        IntegrationModels {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncTenants = /* GraphQL */ `
  query SyncTenants(
    $filter: ModelTenantsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTenants(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        refreshToken
        Clients {
          nextToken
          startedAt
          __typename
        }
        Users {
          nextToken
          startedAt
          __typename
        }
        IntegrationModels {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAppointments = /* GraphQL */ `
  query GetAppointments($id: ID!) {
    getAppointments(id: $id) {
      id
      crmID
      source
      appointmentNumber
      start
      end
      arrivalWindowStart
      arrivalWindowEnd
      status
      updatedAt
      createdAt
      JobID
      ClientID
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAppointments = /* GraphQL */ `
  query ListAppointments(
    $filter: ModelAppointmentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppointments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        crmID
        source
        appointmentNumber
        start
        end
        arrivalWindowStart
        arrivalWindowEnd
        status
        updatedAt
        createdAt
        JobID
        ClientID
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAppointments = /* GraphQL */ `
  query SyncAppointments(
    $filter: ModelAppointmentsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAppointments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        crmID
        source
        appointmentNumber
        start
        end
        arrivalWindowStart
        arrivalWindowEnd
        status
        updatedAt
        createdAt
        JobID
        ClientID
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getClients = /* GraphQL */ `
  query GetClients($id: ID!) {
    getClients(id: $id) {
      id
      name
      googleServiceAdsAccounts
      googleAdsCustomerID
      googleAnalyticsViewID
      ga4MeasurementID
      ga4Secret
      ga4PropertyID
      metaAdsAccount
      metaPixelID
      metaConversionsApiAccessToken
      currency
      bingAdsAccount
      Users {
        items {
          id
          clientsId
          usersId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      WebSessions {
        items {
          id
          ipAddress
          status
          startTime
          endTime
          domain
          gacid
          gclid
          fbclid
          msclkid
          referrer
          utmSource
          utmMedium
          utmCampaign
          utmAdgroup
          utmTerm
          utmContent
          utmId
          utmDevice
          utmAdgroupName
          utmPromotion
          queryParameters
          lastPingedOn
          createdAt
          PhoneNumberID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Contacts {
        items {
          id
          name
          emails
          phones
          crmID
          source
          createdAt
          ClientID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ContactDetails {
        items {
          id
          type
          value
          memo
          crmID
          source
          createdAt
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Locations {
        items {
          id
          name
          street1
          street2
          city
          state
          zip
          crmID
          source
          createdAt
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Leads {
        items {
          id
          name
          description
          type
          details
          value
          status
          state
          twillioSID
          email
          phoneNumber
          crmID
          source
          createdAt
          PhoneNumberID
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          callDuration
          entrySource
          whatconvertsWebSession
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Jobs {
        items {
          id
          name
          description
          status
          value
          completedOn
          crmID
          source
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          LocationID
          LeadID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Invoices {
        items {
          id
          number
          description
          invoiceDate
          createdAt
          ClientID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      InvoiceItems {
        items {
          id
          name
          description
          quantity
          total
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          InvoiceID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      BusinessUnits {
        items {
          id
          name
          ClientID
          active
          maxCPC
          capacityWindow
          jobDuration
          monthlyBudget
          bidderBlockedBy
          bidderBlocked
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Channels {
        items {
          id
          name
          budget
          ClientID
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Campaigns {
        items {
          id
          name
          ClientID
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Integrations {
        items {
          id
          serviceName
          description
          integrationKeys
          options
          IntegrationModelID
          ClientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Activity {
        items {
          id
          title
          description
          eventTime
          queryParameters
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Employees {
        items {
          id
          name
          jobTitle
          ClientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Equipment {
        items {
          id
          name
          installedOn
          type
          modelNumber
          serialNumber
          crmID
          source
          ClientID
          LocationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Dashboards {
        items {
          id
          title
          description
          category
          embedCode
          ClientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      DNIPools {
        items {
          id
          forwardingNumber
          fallbackNumberID
          ClientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ProgrammableDNIPools {
        items {
          id
          name
          forwardingNumber
          fallbackNumberID
          isDefault
          order
          usageLogic
          ClientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      TenantID
      clientView {
        items {
          id
          name
          username
          email
          phone
          type
          clientView
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ServiceTitanQueues {
        items {
          id
          createdAt
          completedOn
          type
          customers
          customerContacts
          locations
          jobs
          invoices
          equipment
          status
          ClientID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      capacitySettings {
        id
        active
        capacityType
        clientID
        client {
          id
          name
          googleServiceAdsAccounts
          googleAdsCustomerID
          googleAnalyticsViewID
          ga4MeasurementID
          ga4Secret
          ga4PropertyID
          metaAdsAccount
          metaPixelID
          metaConversionsApiAccessToken
          currency
          bingAdsAccount
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientsCapacitySettingsId
          clientsBidderSettingsId
          clientsCostSyncSettingsId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      bidderSettings {
        id
        blacklistedBusinessUnits
        blacklistedGoogleAdsCampaigns
        excludeFromCapacityBusinessUnits
        budgetDistributer
        active
        clientID
        client {
          id
          name
          googleServiceAdsAccounts
          googleAdsCustomerID
          googleAnalyticsViewID
          ga4MeasurementID
          ga4Secret
          ga4PropertyID
          metaAdsAccount
          metaPixelID
          metaConversionsApiAccessToken
          currency
          bingAdsAccount
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientsCapacitySettingsId
          clientsBidderSettingsId
          clientsCostSyncSettingsId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      BudgetLogs {
        items {
          id
          bidType
          bidProperty
          propertyId
          previousValue
          updatedValue
          description
          createdAt
          clientID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      costSyncSettings {
        id
        googleAdsActive
        facebookAdsActive
        bingAdsActive
        lsaActive
        googleAdsCpcChannelID
        googleAdsCpmChannelID
        facebookChannelID
        bingChannelID
        lsaChannelID
        clientID
        client {
          id
          name
          googleServiceAdsAccounts
          googleAdsCustomerID
          googleAnalyticsViewID
          ga4MeasurementID
          ga4Secret
          ga4PropertyID
          metaAdsAccount
          metaPixelID
          metaConversionsApiAccessToken
          currency
          bingAdsAccount
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientsCapacitySettingsId
          clientsBidderSettingsId
          clientsCostSyncSettingsId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      clientsCapacitySettingsId
      clientsBidderSettingsId
      clientsCostSyncSettingsId
      __typename
    }
  }
`;
export const listClients = /* GraphQL */ `
  query ListClients(
    $filter: ModelClientsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        googleServiceAdsAccounts
        googleAdsCustomerID
        googleAnalyticsViewID
        ga4MeasurementID
        ga4Secret
        ga4PropertyID
        metaAdsAccount
        metaPixelID
        metaConversionsApiAccessToken
        currency
        bingAdsAccount
        Users {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        Contacts {
          nextToken
          startedAt
          __typename
        }
        ContactDetails {
          nextToken
          startedAt
          __typename
        }
        Locations {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Invoices {
          nextToken
          startedAt
          __typename
        }
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        BusinessUnits {
          nextToken
          startedAt
          __typename
        }
        Channels {
          nextToken
          startedAt
          __typename
        }
        Campaigns {
          nextToken
          startedAt
          __typename
        }
        Integrations {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        Employees {
          nextToken
          startedAt
          __typename
        }
        Equipment {
          nextToken
          startedAt
          __typename
        }
        Dashboards {
          nextToken
          startedAt
          __typename
        }
        DNIPools {
          nextToken
          startedAt
          __typename
        }
        ProgrammableDNIPools {
          nextToken
          startedAt
          __typename
        }
        TenantID
        clientView {
          nextToken
          startedAt
          __typename
        }
        ServiceTitanQueues {
          nextToken
          startedAt
          __typename
        }
        capacitySettings {
          id
          active
          capacityType
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bidderSettings {
          id
          blacklistedBusinessUnits
          blacklistedGoogleAdsCampaigns
          excludeFromCapacityBusinessUnits
          budgetDistributer
          active
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BudgetLogs {
          nextToken
          startedAt
          __typename
        }
        costSyncSettings {
          id
          googleAdsActive
          facebookAdsActive
          bingAdsActive
          lsaActive
          googleAdsCpcChannelID
          googleAdsCpmChannelID
          facebookChannelID
          bingChannelID
          lsaChannelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        clientsCapacitySettingsId
        clientsBidderSettingsId
        clientsCostSyncSettingsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncClients = /* GraphQL */ `
  query SyncClients(
    $filter: ModelClientsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClients(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        googleServiceAdsAccounts
        googleAdsCustomerID
        googleAnalyticsViewID
        ga4MeasurementID
        ga4Secret
        ga4PropertyID
        metaAdsAccount
        metaPixelID
        metaConversionsApiAccessToken
        currency
        bingAdsAccount
        Users {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        Contacts {
          nextToken
          startedAt
          __typename
        }
        ContactDetails {
          nextToken
          startedAt
          __typename
        }
        Locations {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Invoices {
          nextToken
          startedAt
          __typename
        }
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        BusinessUnits {
          nextToken
          startedAt
          __typename
        }
        Channels {
          nextToken
          startedAt
          __typename
        }
        Campaigns {
          nextToken
          startedAt
          __typename
        }
        Integrations {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        Employees {
          nextToken
          startedAt
          __typename
        }
        Equipment {
          nextToken
          startedAt
          __typename
        }
        Dashboards {
          nextToken
          startedAt
          __typename
        }
        DNIPools {
          nextToken
          startedAt
          __typename
        }
        ProgrammableDNIPools {
          nextToken
          startedAt
          __typename
        }
        TenantID
        clientView {
          nextToken
          startedAt
          __typename
        }
        ServiceTitanQueues {
          nextToken
          startedAt
          __typename
        }
        capacitySettings {
          id
          active
          capacityType
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bidderSettings {
          id
          blacklistedBusinessUnits
          blacklistedGoogleAdsCampaigns
          excludeFromCapacityBusinessUnits
          budgetDistributer
          active
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BudgetLogs {
          nextToken
          startedAt
          __typename
        }
        costSyncSettings {
          id
          googleAdsActive
          facebookAdsActive
          bingAdsActive
          lsaActive
          googleAdsCpcChannelID
          googleAdsCpmChannelID
          facebookChannelID
          bingChannelID
          lsaChannelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        clientsCapacitySettingsId
        clientsBidderSettingsId
        clientsCostSyncSettingsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUsers = /* GraphQL */ `
  query GetUsers($id: ID!) {
    getUsers(id: $id) {
      id
      name
      username
      email
      phone
      type
      clientView
      TenantID
      Clients {
        items {
          id
          clientsId
          usersId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        username
        email
        phone
        type
        clientView
        TenantID
        Clients {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        username
        email
        phone
        type
        clientView
        TenantID
        Clients {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getContacts = /* GraphQL */ `
  query GetContacts($id: ID!) {
    getContacts(id: $id) {
      id
      name
      emails
      phones
      crmID
      source
      createdAt
      WebSessions {
        items {
          id
          ipAddress
          status
          startTime
          endTime
          domain
          gacid
          gclid
          fbclid
          msclkid
          referrer
          utmSource
          utmMedium
          utmCampaign
          utmAdgroup
          utmTerm
          utmContent
          utmId
          utmDevice
          utmAdgroupName
          utmPromotion
          queryParameters
          lastPingedOn
          createdAt
          PhoneNumberID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Locations {
        items {
          id
          name
          street1
          street2
          city
          state
          zip
          crmID
          source
          createdAt
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Leads {
        items {
          id
          name
          description
          type
          details
          value
          status
          state
          twillioSID
          email
          phoneNumber
          crmID
          source
          createdAt
          PhoneNumberID
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          callDuration
          entrySource
          whatconvertsWebSession
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Activity {
        items {
          id
          title
          description
          eventTime
          queryParameters
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ContactDetails {
        items {
          id
          type
          value
          memo
          crmID
          source
          createdAt
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ClientID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listContacts = /* GraphQL */ `
  query ListContacts(
    $filter: ModelContactsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        emails
        phones
        crmID
        source
        createdAt
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        Locations {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        ContactDetails {
          nextToken
          startedAt
          __typename
        }
        ClientID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncContacts = /* GraphQL */ `
  query SyncContacts(
    $filter: ModelContactsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncContacts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        emails
        phones
        crmID
        source
        createdAt
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        Locations {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        ContactDetails {
          nextToken
          startedAt
          __typename
        }
        ClientID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getContactDetails = /* GraphQL */ `
  query GetContactDetails($id: ID!) {
    getContactDetails(id: $id) {
      id
      type
      value
      memo
      crmID
      source
      createdAt
      ClientID
      ContactID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listContactDetails = /* GraphQL */ `
  query ListContactDetails(
    $filter: ModelContactDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactDetails(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        value
        memo
        crmID
        source
        createdAt
        ClientID
        ContactID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncContactDetails = /* GraphQL */ `
  query SyncContactDetails(
    $filter: ModelContactDetailsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncContactDetails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        value
        memo
        crmID
        source
        createdAt
        ClientID
        ContactID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLocations = /* GraphQL */ `
  query GetLocations($id: ID!) {
    getLocations(id: $id) {
      id
      name
      street1
      street2
      city
      state
      zip
      crmID
      source
      createdAt
      Jobs {
        items {
          id
          name
          description
          status
          value
          completedOn
          crmID
          source
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          LocationID
          LeadID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Equipment {
        items {
          id
          name
          installedOn
          type
          modelNumber
          serialNumber
          crmID
          source
          ClientID
          LocationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ClientID
      ContactID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $filter: ModelLocationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        street1
        street2
        city
        state
        zip
        crmID
        source
        createdAt
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Equipment {
          nextToken
          startedAt
          __typename
        }
        ClientID
        ContactID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLocations = /* GraphQL */ `
  query SyncLocations(
    $filter: ModelLocationsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLocations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        street1
        street2
        city
        state
        zip
        crmID
        source
        createdAt
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Equipment {
          nextToken
          startedAt
          __typename
        }
        ClientID
        ContactID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLeads = /* GraphQL */ `
  query GetLeads($id: ID!) {
    getLeads(id: $id) {
      id
      name
      description
      type
      details
      value
      status
      state
      twillioSID
      email
      phoneNumber
      crmID
      source
      createdAt
      PhoneNumberID
      Employees {
        items {
          id
          leadsId
          employeesId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      WebSessionID
      WebSession {
        id
        ipAddress
        status
        startTime
        endTime
        domain
        gacid
        gclid
        fbclid
        msclkid
        referrer
        utmSource
        utmMedium
        utmCampaign
        utmAdgroup
        utmTerm
        utmContent
        utmId
        utmDevice
        utmAdgroupName
        utmPromotion
        queryParameters
        lastPingedOn
        createdAt
        Activity {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        PhoneNumberID
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        ContactID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      BusinessUnitID
      ChannelID
      Channel {
        id
        name
        budget
        Leads {
          nextToken
          startedAt
          __typename
        }
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        ClientID
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      CampaignID
      ClientID
      ContactID
      Contact {
        id
        name
        emails
        phones
        crmID
        source
        createdAt
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        Locations {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        ContactDetails {
          nextToken
          startedAt
          __typename
        }
        ClientID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Locations {
        id
        name
        street1
        street2
        city
        state
        zip
        crmID
        source
        createdAt
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Equipment {
          nextToken
          startedAt
          __typename
        }
        ClientID
        ContactID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Jobs {
        id
        name
        description
        status
        value
        completedOn
        crmID
        source
        createdAt
        Employees {
          nextToken
          startedAt
          __typename
        }
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        LocationID
        LeadID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      callDuration
      recordingDetails {
        recordingURL
        recordingDuration
        recordingSID
        callRating
        __typename
      }
      entrySource
      whatconvertsWebSession
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listLeads = /* GraphQL */ `
  query ListLeads(
    $filter: ModelLeadsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        type
        details
        value
        status
        state
        twillioSID
        email
        phoneNumber
        crmID
        source
        createdAt
        PhoneNumberID
        Employees {
          nextToken
          startedAt
          __typename
        }
        WebSessionID
        WebSession {
          id
          ipAddress
          status
          startTime
          endTime
          domain
          gacid
          gclid
          fbclid
          msclkid
          referrer
          utmSource
          utmMedium
          utmCampaign
          utmAdgroup
          utmTerm
          utmContent
          utmId
          utmDevice
          utmAdgroupName
          utmPromotion
          queryParameters
          lastPingedOn
          createdAt
          PhoneNumberID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BusinessUnitID
        ChannelID
        Channel {
          id
          name
          budget
          ClientID
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        CampaignID
        ClientID
        ContactID
        Contact {
          id
          name
          emails
          phones
          crmID
          source
          createdAt
          ClientID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Locations {
          id
          name
          street1
          street2
          city
          state
          zip
          crmID
          source
          createdAt
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Jobs {
          id
          name
          description
          status
          value
          completedOn
          crmID
          source
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          LocationID
          LeadID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        callDuration
        recordingDetails {
          recordingURL
          recordingDuration
          recordingSID
          callRating
          __typename
        }
        entrySource
        whatconvertsWebSession
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLeads = /* GraphQL */ `
  query SyncLeads(
    $filter: ModelLeadsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeads(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        type
        details
        value
        status
        state
        twillioSID
        email
        phoneNumber
        crmID
        source
        createdAt
        PhoneNumberID
        Employees {
          nextToken
          startedAt
          __typename
        }
        WebSessionID
        WebSession {
          id
          ipAddress
          status
          startTime
          endTime
          domain
          gacid
          gclid
          fbclid
          msclkid
          referrer
          utmSource
          utmMedium
          utmCampaign
          utmAdgroup
          utmTerm
          utmContent
          utmId
          utmDevice
          utmAdgroupName
          utmPromotion
          queryParameters
          lastPingedOn
          createdAt
          PhoneNumberID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BusinessUnitID
        ChannelID
        Channel {
          id
          name
          budget
          ClientID
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        CampaignID
        ClientID
        ContactID
        Contact {
          id
          name
          emails
          phones
          crmID
          source
          createdAt
          ClientID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Locations {
          id
          name
          street1
          street2
          city
          state
          zip
          crmID
          source
          createdAt
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Jobs {
          id
          name
          description
          status
          value
          completedOn
          crmID
          source
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          LocationID
          LeadID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        callDuration
        recordingDetails {
          recordingURL
          recordingDuration
          recordingSID
          callRating
          __typename
        }
        entrySource
        whatconvertsWebSession
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getJobs = /* GraphQL */ `
  query GetJobs($id: ID!) {
    getJobs(id: $id) {
      id
      name
      description
      status
      value
      completedOn
      crmID
      source
      createdAt
      Employees {
        items {
          id
          jobsId
          employeesId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      BusinessUnitID
      ChannelID
      CampaignID
      ClientID
      LocationID
      LeadID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listJobs = /* GraphQL */ `
  query ListJobs(
    $filter: ModelJobsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        status
        value
        completedOn
        crmID
        source
        createdAt
        Employees {
          nextToken
          startedAt
          __typename
        }
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        LocationID
        LeadID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncJobs = /* GraphQL */ `
  query SyncJobs(
    $filter: ModelJobsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncJobs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        status
        value
        completedOn
        crmID
        source
        createdAt
        Employees {
          nextToken
          startedAt
          __typename
        }
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        LocationID
        LeadID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getInvoices = /* GraphQL */ `
  query GetInvoices($id: ID!) {
    getInvoices(id: $id) {
      id
      number
      description
      invoiceDate
      InvoiceItems {
        items {
          id
          name
          description
          quantity
          total
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          InvoiceID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      ClientID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listInvoices = /* GraphQL */ `
  query ListInvoices(
    $filter: ModelInvoicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        number
        description
        invoiceDate
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        createdAt
        ClientID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncInvoices = /* GraphQL */ `
  query SyncInvoices(
    $filter: ModelInvoicesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInvoices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        number
        description
        invoiceDate
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        createdAt
        ClientID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getInvoiceItems = /* GraphQL */ `
  query GetInvoiceItems($id: ID!) {
    getInvoiceItems(id: $id) {
      id
      name
      description
      quantity
      total
      createdAt
      BusinessUnitID
      ChannelID
      CampaignID
      ClientID
      InvoiceID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listInvoiceItems = /* GraphQL */ `
  query ListInvoiceItems(
    $filter: ModelInvoiceItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoiceItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        quantity
        total
        createdAt
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        InvoiceID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncInvoiceItems = /* GraphQL */ `
  query SyncInvoiceItems(
    $filter: ModelInvoiceItemsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInvoiceItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        quantity
        total
        createdAt
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        InvoiceID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getBusinessUnits = /* GraphQL */ `
  query GetBusinessUnits($id: ID!) {
    getBusinessUnits(id: $id) {
      id
      name
      Leads {
        items {
          id
          name
          description
          type
          details
          value
          status
          state
          twillioSID
          email
          phoneNumber
          crmID
          source
          createdAt
          PhoneNumberID
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          callDuration
          entrySource
          whatconvertsWebSession
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Jobs {
        items {
          id
          name
          description
          status
          value
          completedOn
          crmID
          source
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          LocationID
          LeadID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Activity {
        items {
          id
          title
          description
          eventTime
          queryParameters
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      WebSessions {
        items {
          id
          ipAddress
          status
          startTime
          endTime
          domain
          gacid
          gclid
          fbclid
          msclkid
          referrer
          utmSource
          utmMedium
          utmCampaign
          utmAdgroup
          utmTerm
          utmContent
          utmId
          utmDevice
          utmAdgroupName
          utmPromotion
          queryParameters
          lastPingedOn
          createdAt
          PhoneNumberID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      InvoiceItems {
        items {
          id
          name
          description
          quantity
          total
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          InvoiceID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ClientID
      active
      maxCPC
      capacityWindow
      jobDuration
      monthlyBudget
      bidderBlockedBy
      bidderBlocked
      Capacities {
        items {
          id
          date
          openHours
          totalHours
          businessUnitID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      BudgetAdjustments {
        items {
          id
          businessUnitID
          adjustment
          reason
          dateApplied
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listBusinessUnits = /* GraphQL */ `
  query ListBusinessUnits(
    $filter: ModelBusinessUnitsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBusinessUnits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        Leads {
          nextToken
          startedAt
          __typename
        }
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        ClientID
        active
        maxCPC
        capacityWindow
        jobDuration
        monthlyBudget
        bidderBlockedBy
        bidderBlocked
        Capacities {
          nextToken
          startedAt
          __typename
        }
        BudgetAdjustments {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncBusinessUnits = /* GraphQL */ `
  query SyncBusinessUnits(
    $filter: ModelBusinessUnitsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBusinessUnits(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        Leads {
          nextToken
          startedAt
          __typename
        }
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        ClientID
        active
        maxCPC
        capacityWindow
        jobDuration
        monthlyBudget
        bidderBlockedBy
        bidderBlocked
        Capacities {
          nextToken
          startedAt
          __typename
        }
        BudgetAdjustments {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getChannels = /* GraphQL */ `
  query GetChannels($id: ID!) {
    getChannels(id: $id) {
      id
      name
      budget
      Leads {
        items {
          id
          name
          description
          type
          details
          value
          status
          state
          twillioSID
          email
          phoneNumber
          crmID
          source
          createdAt
          PhoneNumberID
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          callDuration
          entrySource
          whatconvertsWebSession
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Jobs {
        items {
          id
          name
          description
          status
          value
          completedOn
          crmID
          source
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          LocationID
          LeadID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Activity {
        items {
          id
          title
          description
          eventTime
          queryParameters
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      WebSessions {
        items {
          id
          ipAddress
          status
          startTime
          endTime
          domain
          gacid
          gclid
          fbclid
          msclkid
          referrer
          utmSource
          utmMedium
          utmCampaign
          utmAdgroup
          utmTerm
          utmContent
          utmId
          utmDevice
          utmAdgroupName
          utmPromotion
          queryParameters
          lastPingedOn
          createdAt
          PhoneNumberID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      InvoiceItems {
        items {
          id
          name
          description
          quantity
          total
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          InvoiceID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ClientID
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listChannels = /* GraphQL */ `
  query ListChannels(
    $filter: ModelChannelsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChannels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        budget
        Leads {
          nextToken
          startedAt
          __typename
        }
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        ClientID
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncChannels = /* GraphQL */ `
  query SyncChannels(
    $filter: ModelChannelsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncChannels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        budget
        Leads {
          nextToken
          startedAt
          __typename
        }
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        ClientID
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCampaigns = /* GraphQL */ `
  query GetCampaigns($id: ID!) {
    getCampaigns(id: $id) {
      id
      name
      Jobs {
        items {
          id
          name
          description
          status
          value
          completedOn
          crmID
          source
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          LocationID
          LeadID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Activity {
        items {
          id
          title
          description
          eventTime
          queryParameters
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      WebSessions {
        items {
          id
          ipAddress
          status
          startTime
          endTime
          domain
          gacid
          gclid
          fbclid
          msclkid
          referrer
          utmSource
          utmMedium
          utmCampaign
          utmAdgroup
          utmTerm
          utmContent
          utmId
          utmDevice
          utmAdgroupName
          utmPromotion
          queryParameters
          lastPingedOn
          createdAt
          PhoneNumberID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      InvoiceItems {
        items {
          id
          name
          description
          quantity
          total
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          InvoiceID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Leads {
        items {
          id
          name
          description
          type
          details
          value
          status
          state
          twillioSID
          email
          phoneNumber
          crmID
          source
          createdAt
          PhoneNumberID
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          callDuration
          entrySource
          whatconvertsWebSession
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ClientID
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listCampaigns = /* GraphQL */ `
  query ListCampaigns(
    $filter: ModelCampaignsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCampaigns(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        ClientID
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCampaigns = /* GraphQL */ `
  query SyncCampaigns(
    $filter: ModelCampaignsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCampaigns(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        ClientID
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getIntegrationModels = /* GraphQL */ `
  query GetIntegrationModels($id: ID!) {
    getIntegrationModels(id: $id) {
      id
      serviceName
      description
      integrationKeys
      options
      type
      Integrations {
        items {
          id
          serviceName
          description
          integrationKeys
          options
          IntegrationModelID
          ClientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      TenantID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listIntegrationModels = /* GraphQL */ `
  query ListIntegrationModels(
    $filter: ModelIntegrationModelsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIntegrationModels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serviceName
        description
        integrationKeys
        options
        type
        Integrations {
          nextToken
          startedAt
          __typename
        }
        TenantID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncIntegrationModels = /* GraphQL */ `
  query SyncIntegrationModels(
    $filter: ModelIntegrationModelsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncIntegrationModels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        serviceName
        description
        integrationKeys
        options
        type
        Integrations {
          nextToken
          startedAt
          __typename
        }
        TenantID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getIntegrations = /* GraphQL */ `
  query GetIntegrations($id: ID!) {
    getIntegrations(id: $id) {
      id
      serviceName
      description
      integrationKeys
      options
      IntegrationModelID
      ClientID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listIntegrations = /* GraphQL */ `
  query ListIntegrations(
    $filter: ModelIntegrationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIntegrations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        serviceName
        description
        integrationKeys
        options
        IntegrationModelID
        ClientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncIntegrations = /* GraphQL */ `
  query SyncIntegrations(
    $filter: ModelIntegrationsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncIntegrations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        serviceName
        description
        integrationKeys
        options
        IntegrationModelID
        ClientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDNIPools = /* GraphQL */ `
  query GetDNIPools($id: ID!) {
    getDNIPools(id: $id) {
      id
      forwardingNumber
      fallbackNumberID
      ClientID
      PhoneNumbers {
        items {
          id
          number
          twilioID
          poolID
          pDniPoolID
          reserved
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDNIPools = /* GraphQL */ `
  query ListDNIPools(
    $filter: ModelDNIPoolsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDNIPools(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        forwardingNumber
        fallbackNumberID
        ClientID
        PhoneNumbers {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDNIPools = /* GraphQL */ `
  query SyncDNIPools(
    $filter: ModelDNIPoolsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDNIPools(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        forwardingNumber
        fallbackNumberID
        ClientID
        PhoneNumbers {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getProgrammableDNIPools = /* GraphQL */ `
  query GetProgrammableDNIPools($id: ID!) {
    getProgrammableDNIPools(id: $id) {
      id
      name
      forwardingNumber
      fallbackNumberID
      isDefault
      order
      usageLogic
      ClientID
      PhoneNumbers {
        items {
          id
          number
          twilioID
          poolID
          pDniPoolID
          reserved
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listProgrammableDNIPools = /* GraphQL */ `
  query ListProgrammableDNIPools(
    $filter: ModelProgrammableDNIPoolsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProgrammableDNIPools(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        forwardingNumber
        fallbackNumberID
        isDefault
        order
        usageLogic
        ClientID
        PhoneNumbers {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncProgrammableDNIPools = /* GraphQL */ `
  query SyncProgrammableDNIPools(
    $filter: ModelProgrammableDNIPoolsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProgrammableDNIPools(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        forwardingNumber
        fallbackNumberID
        isDefault
        order
        usageLogic
        ClientID
        PhoneNumbers {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPhoneNumbers = /* GraphQL */ `
  query GetPhoneNumbers($id: ID!) {
    getPhoneNumbers(id: $id) {
      id
      number
      twilioID
      poolID
      pDniPoolID
      reserved
      Leads {
        items {
          id
          name
          description
          type
          details
          value
          status
          state
          twillioSID
          email
          phoneNumber
          crmID
          source
          createdAt
          PhoneNumberID
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          callDuration
          entrySource
          whatconvertsWebSession
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      WebSessions {
        items {
          id
          ipAddress
          status
          startTime
          endTime
          domain
          gacid
          gclid
          fbclid
          msclkid
          referrer
          utmSource
          utmMedium
          utmCampaign
          utmAdgroup
          utmTerm
          utmContent
          utmId
          utmDevice
          utmAdgroupName
          utmPromotion
          queryParameters
          lastPingedOn
          createdAt
          PhoneNumberID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPhoneNumbers = /* GraphQL */ `
  query ListPhoneNumbers(
    $filter: ModelPhoneNumbersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhoneNumbers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        number
        twilioID
        poolID
        pDniPoolID
        reserved
        Leads {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPhoneNumbers = /* GraphQL */ `
  query SyncPhoneNumbers(
    $filter: ModelPhoneNumbersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPhoneNumbers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        number
        twilioID
        poolID
        pDniPoolID
        reserved
        Leads {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getActivity = /* GraphQL */ `
  query GetActivity($id: ID!) {
    getActivity(id: $id) {
      id
      title
      description
      eventTime
      queryParameters
      WebSessionID
      BusinessUnitID
      ChannelID
      CampaignID
      ClientID
      ContactID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listActivities = /* GraphQL */ `
  query ListActivities(
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        eventTime
        queryParameters
        WebSessionID
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        ContactID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncActivities = /* GraphQL */ `
  query SyncActivities(
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncActivities(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        description
        eventTime
        queryParameters
        WebSessionID
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        ContactID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getWebSessions = /* GraphQL */ `
  query GetWebSessions($id: ID!) {
    getWebSessions(id: $id) {
      id
      ipAddress
      status
      startTime
      endTime
      domain
      gacid
      gclid
      fbclid
      msclkid
      referrer
      utmSource
      utmMedium
      utmCampaign
      utmAdgroup
      utmTerm
      utmContent
      utmId
      utmDevice
      utmAdgroupName
      utmPromotion
      queryParameters
      lastPingedOn
      createdAt
      Activity {
        items {
          id
          title
          description
          eventTime
          queryParameters
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Leads {
        items {
          id
          name
          description
          type
          details
          value
          status
          state
          twillioSID
          email
          phoneNumber
          crmID
          source
          createdAt
          PhoneNumberID
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          callDuration
          entrySource
          whatconvertsWebSession
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      PhoneNumberID
      BusinessUnitID
      ChannelID
      CampaignID
      ClientID
      ContactID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listWebSessions = /* GraphQL */ `
  query ListWebSessions(
    $filter: ModelWebSessionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWebSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ipAddress
        status
        startTime
        endTime
        domain
        gacid
        gclid
        fbclid
        msclkid
        referrer
        utmSource
        utmMedium
        utmCampaign
        utmAdgroup
        utmTerm
        utmContent
        utmId
        utmDevice
        utmAdgroupName
        utmPromotion
        queryParameters
        lastPingedOn
        createdAt
        Activity {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        PhoneNumberID
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        ContactID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncWebSessions = /* GraphQL */ `
  query SyncWebSessions(
    $filter: ModelWebSessionsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWebSessions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        ipAddress
        status
        startTime
        endTime
        domain
        gacid
        gclid
        fbclid
        msclkid
        referrer
        utmSource
        utmMedium
        utmCampaign
        utmAdgroup
        utmTerm
        utmContent
        utmId
        utmDevice
        utmAdgroupName
        utmPromotion
        queryParameters
        lastPingedOn
        createdAt
        Activity {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        PhoneNumberID
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        ContactID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getServiceTitanQueues = /* GraphQL */ `
  query GetServiceTitanQueues($id: ID!) {
    getServiceTitanQueues(id: $id) {
      id
      createdAt
      completedOn
      type
      customers
      customerContacts
      locations
      jobs
      invoices
      equipment
      status
      ClientID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listServiceTitanQueues = /* GraphQL */ `
  query ListServiceTitanQueues(
    $filter: ModelServiceTitanQueuesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServiceTitanQueues(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        completedOn
        type
        customers
        customerContacts
        locations
        jobs
        invoices
        equipment
        status
        ClientID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncServiceTitanQueues = /* GraphQL */ `
  query SyncServiceTitanQueues(
    $filter: ModelServiceTitanQueuesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncServiceTitanQueues(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdAt
        completedOn
        type
        customers
        customerContacts
        locations
        jobs
        invoices
        equipment
        status
        ClientID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getEmployees = /* GraphQL */ `
  query GetEmployees($id: ID!) {
    getEmployees(id: $id) {
      id
      name
      jobTitle
      Leads {
        items {
          id
          leadsId
          employeesId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Job {
        items {
          id
          jobsId
          employeesId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ClientID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listEmployees = /* GraphQL */ `
  query ListEmployees(
    $filter: ModelEmployeesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        jobTitle
        Leads {
          nextToken
          startedAt
          __typename
        }
        Job {
          nextToken
          startedAt
          __typename
        }
        ClientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncEmployees = /* GraphQL */ `
  query SyncEmployees(
    $filter: ModelEmployeesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEmployees(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        jobTitle
        Leads {
          nextToken
          startedAt
          __typename
        }
        Job {
          nextToken
          startedAt
          __typename
        }
        ClientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDashboards = /* GraphQL */ `
  query GetDashboards($id: ID!) {
    getDashboards(id: $id) {
      id
      title
      description
      category
      embedCode
      ClientID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDashboards = /* GraphQL */ `
  query ListDashboards(
    $filter: ModelDashboardsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDashboards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        category
        embedCode
        ClientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDashboards = /* GraphQL */ `
  query SyncDashboards(
    $filter: ModelDashboardsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDashboards(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        description
        category
        embedCode
        ClientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getEquipment = /* GraphQL */ `
  query GetEquipment($id: ID!) {
    getEquipment(id: $id) {
      id
      name
      installedOn
      type
      modelNumber
      serialNumber
      crmID
      source
      ClientID
      LocationID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listEquipment = /* GraphQL */ `
  query ListEquipment(
    $filter: ModelEquipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEquipment(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        installedOn
        type
        modelNumber
        serialNumber
        crmID
        source
        ClientID
        LocationID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncEquipment = /* GraphQL */ `
  query SyncEquipment(
    $filter: ModelEquipmentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEquipment(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        installedOn
        type
        modelNumber
        serialNumber
        crmID
        source
        ClientID
        LocationID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMappings = /* GraphQL */ `
  query GetMappings($id: ID!) {
    getMappings(id: $id) {
      id
      type
      service
      propertyIDs
      BusinessUnitID
      ChannelID
      CampaignID
      ClientID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listMappings = /* GraphQL */ `
  query ListMappings(
    $filter: ModelMappingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMappings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        service
        propertyIDs
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMappings = /* GraphQL */ `
  query SyncMappings(
    $filter: ModelMappingsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMappings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        service
        propertyIDs
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCapacitySettings = /* GraphQL */ `
  query GetCapacitySettings($id: ID!) {
    getCapacitySettings(id: $id) {
      id
      active
      capacityType
      clientID
      client {
        id
        name
        googleServiceAdsAccounts
        googleAdsCustomerID
        googleAnalyticsViewID
        ga4MeasurementID
        ga4Secret
        ga4PropertyID
        metaAdsAccount
        metaPixelID
        metaConversionsApiAccessToken
        currency
        bingAdsAccount
        Users {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        Contacts {
          nextToken
          startedAt
          __typename
        }
        ContactDetails {
          nextToken
          startedAt
          __typename
        }
        Locations {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Invoices {
          nextToken
          startedAt
          __typename
        }
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        BusinessUnits {
          nextToken
          startedAt
          __typename
        }
        Channels {
          nextToken
          startedAt
          __typename
        }
        Campaigns {
          nextToken
          startedAt
          __typename
        }
        Integrations {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        Employees {
          nextToken
          startedAt
          __typename
        }
        Equipment {
          nextToken
          startedAt
          __typename
        }
        Dashboards {
          nextToken
          startedAt
          __typename
        }
        DNIPools {
          nextToken
          startedAt
          __typename
        }
        ProgrammableDNIPools {
          nextToken
          startedAt
          __typename
        }
        TenantID
        clientView {
          nextToken
          startedAt
          __typename
        }
        ServiceTitanQueues {
          nextToken
          startedAt
          __typename
        }
        capacitySettings {
          id
          active
          capacityType
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bidderSettings {
          id
          blacklistedBusinessUnits
          blacklistedGoogleAdsCampaigns
          excludeFromCapacityBusinessUnits
          budgetDistributer
          active
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BudgetLogs {
          nextToken
          startedAt
          __typename
        }
        costSyncSettings {
          id
          googleAdsActive
          facebookAdsActive
          bingAdsActive
          lsaActive
          googleAdsCpcChannelID
          googleAdsCpmChannelID
          facebookChannelID
          bingChannelID
          lsaChannelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        clientsCapacitySettingsId
        clientsBidderSettingsId
        clientsCostSyncSettingsId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listCapacitySettings = /* GraphQL */ `
  query ListCapacitySettings(
    $filter: ModelCapacitySettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCapacitySettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        active
        capacityType
        clientID
        client {
          id
          name
          googleServiceAdsAccounts
          googleAdsCustomerID
          googleAnalyticsViewID
          ga4MeasurementID
          ga4Secret
          ga4PropertyID
          metaAdsAccount
          metaPixelID
          metaConversionsApiAccessToken
          currency
          bingAdsAccount
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientsCapacitySettingsId
          clientsBidderSettingsId
          clientsCostSyncSettingsId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCapacitySettings = /* GraphQL */ `
  query SyncCapacitySettings(
    $filter: ModelCapacitySettingsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCapacitySettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        active
        capacityType
        clientID
        client {
          id
          name
          googleServiceAdsAccounts
          googleAdsCustomerID
          googleAnalyticsViewID
          ga4MeasurementID
          ga4Secret
          ga4PropertyID
          metaAdsAccount
          metaPixelID
          metaConversionsApiAccessToken
          currency
          bingAdsAccount
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientsCapacitySettingsId
          clientsBidderSettingsId
          clientsCostSyncSettingsId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCapacities = /* GraphQL */ `
  query GetCapacities($id: ID!) {
    getCapacities(id: $id) {
      id
      date
      openHours
      totalHours
      businessUnitID
      businessUnit {
        id
        name
        Leads {
          nextToken
          startedAt
          __typename
        }
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        ClientID
        active
        maxCPC
        capacityWindow
        jobDuration
        monthlyBudget
        bidderBlockedBy
        bidderBlocked
        Capacities {
          nextToken
          startedAt
          __typename
        }
        BudgetAdjustments {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listCapacities = /* GraphQL */ `
  query ListCapacities(
    $filter: ModelCapacitiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCapacities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        date
        openHours
        totalHours
        businessUnitID
        businessUnit {
          id
          name
          ClientID
          active
          maxCPC
          capacityWindow
          jobDuration
          monthlyBudget
          bidderBlockedBy
          bidderBlocked
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCapacities = /* GraphQL */ `
  query SyncCapacities(
    $filter: ModelCapacitiesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCapacities(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        date
        openHours
        totalHours
        businessUnitID
        businessUnit {
          id
          name
          ClientID
          active
          maxCPC
          capacityWindow
          jobDuration
          monthlyBudget
          bidderBlockedBy
          bidderBlocked
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getBidderSettings = /* GraphQL */ `
  query GetBidderSettings($id: ID!) {
    getBidderSettings(id: $id) {
      id
      blacklistedBusinessUnits
      blacklistedGoogleAdsCampaigns
      excludeFromCapacityBusinessUnits
      budgetDistributer
      active
      clientID
      client {
        id
        name
        googleServiceAdsAccounts
        googleAdsCustomerID
        googleAnalyticsViewID
        ga4MeasurementID
        ga4Secret
        ga4PropertyID
        metaAdsAccount
        metaPixelID
        metaConversionsApiAccessToken
        currency
        bingAdsAccount
        Users {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        Contacts {
          nextToken
          startedAt
          __typename
        }
        ContactDetails {
          nextToken
          startedAt
          __typename
        }
        Locations {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Invoices {
          nextToken
          startedAt
          __typename
        }
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        BusinessUnits {
          nextToken
          startedAt
          __typename
        }
        Channels {
          nextToken
          startedAt
          __typename
        }
        Campaigns {
          nextToken
          startedAt
          __typename
        }
        Integrations {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        Employees {
          nextToken
          startedAt
          __typename
        }
        Equipment {
          nextToken
          startedAt
          __typename
        }
        Dashboards {
          nextToken
          startedAt
          __typename
        }
        DNIPools {
          nextToken
          startedAt
          __typename
        }
        ProgrammableDNIPools {
          nextToken
          startedAt
          __typename
        }
        TenantID
        clientView {
          nextToken
          startedAt
          __typename
        }
        ServiceTitanQueues {
          nextToken
          startedAt
          __typename
        }
        capacitySettings {
          id
          active
          capacityType
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bidderSettings {
          id
          blacklistedBusinessUnits
          blacklistedGoogleAdsCampaigns
          excludeFromCapacityBusinessUnits
          budgetDistributer
          active
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BudgetLogs {
          nextToken
          startedAt
          __typename
        }
        costSyncSettings {
          id
          googleAdsActive
          facebookAdsActive
          bingAdsActive
          lsaActive
          googleAdsCpcChannelID
          googleAdsCpmChannelID
          facebookChannelID
          bingChannelID
          lsaChannelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        clientsCapacitySettingsId
        clientsBidderSettingsId
        clientsCostSyncSettingsId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listBidderSettings = /* GraphQL */ `
  query ListBidderSettings(
    $filter: ModelBidderSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBidderSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        blacklistedBusinessUnits
        blacklistedGoogleAdsCampaigns
        excludeFromCapacityBusinessUnits
        budgetDistributer
        active
        clientID
        client {
          id
          name
          googleServiceAdsAccounts
          googleAdsCustomerID
          googleAnalyticsViewID
          ga4MeasurementID
          ga4Secret
          ga4PropertyID
          metaAdsAccount
          metaPixelID
          metaConversionsApiAccessToken
          currency
          bingAdsAccount
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientsCapacitySettingsId
          clientsBidderSettingsId
          clientsCostSyncSettingsId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncBidderSettings = /* GraphQL */ `
  query SyncBidderSettings(
    $filter: ModelBidderSettingsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBidderSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        blacklistedBusinessUnits
        blacklistedGoogleAdsCampaigns
        excludeFromCapacityBusinessUnits
        budgetDistributer
        active
        clientID
        client {
          id
          name
          googleServiceAdsAccounts
          googleAdsCustomerID
          googleAnalyticsViewID
          ga4MeasurementID
          ga4Secret
          ga4PropertyID
          metaAdsAccount
          metaPixelID
          metaConversionsApiAccessToken
          currency
          bingAdsAccount
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientsCapacitySettingsId
          clientsBidderSettingsId
          clientsCostSyncSettingsId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getBudgetAdjustments = /* GraphQL */ `
  query GetBudgetAdjustments($id: ID!) {
    getBudgetAdjustments(id: $id) {
      id
      businessUnitID
      businessUnit {
        id
        name
        Leads {
          nextToken
          startedAt
          __typename
        }
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        ClientID
        active
        maxCPC
        capacityWindow
        jobDuration
        monthlyBudget
        bidderBlockedBy
        bidderBlocked
        Capacities {
          nextToken
          startedAt
          __typename
        }
        BudgetAdjustments {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      adjustment
      reason
      dateApplied
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listBudgetAdjustments = /* GraphQL */ `
  query ListBudgetAdjustments(
    $filter: ModelBudgetAdjustmentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBudgetAdjustments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        businessUnitID
        businessUnit {
          id
          name
          ClientID
          active
          maxCPC
          capacityWindow
          jobDuration
          monthlyBudget
          bidderBlockedBy
          bidderBlocked
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        adjustment
        reason
        dateApplied
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncBudgetAdjustments = /* GraphQL */ `
  query SyncBudgetAdjustments(
    $filter: ModelBudgetAdjustmentsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBudgetAdjustments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        businessUnitID
        businessUnit {
          id
          name
          ClientID
          active
          maxCPC
          capacityWindow
          jobDuration
          monthlyBudget
          bidderBlockedBy
          bidderBlocked
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        adjustment
        reason
        dateApplied
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getBudgetLogs = /* GraphQL */ `
  query GetBudgetLogs($id: ID!) {
    getBudgetLogs(id: $id) {
      id
      bidType
      bidProperty
      propertyId
      previousValue
      updatedValue
      description
      createdAt
      clientID
      client {
        id
        name
        googleServiceAdsAccounts
        googleAdsCustomerID
        googleAnalyticsViewID
        ga4MeasurementID
        ga4Secret
        ga4PropertyID
        metaAdsAccount
        metaPixelID
        metaConversionsApiAccessToken
        currency
        bingAdsAccount
        Users {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        Contacts {
          nextToken
          startedAt
          __typename
        }
        ContactDetails {
          nextToken
          startedAt
          __typename
        }
        Locations {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Invoices {
          nextToken
          startedAt
          __typename
        }
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        BusinessUnits {
          nextToken
          startedAt
          __typename
        }
        Channels {
          nextToken
          startedAt
          __typename
        }
        Campaigns {
          nextToken
          startedAt
          __typename
        }
        Integrations {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        Employees {
          nextToken
          startedAt
          __typename
        }
        Equipment {
          nextToken
          startedAt
          __typename
        }
        Dashboards {
          nextToken
          startedAt
          __typename
        }
        DNIPools {
          nextToken
          startedAt
          __typename
        }
        ProgrammableDNIPools {
          nextToken
          startedAt
          __typename
        }
        TenantID
        clientView {
          nextToken
          startedAt
          __typename
        }
        ServiceTitanQueues {
          nextToken
          startedAt
          __typename
        }
        capacitySettings {
          id
          active
          capacityType
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bidderSettings {
          id
          blacklistedBusinessUnits
          blacklistedGoogleAdsCampaigns
          excludeFromCapacityBusinessUnits
          budgetDistributer
          active
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BudgetLogs {
          nextToken
          startedAt
          __typename
        }
        costSyncSettings {
          id
          googleAdsActive
          facebookAdsActive
          bingAdsActive
          lsaActive
          googleAdsCpcChannelID
          googleAdsCpmChannelID
          facebookChannelID
          bingChannelID
          lsaChannelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        clientsCapacitySettingsId
        clientsBidderSettingsId
        clientsCostSyncSettingsId
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listBudgetLogs = /* GraphQL */ `
  query ListBudgetLogs(
    $filter: ModelBudgetLogsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBudgetLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        bidType
        bidProperty
        propertyId
        previousValue
        updatedValue
        description
        createdAt
        clientID
        client {
          id
          name
          googleServiceAdsAccounts
          googleAdsCustomerID
          googleAnalyticsViewID
          ga4MeasurementID
          ga4Secret
          ga4PropertyID
          metaAdsAccount
          metaPixelID
          metaConversionsApiAccessToken
          currency
          bingAdsAccount
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientsCapacitySettingsId
          clientsBidderSettingsId
          clientsCostSyncSettingsId
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncBudgetLogs = /* GraphQL */ `
  query SyncBudgetLogs(
    $filter: ModelBudgetLogsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBudgetLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        bidType
        bidProperty
        propertyId
        previousValue
        updatedValue
        description
        createdAt
        clientID
        client {
          id
          name
          googleServiceAdsAccounts
          googleAdsCustomerID
          googleAnalyticsViewID
          ga4MeasurementID
          ga4Secret
          ga4PropertyID
          metaAdsAccount
          metaPixelID
          metaConversionsApiAccessToken
          currency
          bingAdsAccount
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientsCapacitySettingsId
          clientsBidderSettingsId
          clientsCostSyncSettingsId
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCostSyncSettings = /* GraphQL */ `
  query GetCostSyncSettings($id: ID!) {
    getCostSyncSettings(id: $id) {
      id
      googleAdsActive
      facebookAdsActive
      bingAdsActive
      lsaActive
      googleAdsCpcChannelID
      googleAdsCpmChannelID
      facebookChannelID
      bingChannelID
      lsaChannelID
      clientID
      client {
        id
        name
        googleServiceAdsAccounts
        googleAdsCustomerID
        googleAnalyticsViewID
        ga4MeasurementID
        ga4Secret
        ga4PropertyID
        metaAdsAccount
        metaPixelID
        metaConversionsApiAccessToken
        currency
        bingAdsAccount
        Users {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        Contacts {
          nextToken
          startedAt
          __typename
        }
        ContactDetails {
          nextToken
          startedAt
          __typename
        }
        Locations {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Invoices {
          nextToken
          startedAt
          __typename
        }
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        BusinessUnits {
          nextToken
          startedAt
          __typename
        }
        Channels {
          nextToken
          startedAt
          __typename
        }
        Campaigns {
          nextToken
          startedAt
          __typename
        }
        Integrations {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        Employees {
          nextToken
          startedAt
          __typename
        }
        Equipment {
          nextToken
          startedAt
          __typename
        }
        Dashboards {
          nextToken
          startedAt
          __typename
        }
        DNIPools {
          nextToken
          startedAt
          __typename
        }
        ProgrammableDNIPools {
          nextToken
          startedAt
          __typename
        }
        TenantID
        clientView {
          nextToken
          startedAt
          __typename
        }
        ServiceTitanQueues {
          nextToken
          startedAt
          __typename
        }
        capacitySettings {
          id
          active
          capacityType
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bidderSettings {
          id
          blacklistedBusinessUnits
          blacklistedGoogleAdsCampaigns
          excludeFromCapacityBusinessUnits
          budgetDistributer
          active
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BudgetLogs {
          nextToken
          startedAt
          __typename
        }
        costSyncSettings {
          id
          googleAdsActive
          facebookAdsActive
          bingAdsActive
          lsaActive
          googleAdsCpcChannelID
          googleAdsCpmChannelID
          facebookChannelID
          bingChannelID
          lsaChannelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        clientsCapacitySettingsId
        clientsBidderSettingsId
        clientsCostSyncSettingsId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listCostSyncSettings = /* GraphQL */ `
  query ListCostSyncSettings(
    $filter: ModelCostSyncSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCostSyncSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        googleAdsActive
        facebookAdsActive
        bingAdsActive
        lsaActive
        googleAdsCpcChannelID
        googleAdsCpmChannelID
        facebookChannelID
        bingChannelID
        lsaChannelID
        clientID
        client {
          id
          name
          googleServiceAdsAccounts
          googleAdsCustomerID
          googleAnalyticsViewID
          ga4MeasurementID
          ga4Secret
          ga4PropertyID
          metaAdsAccount
          metaPixelID
          metaConversionsApiAccessToken
          currency
          bingAdsAccount
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientsCapacitySettingsId
          clientsBidderSettingsId
          clientsCostSyncSettingsId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCostSyncSettings = /* GraphQL */ `
  query SyncCostSyncSettings(
    $filter: ModelCostSyncSettingsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCostSyncSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        googleAdsActive
        facebookAdsActive
        bingAdsActive
        lsaActive
        googleAdsCpcChannelID
        googleAdsCpmChannelID
        facebookChannelID
        bingChannelID
        lsaChannelID
        clientID
        client {
          id
          name
          googleServiceAdsAccounts
          googleAdsCustomerID
          googleAnalyticsViewID
          ga4MeasurementID
          ga4Secret
          ga4PropertyID
          metaAdsAccount
          metaPixelID
          metaConversionsApiAccessToken
          currency
          bingAdsAccount
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientsCapacitySettingsId
          clientsBidderSettingsId
          clientsCostSyncSettingsId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getClientUsers = /* GraphQL */ `
  query GetClientUsers($id: ID!) {
    getClientUsers(id: $id) {
      id
      clientsId
      usersId
      clients {
        id
        name
        googleServiceAdsAccounts
        googleAdsCustomerID
        googleAnalyticsViewID
        ga4MeasurementID
        ga4Secret
        ga4PropertyID
        metaAdsAccount
        metaPixelID
        metaConversionsApiAccessToken
        currency
        bingAdsAccount
        Users {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        Contacts {
          nextToken
          startedAt
          __typename
        }
        ContactDetails {
          nextToken
          startedAt
          __typename
        }
        Locations {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Invoices {
          nextToken
          startedAt
          __typename
        }
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        BusinessUnits {
          nextToken
          startedAt
          __typename
        }
        Channels {
          nextToken
          startedAt
          __typename
        }
        Campaigns {
          nextToken
          startedAt
          __typename
        }
        Integrations {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        Employees {
          nextToken
          startedAt
          __typename
        }
        Equipment {
          nextToken
          startedAt
          __typename
        }
        Dashboards {
          nextToken
          startedAt
          __typename
        }
        DNIPools {
          nextToken
          startedAt
          __typename
        }
        ProgrammableDNIPools {
          nextToken
          startedAt
          __typename
        }
        TenantID
        clientView {
          nextToken
          startedAt
          __typename
        }
        ServiceTitanQueues {
          nextToken
          startedAt
          __typename
        }
        capacitySettings {
          id
          active
          capacityType
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bidderSettings {
          id
          blacklistedBusinessUnits
          blacklistedGoogleAdsCampaigns
          excludeFromCapacityBusinessUnits
          budgetDistributer
          active
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BudgetLogs {
          nextToken
          startedAt
          __typename
        }
        costSyncSettings {
          id
          googleAdsActive
          facebookAdsActive
          bingAdsActive
          lsaActive
          googleAdsCpcChannelID
          googleAdsCpmChannelID
          facebookChannelID
          bingChannelID
          lsaChannelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        clientsCapacitySettingsId
        clientsBidderSettingsId
        clientsCostSyncSettingsId
        __typename
      }
      users {
        id
        name
        username
        email
        phone
        type
        clientView
        TenantID
        Clients {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listClientUsers = /* GraphQL */ `
  query ListClientUsers(
    $filter: ModelClientUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClientUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientsId
        usersId
        clients {
          id
          name
          googleServiceAdsAccounts
          googleAdsCustomerID
          googleAnalyticsViewID
          ga4MeasurementID
          ga4Secret
          ga4PropertyID
          metaAdsAccount
          metaPixelID
          metaConversionsApiAccessToken
          currency
          bingAdsAccount
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientsCapacitySettingsId
          clientsBidderSettingsId
          clientsCostSyncSettingsId
          __typename
        }
        users {
          id
          name
          username
          email
          phone
          type
          clientView
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncClientUsers = /* GraphQL */ `
  query SyncClientUsers(
    $filter: ModelClientUsersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClientUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        clientsId
        usersId
        clients {
          id
          name
          googleServiceAdsAccounts
          googleAdsCustomerID
          googleAnalyticsViewID
          ga4MeasurementID
          ga4Secret
          ga4PropertyID
          metaAdsAccount
          metaPixelID
          metaConversionsApiAccessToken
          currency
          bingAdsAccount
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientsCapacitySettingsId
          clientsBidderSettingsId
          clientsCostSyncSettingsId
          __typename
        }
        users {
          id
          name
          username
          email
          phone
          type
          clientView
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getEmployeeLeads = /* GraphQL */ `
  query GetEmployeeLeads($id: ID!) {
    getEmployeeLeads(id: $id) {
      id
      leadsId
      employeesId
      leads {
        id
        name
        description
        type
        details
        value
        status
        state
        twillioSID
        email
        phoneNumber
        crmID
        source
        createdAt
        PhoneNumberID
        Employees {
          nextToken
          startedAt
          __typename
        }
        WebSessionID
        WebSession {
          id
          ipAddress
          status
          startTime
          endTime
          domain
          gacid
          gclid
          fbclid
          msclkid
          referrer
          utmSource
          utmMedium
          utmCampaign
          utmAdgroup
          utmTerm
          utmContent
          utmId
          utmDevice
          utmAdgroupName
          utmPromotion
          queryParameters
          lastPingedOn
          createdAt
          PhoneNumberID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BusinessUnitID
        ChannelID
        Channel {
          id
          name
          budget
          ClientID
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        CampaignID
        ClientID
        ContactID
        Contact {
          id
          name
          emails
          phones
          crmID
          source
          createdAt
          ClientID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Locations {
          id
          name
          street1
          street2
          city
          state
          zip
          crmID
          source
          createdAt
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Jobs {
          id
          name
          description
          status
          value
          completedOn
          crmID
          source
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          LocationID
          LeadID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        callDuration
        recordingDetails {
          recordingURL
          recordingDuration
          recordingSID
          callRating
          __typename
        }
        entrySource
        whatconvertsWebSession
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      employees {
        id
        name
        jobTitle
        Leads {
          nextToken
          startedAt
          __typename
        }
        Job {
          nextToken
          startedAt
          __typename
        }
        ClientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listEmployeeLeads = /* GraphQL */ `
  query ListEmployeeLeads(
    $filter: ModelEmployeeLeadsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployeeLeads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        leadsId
        employeesId
        leads {
          id
          name
          description
          type
          details
          value
          status
          state
          twillioSID
          email
          phoneNumber
          crmID
          source
          createdAt
          PhoneNumberID
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          callDuration
          entrySource
          whatconvertsWebSession
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        employees {
          id
          name
          jobTitle
          ClientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncEmployeeLeads = /* GraphQL */ `
  query SyncEmployeeLeads(
    $filter: ModelEmployeeLeadsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEmployeeLeads(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        leadsId
        employeesId
        leads {
          id
          name
          description
          type
          details
          value
          status
          state
          twillioSID
          email
          phoneNumber
          crmID
          source
          createdAt
          PhoneNumberID
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          callDuration
          entrySource
          whatconvertsWebSession
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        employees {
          id
          name
          jobTitle
          ClientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getEmployeeJobs = /* GraphQL */ `
  query GetEmployeeJobs($id: ID!) {
    getEmployeeJobs(id: $id) {
      id
      jobsId
      employeesId
      jobs {
        id
        name
        description
        status
        value
        completedOn
        crmID
        source
        createdAt
        Employees {
          nextToken
          startedAt
          __typename
        }
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        LocationID
        LeadID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      employees {
        id
        name
        jobTitle
        Leads {
          nextToken
          startedAt
          __typename
        }
        Job {
          nextToken
          startedAt
          __typename
        }
        ClientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listEmployeeJobs = /* GraphQL */ `
  query ListEmployeeJobs(
    $filter: ModelEmployeeJobsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployeeJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        jobsId
        employeesId
        jobs {
          id
          name
          description
          status
          value
          completedOn
          crmID
          source
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          LocationID
          LeadID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        employees {
          id
          name
          jobTitle
          ClientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncEmployeeJobs = /* GraphQL */ `
  query SyncEmployeeJobs(
    $filter: ModelEmployeeJobsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEmployeeJobs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        jobsId
        employeesId
        jobs {
          id
          name
          description
          status
          value
          completedOn
          crmID
          source
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          LocationID
          LeadID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        employees {
          id
          name
          jobTitle
          ClientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const appointmentsByJobID = /* GraphQL */ `
  query AppointmentsByJobID(
    $JobID: ID!
    $start: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAppointmentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    appointmentsByJobID(
      JobID: $JobID
      start: $start
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        crmID
        source
        appointmentNumber
        start
        end
        arrivalWindowStart
        arrivalWindowEnd
        status
        updatedAt
        createdAt
        JobID
        ClientID
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const appointmentsByJobIDByEnd = /* GraphQL */ `
  query AppointmentsByJobIDByEnd(
    $JobID: ID!
    $end: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAppointmentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    appointmentsByJobIDByEnd(
      JobID: $JobID
      end: $end
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        crmID
        source
        appointmentNumber
        start
        end
        arrivalWindowStart
        arrivalWindowEnd
        status
        updatedAt
        createdAt
        JobID
        ClientID
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const appointmentsByClientID = /* GraphQL */ `
  query AppointmentsByClientID(
    $ClientID: ID!
    $start: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAppointmentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    appointmentsByClientID(
      ClientID: $ClientID
      start: $start
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        crmID
        source
        appointmentNumber
        start
        end
        arrivalWindowStart
        arrivalWindowEnd
        status
        updatedAt
        createdAt
        JobID
        ClientID
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const appointmentsByClientIDByEnd = /* GraphQL */ `
  query AppointmentsByClientIDByEnd(
    $ClientID: ID!
    $end: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAppointmentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    appointmentsByClientIDByEnd(
      ClientID: $ClientID
      end: $end
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        crmID
        source
        appointmentNumber
        start
        end
        arrivalWindowStart
        arrivalWindowEnd
        status
        updatedAt
        createdAt
        JobID
        ClientID
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const clientsByTenantID = /* GraphQL */ `
  query ClientsByTenantID(
    $TenantID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelClientsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clientsByTenantID(
      TenantID: $TenantID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        googleServiceAdsAccounts
        googleAdsCustomerID
        googleAnalyticsViewID
        ga4MeasurementID
        ga4Secret
        ga4PropertyID
        metaAdsAccount
        metaPixelID
        metaConversionsApiAccessToken
        currency
        bingAdsAccount
        Users {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        Contacts {
          nextToken
          startedAt
          __typename
        }
        ContactDetails {
          nextToken
          startedAt
          __typename
        }
        Locations {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Invoices {
          nextToken
          startedAt
          __typename
        }
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        BusinessUnits {
          nextToken
          startedAt
          __typename
        }
        Channels {
          nextToken
          startedAt
          __typename
        }
        Campaigns {
          nextToken
          startedAt
          __typename
        }
        Integrations {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        Employees {
          nextToken
          startedAt
          __typename
        }
        Equipment {
          nextToken
          startedAt
          __typename
        }
        Dashboards {
          nextToken
          startedAt
          __typename
        }
        DNIPools {
          nextToken
          startedAt
          __typename
        }
        ProgrammableDNIPools {
          nextToken
          startedAt
          __typename
        }
        TenantID
        clientView {
          nextToken
          startedAt
          __typename
        }
        ServiceTitanQueues {
          nextToken
          startedAt
          __typename
        }
        capacitySettings {
          id
          active
          capacityType
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        bidderSettings {
          id
          blacklistedBusinessUnits
          blacklistedGoogleAdsCampaigns
          excludeFromCapacityBusinessUnits
          budgetDistributer
          active
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BudgetLogs {
          nextToken
          startedAt
          __typename
        }
        costSyncSettings {
          id
          googleAdsActive
          facebookAdsActive
          bingAdsActive
          lsaActive
          googleAdsCpcChannelID
          googleAdsCpmChannelID
          facebookChannelID
          bingChannelID
          lsaChannelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        clientsCapacitySettingsId
        clientsBidderSettingsId
        clientsCostSyncSettingsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const usersByClientView = /* GraphQL */ `
  query UsersByClientView(
    $clientView: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByClientView(
      clientView: $clientView
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        username
        email
        phone
        type
        clientView
        TenantID
        Clients {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const usersByTenantID = /* GraphQL */ `
  query UsersByTenantID(
    $TenantID: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByTenantID(
      TenantID: $TenantID
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        username
        email
        phone
        type
        clientView
        TenantID
        Clients {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const contactsByCrmID = /* GraphQL */ `
  query ContactsByCrmID(
    $crmID: String!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContactsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contactsByCrmID(
      crmID: $crmID
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        emails
        phones
        crmID
        source
        createdAt
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        Locations {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        ContactDetails {
          nextToken
          startedAt
          __typename
        }
        ClientID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const contactsByClientID = /* GraphQL */ `
  query ContactsByClientID(
    $ClientID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContactsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contactsByClientID(
      ClientID: $ClientID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        emails
        phones
        crmID
        source
        createdAt
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        Locations {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        ContactDetails {
          nextToken
          startedAt
          __typename
        }
        ClientID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const contactDetailsByClientID = /* GraphQL */ `
  query ContactDetailsByClientID(
    $ClientID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContactDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contactDetailsByClientID(
      ClientID: $ClientID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        value
        memo
        crmID
        source
        createdAt
        ClientID
        ContactID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const contactDetailsByClientIDByValue = /* GraphQL */ `
  query ContactDetailsByClientIDByValue(
    $ClientID: ID!
    $value: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContactDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contactDetailsByClientIDByValue(
      ClientID: $ClientID
      value: $value
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        value
        memo
        crmID
        source
        createdAt
        ClientID
        ContactID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const contactDetailsByContactID = /* GraphQL */ `
  query ContactDetailsByContactID(
    $ContactID: ID!
    $value: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContactDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contactDetailsByContactID(
      ContactID: $ContactID
      value: $value
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        value
        memo
        crmID
        source
        createdAt
        ClientID
        ContactID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const locationsByCrmID = /* GraphQL */ `
  query LocationsByCrmID(
    $crmID: String!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLocationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    locationsByCrmID(
      crmID: $crmID
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        street1
        street2
        city
        state
        zip
        crmID
        source
        createdAt
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Equipment {
          nextToken
          startedAt
          __typename
        }
        ClientID
        ContactID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const locationsByClientID = /* GraphQL */ `
  query LocationsByClientID(
    $ClientID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLocationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    locationsByClientID(
      ClientID: $ClientID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        street1
        street2
        city
        state
        zip
        crmID
        source
        createdAt
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Equipment {
          nextToken
          startedAt
          __typename
        }
        ClientID
        ContactID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const locationByContactID = /* GraphQL */ `
  query LocationByContactID(
    $ContactID: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLocationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    locationByContactID(
      ContactID: $ContactID
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        street1
        street2
        city
        state
        zip
        crmID
        source
        createdAt
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Equipment {
          nextToken
          startedAt
          __typename
        }
        ClientID
        ContactID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const leadsByEmail = /* GraphQL */ `
  query LeadsByEmail(
    $email: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLeadsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    leadsByEmail(
      email: $email
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        type
        details
        value
        status
        state
        twillioSID
        email
        phoneNumber
        crmID
        source
        createdAt
        PhoneNumberID
        Employees {
          nextToken
          startedAt
          __typename
        }
        WebSessionID
        WebSession {
          id
          ipAddress
          status
          startTime
          endTime
          domain
          gacid
          gclid
          fbclid
          msclkid
          referrer
          utmSource
          utmMedium
          utmCampaign
          utmAdgroup
          utmTerm
          utmContent
          utmId
          utmDevice
          utmAdgroupName
          utmPromotion
          queryParameters
          lastPingedOn
          createdAt
          PhoneNumberID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BusinessUnitID
        ChannelID
        Channel {
          id
          name
          budget
          ClientID
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        CampaignID
        ClientID
        ContactID
        Contact {
          id
          name
          emails
          phones
          crmID
          source
          createdAt
          ClientID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Locations {
          id
          name
          street1
          street2
          city
          state
          zip
          crmID
          source
          createdAt
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Jobs {
          id
          name
          description
          status
          value
          completedOn
          crmID
          source
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          LocationID
          LeadID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        callDuration
        recordingDetails {
          recordingURL
          recordingDuration
          recordingSID
          callRating
          __typename
        }
        entrySource
        whatconvertsWebSession
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const leadsByPhone = /* GraphQL */ `
  query LeadsByPhone(
    $phoneNumber: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLeadsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    leadsByPhone(
      phoneNumber: $phoneNumber
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        type
        details
        value
        status
        state
        twillioSID
        email
        phoneNumber
        crmID
        source
        createdAt
        PhoneNumberID
        Employees {
          nextToken
          startedAt
          __typename
        }
        WebSessionID
        WebSession {
          id
          ipAddress
          status
          startTime
          endTime
          domain
          gacid
          gclid
          fbclid
          msclkid
          referrer
          utmSource
          utmMedium
          utmCampaign
          utmAdgroup
          utmTerm
          utmContent
          utmId
          utmDevice
          utmAdgroupName
          utmPromotion
          queryParameters
          lastPingedOn
          createdAt
          PhoneNumberID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BusinessUnitID
        ChannelID
        Channel {
          id
          name
          budget
          ClientID
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        CampaignID
        ClientID
        ContactID
        Contact {
          id
          name
          emails
          phones
          crmID
          source
          createdAt
          ClientID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Locations {
          id
          name
          street1
          street2
          city
          state
          zip
          crmID
          source
          createdAt
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Jobs {
          id
          name
          description
          status
          value
          completedOn
          crmID
          source
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          LocationID
          LeadID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        callDuration
        recordingDetails {
          recordingURL
          recordingDuration
          recordingSID
          callRating
          __typename
        }
        entrySource
        whatconvertsWebSession
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const leadsByCrmID = /* GraphQL */ `
  query LeadsByCrmID(
    $crmID: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLeadsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    leadsByCrmID(
      crmID: $crmID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        type
        details
        value
        status
        state
        twillioSID
        email
        phoneNumber
        crmID
        source
        createdAt
        PhoneNumberID
        Employees {
          nextToken
          startedAt
          __typename
        }
        WebSessionID
        WebSession {
          id
          ipAddress
          status
          startTime
          endTime
          domain
          gacid
          gclid
          fbclid
          msclkid
          referrer
          utmSource
          utmMedium
          utmCampaign
          utmAdgroup
          utmTerm
          utmContent
          utmId
          utmDevice
          utmAdgroupName
          utmPromotion
          queryParameters
          lastPingedOn
          createdAt
          PhoneNumberID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BusinessUnitID
        ChannelID
        Channel {
          id
          name
          budget
          ClientID
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        CampaignID
        ClientID
        ContactID
        Contact {
          id
          name
          emails
          phones
          crmID
          source
          createdAt
          ClientID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Locations {
          id
          name
          street1
          street2
          city
          state
          zip
          crmID
          source
          createdAt
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Jobs {
          id
          name
          description
          status
          value
          completedOn
          crmID
          source
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          LocationID
          LeadID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        callDuration
        recordingDetails {
          recordingURL
          recordingDuration
          recordingSID
          callRating
          __typename
        }
        entrySource
        whatconvertsWebSession
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const leadsByPhoneNumberID = /* GraphQL */ `
  query LeadsByPhoneNumberID(
    $PhoneNumberID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLeadsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    leadsByPhoneNumberID(
      PhoneNumberID: $PhoneNumberID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        type
        details
        value
        status
        state
        twillioSID
        email
        phoneNumber
        crmID
        source
        createdAt
        PhoneNumberID
        Employees {
          nextToken
          startedAt
          __typename
        }
        WebSessionID
        WebSession {
          id
          ipAddress
          status
          startTime
          endTime
          domain
          gacid
          gclid
          fbclid
          msclkid
          referrer
          utmSource
          utmMedium
          utmCampaign
          utmAdgroup
          utmTerm
          utmContent
          utmId
          utmDevice
          utmAdgroupName
          utmPromotion
          queryParameters
          lastPingedOn
          createdAt
          PhoneNumberID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BusinessUnitID
        ChannelID
        Channel {
          id
          name
          budget
          ClientID
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        CampaignID
        ClientID
        ContactID
        Contact {
          id
          name
          emails
          phones
          crmID
          source
          createdAt
          ClientID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Locations {
          id
          name
          street1
          street2
          city
          state
          zip
          crmID
          source
          createdAt
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Jobs {
          id
          name
          description
          status
          value
          completedOn
          crmID
          source
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          LocationID
          LeadID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        callDuration
        recordingDetails {
          recordingURL
          recordingDuration
          recordingSID
          callRating
          __typename
        }
        entrySource
        whatconvertsWebSession
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const leadsByWebSessionID = /* GraphQL */ `
  query LeadsByWebSessionID(
    $WebSessionID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLeadsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    leadsByWebSessionID(
      WebSessionID: $WebSessionID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        type
        details
        value
        status
        state
        twillioSID
        email
        phoneNumber
        crmID
        source
        createdAt
        PhoneNumberID
        Employees {
          nextToken
          startedAt
          __typename
        }
        WebSessionID
        WebSession {
          id
          ipAddress
          status
          startTime
          endTime
          domain
          gacid
          gclid
          fbclid
          msclkid
          referrer
          utmSource
          utmMedium
          utmCampaign
          utmAdgroup
          utmTerm
          utmContent
          utmId
          utmDevice
          utmAdgroupName
          utmPromotion
          queryParameters
          lastPingedOn
          createdAt
          PhoneNumberID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BusinessUnitID
        ChannelID
        Channel {
          id
          name
          budget
          ClientID
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        CampaignID
        ClientID
        ContactID
        Contact {
          id
          name
          emails
          phones
          crmID
          source
          createdAt
          ClientID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Locations {
          id
          name
          street1
          street2
          city
          state
          zip
          crmID
          source
          createdAt
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Jobs {
          id
          name
          description
          status
          value
          completedOn
          crmID
          source
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          LocationID
          LeadID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        callDuration
        recordingDetails {
          recordingURL
          recordingDuration
          recordingSID
          callRating
          __typename
        }
        entrySource
        whatconvertsWebSession
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const leadsByBusinessUnitID = /* GraphQL */ `
  query LeadsByBusinessUnitID(
    $BusinessUnitID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLeadsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    leadsByBusinessUnitID(
      BusinessUnitID: $BusinessUnitID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        type
        details
        value
        status
        state
        twillioSID
        email
        phoneNumber
        crmID
        source
        createdAt
        PhoneNumberID
        Employees {
          nextToken
          startedAt
          __typename
        }
        WebSessionID
        WebSession {
          id
          ipAddress
          status
          startTime
          endTime
          domain
          gacid
          gclid
          fbclid
          msclkid
          referrer
          utmSource
          utmMedium
          utmCampaign
          utmAdgroup
          utmTerm
          utmContent
          utmId
          utmDevice
          utmAdgroupName
          utmPromotion
          queryParameters
          lastPingedOn
          createdAt
          PhoneNumberID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BusinessUnitID
        ChannelID
        Channel {
          id
          name
          budget
          ClientID
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        CampaignID
        ClientID
        ContactID
        Contact {
          id
          name
          emails
          phones
          crmID
          source
          createdAt
          ClientID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Locations {
          id
          name
          street1
          street2
          city
          state
          zip
          crmID
          source
          createdAt
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Jobs {
          id
          name
          description
          status
          value
          completedOn
          crmID
          source
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          LocationID
          LeadID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        callDuration
        recordingDetails {
          recordingURL
          recordingDuration
          recordingSID
          callRating
          __typename
        }
        entrySource
        whatconvertsWebSession
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const leadsByChannelID = /* GraphQL */ `
  query LeadsByChannelID(
    $ChannelID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLeadsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    leadsByChannelID(
      ChannelID: $ChannelID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        type
        details
        value
        status
        state
        twillioSID
        email
        phoneNumber
        crmID
        source
        createdAt
        PhoneNumberID
        Employees {
          nextToken
          startedAt
          __typename
        }
        WebSessionID
        WebSession {
          id
          ipAddress
          status
          startTime
          endTime
          domain
          gacid
          gclid
          fbclid
          msclkid
          referrer
          utmSource
          utmMedium
          utmCampaign
          utmAdgroup
          utmTerm
          utmContent
          utmId
          utmDevice
          utmAdgroupName
          utmPromotion
          queryParameters
          lastPingedOn
          createdAt
          PhoneNumberID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BusinessUnitID
        ChannelID
        Channel {
          id
          name
          budget
          ClientID
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        CampaignID
        ClientID
        ContactID
        Contact {
          id
          name
          emails
          phones
          crmID
          source
          createdAt
          ClientID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Locations {
          id
          name
          street1
          street2
          city
          state
          zip
          crmID
          source
          createdAt
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Jobs {
          id
          name
          description
          status
          value
          completedOn
          crmID
          source
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          LocationID
          LeadID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        callDuration
        recordingDetails {
          recordingURL
          recordingDuration
          recordingSID
          callRating
          __typename
        }
        entrySource
        whatconvertsWebSession
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const leadsByCampaignID = /* GraphQL */ `
  query LeadsByCampaignID(
    $CampaignID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLeadsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    leadsByCampaignID(
      CampaignID: $CampaignID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        type
        details
        value
        status
        state
        twillioSID
        email
        phoneNumber
        crmID
        source
        createdAt
        PhoneNumberID
        Employees {
          nextToken
          startedAt
          __typename
        }
        WebSessionID
        WebSession {
          id
          ipAddress
          status
          startTime
          endTime
          domain
          gacid
          gclid
          fbclid
          msclkid
          referrer
          utmSource
          utmMedium
          utmCampaign
          utmAdgroup
          utmTerm
          utmContent
          utmId
          utmDevice
          utmAdgroupName
          utmPromotion
          queryParameters
          lastPingedOn
          createdAt
          PhoneNumberID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BusinessUnitID
        ChannelID
        Channel {
          id
          name
          budget
          ClientID
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        CampaignID
        ClientID
        ContactID
        Contact {
          id
          name
          emails
          phones
          crmID
          source
          createdAt
          ClientID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Locations {
          id
          name
          street1
          street2
          city
          state
          zip
          crmID
          source
          createdAt
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Jobs {
          id
          name
          description
          status
          value
          completedOn
          crmID
          source
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          LocationID
          LeadID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        callDuration
        recordingDetails {
          recordingURL
          recordingDuration
          recordingSID
          callRating
          __typename
        }
        entrySource
        whatconvertsWebSession
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const leadsByClientID = /* GraphQL */ `
  query LeadsByClientID(
    $ClientID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLeadsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    leadsByClientID(
      ClientID: $ClientID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        type
        details
        value
        status
        state
        twillioSID
        email
        phoneNumber
        crmID
        source
        createdAt
        PhoneNumberID
        Employees {
          nextToken
          startedAt
          __typename
        }
        WebSessionID
        WebSession {
          id
          ipAddress
          status
          startTime
          endTime
          domain
          gacid
          gclid
          fbclid
          msclkid
          referrer
          utmSource
          utmMedium
          utmCampaign
          utmAdgroup
          utmTerm
          utmContent
          utmId
          utmDevice
          utmAdgroupName
          utmPromotion
          queryParameters
          lastPingedOn
          createdAt
          PhoneNumberID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BusinessUnitID
        ChannelID
        Channel {
          id
          name
          budget
          ClientID
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        CampaignID
        ClientID
        ContactID
        Contact {
          id
          name
          emails
          phones
          crmID
          source
          createdAt
          ClientID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Locations {
          id
          name
          street1
          street2
          city
          state
          zip
          crmID
          source
          createdAt
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Jobs {
          id
          name
          description
          status
          value
          completedOn
          crmID
          source
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          LocationID
          LeadID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        callDuration
        recordingDetails {
          recordingURL
          recordingDuration
          recordingSID
          callRating
          __typename
        }
        entrySource
        whatconvertsWebSession
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const leadsByContactID = /* GraphQL */ `
  query LeadsByContactID(
    $ContactID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLeadsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    leadsByContactID(
      ContactID: $ContactID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        type
        details
        value
        status
        state
        twillioSID
        email
        phoneNumber
        crmID
        source
        createdAt
        PhoneNumberID
        Employees {
          nextToken
          startedAt
          __typename
        }
        WebSessionID
        WebSession {
          id
          ipAddress
          status
          startTime
          endTime
          domain
          gacid
          gclid
          fbclid
          msclkid
          referrer
          utmSource
          utmMedium
          utmCampaign
          utmAdgroup
          utmTerm
          utmContent
          utmId
          utmDevice
          utmAdgroupName
          utmPromotion
          queryParameters
          lastPingedOn
          createdAt
          PhoneNumberID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BusinessUnitID
        ChannelID
        Channel {
          id
          name
          budget
          ClientID
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        CampaignID
        ClientID
        ContactID
        Contact {
          id
          name
          emails
          phones
          crmID
          source
          createdAt
          ClientID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Locations {
          id
          name
          street1
          street2
          city
          state
          zip
          crmID
          source
          createdAt
          ClientID
          ContactID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Jobs {
          id
          name
          description
          status
          value
          completedOn
          crmID
          source
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          LocationID
          LeadID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        callDuration
        recordingDetails {
          recordingURL
          recordingDuration
          recordingSID
          callRating
          __typename
        }
        entrySource
        whatconvertsWebSession
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const jobsByCrmID = /* GraphQL */ `
  query JobsByCrmID(
    $crmID: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJobsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobsByCrmID(
      crmID: $crmID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        status
        value
        completedOn
        crmID
        source
        createdAt
        Employees {
          nextToken
          startedAt
          __typename
        }
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        LocationID
        LeadID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const jobsByBusinessUnitID = /* GraphQL */ `
  query JobsByBusinessUnitID(
    $BusinessUnitID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJobsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobsByBusinessUnitID(
      BusinessUnitID: $BusinessUnitID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        status
        value
        completedOn
        crmID
        source
        createdAt
        Employees {
          nextToken
          startedAt
          __typename
        }
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        LocationID
        LeadID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const jobsByBusinessUnitIDByCompletedOn = /* GraphQL */ `
  query JobsByBusinessUnitIDByCompletedOn(
    $BusinessUnitID: ID!
    $completedOn: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJobsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobsByBusinessUnitIDByCompletedOn(
      BusinessUnitID: $BusinessUnitID
      completedOn: $completedOn
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        status
        value
        completedOn
        crmID
        source
        createdAt
        Employees {
          nextToken
          startedAt
          __typename
        }
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        LocationID
        LeadID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const jobsByChannelID = /* GraphQL */ `
  query JobsByChannelID(
    $ChannelID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJobsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobsByChannelID(
      ChannelID: $ChannelID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        status
        value
        completedOn
        crmID
        source
        createdAt
        Employees {
          nextToken
          startedAt
          __typename
        }
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        LocationID
        LeadID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const jobsByCampaignID = /* GraphQL */ `
  query JobsByCampaignID(
    $CampaignID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJobsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobsByCampaignID(
      CampaignID: $CampaignID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        status
        value
        completedOn
        crmID
        source
        createdAt
        Employees {
          nextToken
          startedAt
          __typename
        }
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        LocationID
        LeadID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const jobsByClientID = /* GraphQL */ `
  query JobsByClientID(
    $ClientID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJobsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobsByClientID(
      ClientID: $ClientID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        status
        value
        completedOn
        crmID
        source
        createdAt
        Employees {
          nextToken
          startedAt
          __typename
        }
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        LocationID
        LeadID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const jobsByClientIDByCompletedOn = /* GraphQL */ `
  query JobsByClientIDByCompletedOn(
    $ClientID: ID!
    $completedOn: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJobsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobsByClientIDByCompletedOn(
      ClientID: $ClientID
      completedOn: $completedOn
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        status
        value
        completedOn
        crmID
        source
        createdAt
        Employees {
          nextToken
          startedAt
          __typename
        }
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        LocationID
        LeadID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const jobsByLocationID = /* GraphQL */ `
  query JobsByLocationID(
    $LocationID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJobsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobsByLocationID(
      LocationID: $LocationID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        status
        value
        completedOn
        crmID
        source
        createdAt
        Employees {
          nextToken
          startedAt
          __typename
        }
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        LocationID
        LeadID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const jobsByLeadID = /* GraphQL */ `
  query JobsByLeadID(
    $LeadID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJobsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobsByLeadID(
      LeadID: $LeadID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        status
        value
        completedOn
        crmID
        source
        createdAt
        Employees {
          nextToken
          startedAt
          __typename
        }
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        LocationID
        LeadID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const invoicesByClientID = /* GraphQL */ `
  query InvoicesByClientID(
    $ClientID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInvoicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invoicesByClientID(
      ClientID: $ClientID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        description
        invoiceDate
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        createdAt
        ClientID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const invoiceItemsByBusinessUnitID = /* GraphQL */ `
  query InvoiceItemsByBusinessUnitID(
    $BusinessUnitID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invoiceItemsByBusinessUnitID(
      BusinessUnitID: $BusinessUnitID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        quantity
        total
        createdAt
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        InvoiceID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const invoiceItemsByChannelID = /* GraphQL */ `
  query InvoiceItemsByChannelID(
    $ChannelID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invoiceItemsByChannelID(
      ChannelID: $ChannelID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        quantity
        total
        createdAt
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        InvoiceID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const invoiceItemsByCampaignID = /* GraphQL */ `
  query InvoiceItemsByCampaignID(
    $CampaignID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invoiceItemsByCampaignID(
      CampaignID: $CampaignID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        quantity
        total
        createdAt
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        InvoiceID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const invoiceItemsByClientID = /* GraphQL */ `
  query InvoiceItemsByClientID(
    $ClientID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invoiceItemsByClientID(
      ClientID: $ClientID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        quantity
        total
        createdAt
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        InvoiceID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const invoiceItemsByInvoiceID = /* GraphQL */ `
  query InvoiceItemsByInvoiceID(
    $InvoiceID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invoiceItemsByInvoiceID(
      InvoiceID: $InvoiceID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        quantity
        total
        createdAt
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        InvoiceID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const businessUnitsByClientID = /* GraphQL */ `
  query BusinessUnitsByClientID(
    $ClientID: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBusinessUnitsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    businessUnitsByClientID(
      ClientID: $ClientID
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        Leads {
          nextToken
          startedAt
          __typename
        }
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        ClientID
        active
        maxCPC
        capacityWindow
        jobDuration
        monthlyBudget
        bidderBlockedBy
        bidderBlocked
        Capacities {
          nextToken
          startedAt
          __typename
        }
        BudgetAdjustments {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const channelsByClientID = /* GraphQL */ `
  query ChannelsByClientID(
    $ClientID: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChannelsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    channelsByClientID(
      ClientID: $ClientID
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        budget
        Leads {
          nextToken
          startedAt
          __typename
        }
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        ClientID
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const campaignsByClientID = /* GraphQL */ `
  query CampaignsByClientID(
    $ClientID: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCampaignsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    campaignsByClientID(
      ClientID: $ClientID
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        Jobs {
          nextToken
          startedAt
          __typename
        }
        Activity {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        InvoiceItems {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        ClientID
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const integrationModelsByTenantID = /* GraphQL */ `
  query IntegrationModelsByTenantID(
    $TenantID: ID!
    $serviceName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIntegrationModelsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    integrationModelsByTenantID(
      TenantID: $TenantID
      serviceName: $serviceName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serviceName
        description
        integrationKeys
        options
        type
        Integrations {
          nextToken
          startedAt
          __typename
        }
        TenantID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const integrationsByIntegrationModelID = /* GraphQL */ `
  query IntegrationsByIntegrationModelID(
    $IntegrationModelID: ID!
    $serviceName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIntegrationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    integrationsByIntegrationModelID(
      IntegrationModelID: $IntegrationModelID
      serviceName: $serviceName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serviceName
        description
        integrationKeys
        options
        IntegrationModelID
        ClientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const integrationsByClientID = /* GraphQL */ `
  query IntegrationsByClientID(
    $ClientID: ID!
    $serviceName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIntegrationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    integrationsByClientID(
      ClientID: $ClientID
      serviceName: $serviceName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serviceName
        description
        integrationKeys
        options
        IntegrationModelID
        ClientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const dNIPoolsByClientID = /* GraphQL */ `
  query DNIPoolsByClientID(
    $ClientID: ID!
    $forwardingNumber: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDNIPoolsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dNIPoolsByClientID(
      ClientID: $ClientID
      forwardingNumber: $forwardingNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        forwardingNumber
        fallbackNumberID
        ClientID
        PhoneNumbers {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const pDNIPoolsByClientID = /* GraphQL */ `
  query PDNIPoolsByClientID(
    $ClientID: ID!
    $forwardingNumber: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProgrammableDNIPoolsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pDNIPoolsByClientID(
      ClientID: $ClientID
      forwardingNumber: $forwardingNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        forwardingNumber
        fallbackNumberID
        isDefault
        order
        usageLogic
        ClientID
        PhoneNumbers {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const phoneNumbersByPoolID = /* GraphQL */ `
  query PhoneNumbersByPoolID(
    $poolID: ID!
    $number: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPhoneNumbersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    phoneNumbersByPoolID(
      poolID: $poolID
      number: $number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        twilioID
        poolID
        pDniPoolID
        reserved
        Leads {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const phoneNumbersByPdniPoolID = /* GraphQL */ `
  query PhoneNumbersByPdniPoolID(
    $pDniPoolID: ID!
    $number: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPhoneNumbersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    phoneNumbersByPdniPoolID(
      pDniPoolID: $pDniPoolID
      number: $number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        twilioID
        poolID
        pDniPoolID
        reserved
        Leads {
          nextToken
          startedAt
          __typename
        }
        WebSessions {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const activitiesByWebSessionID = /* GraphQL */ `
  query ActivitiesByWebSessionID(
    $WebSessionID: ID!
    $title: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activitiesByWebSessionID(
      WebSessionID: $WebSessionID
      title: $title
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        eventTime
        queryParameters
        WebSessionID
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        ContactID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const activitiesByBusinessUnitID = /* GraphQL */ `
  query ActivitiesByBusinessUnitID(
    $BusinessUnitID: ID!
    $title: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activitiesByBusinessUnitID(
      BusinessUnitID: $BusinessUnitID
      title: $title
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        eventTime
        queryParameters
        WebSessionID
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        ContactID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const activitiesByChannelID = /* GraphQL */ `
  query ActivitiesByChannelID(
    $ChannelID: ID!
    $title: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activitiesByChannelID(
      ChannelID: $ChannelID
      title: $title
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        eventTime
        queryParameters
        WebSessionID
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        ContactID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const activitiesByCampaignID = /* GraphQL */ `
  query ActivitiesByCampaignID(
    $CampaignID: ID!
    $title: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activitiesByCampaignID(
      CampaignID: $CampaignID
      title: $title
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        eventTime
        queryParameters
        WebSessionID
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        ContactID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const activitiesByClientID = /* GraphQL */ `
  query ActivitiesByClientID(
    $ClientID: ID!
    $title: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activitiesByClientID(
      ClientID: $ClientID
      title: $title
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        eventTime
        queryParameters
        WebSessionID
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        ContactID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const activitiesByContactID = /* GraphQL */ `
  query ActivitiesByContactID(
    $ContactID: ID!
    $title: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activitiesByContactID(
      ContactID: $ContactID
      title: $title
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        eventTime
        queryParameters
        WebSessionID
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        ContactID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const webSessionsByIpAddress = /* GraphQL */ `
  query WebSessionsByIpAddress(
    $ipAddress: String!
    $ClientID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWebSessionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    webSessionsByIpAddress(
      ipAddress: $ipAddress
      ClientID: $ClientID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ipAddress
        status
        startTime
        endTime
        domain
        gacid
        gclid
        fbclid
        msclkid
        referrer
        utmSource
        utmMedium
        utmCampaign
        utmAdgroup
        utmTerm
        utmContent
        utmId
        utmDevice
        utmAdgroupName
        utmPromotion
        queryParameters
        lastPingedOn
        createdAt
        Activity {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        PhoneNumberID
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        ContactID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const webSessionsbyStatus = /* GraphQL */ `
  query WebSessionsbyStatus(
    $status: String!
    $lastPingedOn: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWebSessionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    webSessionsbyStatus(
      status: $status
      lastPingedOn: $lastPingedOn
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ipAddress
        status
        startTime
        endTime
        domain
        gacid
        gclid
        fbclid
        msclkid
        referrer
        utmSource
        utmMedium
        utmCampaign
        utmAdgroup
        utmTerm
        utmContent
        utmId
        utmDevice
        utmAdgroupName
        utmPromotion
        queryParameters
        lastPingedOn
        createdAt
        Activity {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        PhoneNumberID
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        ContactID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const webSessionsByPhoneNumberID = /* GraphQL */ `
  query WebSessionsByPhoneNumberID(
    $PhoneNumberID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWebSessionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    webSessionsByPhoneNumberID(
      PhoneNumberID: $PhoneNumberID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ipAddress
        status
        startTime
        endTime
        domain
        gacid
        gclid
        fbclid
        msclkid
        referrer
        utmSource
        utmMedium
        utmCampaign
        utmAdgroup
        utmTerm
        utmContent
        utmId
        utmDevice
        utmAdgroupName
        utmPromotion
        queryParameters
        lastPingedOn
        createdAt
        Activity {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        PhoneNumberID
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        ContactID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const webSessionsByBusinessUnitID = /* GraphQL */ `
  query WebSessionsByBusinessUnitID(
    $BusinessUnitID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWebSessionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    webSessionsByBusinessUnitID(
      BusinessUnitID: $BusinessUnitID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ipAddress
        status
        startTime
        endTime
        domain
        gacid
        gclid
        fbclid
        msclkid
        referrer
        utmSource
        utmMedium
        utmCampaign
        utmAdgroup
        utmTerm
        utmContent
        utmId
        utmDevice
        utmAdgroupName
        utmPromotion
        queryParameters
        lastPingedOn
        createdAt
        Activity {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        PhoneNumberID
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        ContactID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const webSessionsByChannelID = /* GraphQL */ `
  query WebSessionsByChannelID(
    $ChannelID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWebSessionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    webSessionsByChannelID(
      ChannelID: $ChannelID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ipAddress
        status
        startTime
        endTime
        domain
        gacid
        gclid
        fbclid
        msclkid
        referrer
        utmSource
        utmMedium
        utmCampaign
        utmAdgroup
        utmTerm
        utmContent
        utmId
        utmDevice
        utmAdgroupName
        utmPromotion
        queryParameters
        lastPingedOn
        createdAt
        Activity {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        PhoneNumberID
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        ContactID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const webSessionsByCampaignID = /* GraphQL */ `
  query WebSessionsByCampaignID(
    $CampaignID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWebSessionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    webSessionsByCampaignID(
      CampaignID: $CampaignID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ipAddress
        status
        startTime
        endTime
        domain
        gacid
        gclid
        fbclid
        msclkid
        referrer
        utmSource
        utmMedium
        utmCampaign
        utmAdgroup
        utmTerm
        utmContent
        utmId
        utmDevice
        utmAdgroupName
        utmPromotion
        queryParameters
        lastPingedOn
        createdAt
        Activity {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        PhoneNumberID
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        ContactID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const webSessionsByClientID = /* GraphQL */ `
  query WebSessionsByClientID(
    $ClientID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWebSessionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    webSessionsByClientID(
      ClientID: $ClientID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ipAddress
        status
        startTime
        endTime
        domain
        gacid
        gclid
        fbclid
        msclkid
        referrer
        utmSource
        utmMedium
        utmCampaign
        utmAdgroup
        utmTerm
        utmContent
        utmId
        utmDevice
        utmAdgroupName
        utmPromotion
        queryParameters
        lastPingedOn
        createdAt
        Activity {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        PhoneNumberID
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        ContactID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const webSessionsByContactID = /* GraphQL */ `
  query WebSessionsByContactID(
    $ContactID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWebSessionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    webSessionsByContactID(
      ContactID: $ContactID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ipAddress
        status
        startTime
        endTime
        domain
        gacid
        gclid
        fbclid
        msclkid
        referrer
        utmSource
        utmMedium
        utmCampaign
        utmAdgroup
        utmTerm
        utmContent
        utmId
        utmDevice
        utmAdgroupName
        utmPromotion
        queryParameters
        lastPingedOn
        createdAt
        Activity {
          nextToken
          startedAt
          __typename
        }
        Leads {
          nextToken
          startedAt
          __typename
        }
        PhoneNumberID
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        ContactID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const serviceTitanQueuesByClientID = /* GraphQL */ `
  query ServiceTitanQueuesByClientID(
    $ClientID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelServiceTitanQueuesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    serviceTitanQueuesByClientID(
      ClientID: $ClientID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        completedOn
        type
        customers
        customerContacts
        locations
        jobs
        invoices
        equipment
        status
        ClientID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const employeesByClientID = /* GraphQL */ `
  query EmployeesByClientID(
    $ClientID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEmployeesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    employeesByClientID(
      ClientID: $ClientID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        jobTitle
        Leads {
          nextToken
          startedAt
          __typename
        }
        Job {
          nextToken
          startedAt
          __typename
        }
        ClientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const dashboardsByClientID = /* GraphQL */ `
  query DashboardsByClientID(
    $ClientID: ID!
    $title: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDashboardsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dashboardsByClientID(
      ClientID: $ClientID
      title: $title
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        category
        embedCode
        ClientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const equipmentByClientID = /* GraphQL */ `
  query EquipmentByClientID(
    $ClientID: ID!
    $installedOn: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEquipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    equipmentByClientID(
      ClientID: $ClientID
      installedOn: $installedOn
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        installedOn
        type
        modelNumber
        serialNumber
        crmID
        source
        ClientID
        LocationID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const equipmentByLocationID = /* GraphQL */ `
  query EquipmentByLocationID(
    $LocationID: ID!
    $installedOn: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEquipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    equipmentByLocationID(
      LocationID: $LocationID
      installedOn: $installedOn
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        installedOn
        type
        modelNumber
        serialNumber
        crmID
        source
        ClientID
        LocationID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const mappingsByBusinessID = /* GraphQL */ `
  query MappingsByBusinessID(
    $BusinessUnitID: ID!
    $type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMappingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    mappingsByBusinessID(
      BusinessUnitID: $BusinessUnitID
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        service
        propertyIDs
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const mappingsByChannelID = /* GraphQL */ `
  query MappingsByChannelID(
    $ChannelID: ID!
    $type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMappingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    mappingsByChannelID(
      ChannelID: $ChannelID
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        service
        propertyIDs
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const mappingsByCampaignID = /* GraphQL */ `
  query MappingsByCampaignID(
    $CampaignID: ID!
    $type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMappingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    mappingsByCampaignID(
      CampaignID: $CampaignID
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        service
        propertyIDs
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const mappingsByClientID = /* GraphQL */ `
  query MappingsByClientID(
    $ClientID: ID!
    $type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMappingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    mappingsByClientID(
      ClientID: $ClientID
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        service
        propertyIDs
        BusinessUnitID
        ChannelID
        CampaignID
        ClientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const capacitySettingsByClientID = /* GraphQL */ `
  query CapacitySettingsByClientID(
    $clientID: ID!
    $capacityType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCapacitySettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    capacitySettingsByClientID(
      clientID: $clientID
      capacityType: $capacityType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        active
        capacityType
        clientID
        client {
          id
          name
          googleServiceAdsAccounts
          googleAdsCustomerID
          googleAnalyticsViewID
          ga4MeasurementID
          ga4Secret
          ga4PropertyID
          metaAdsAccount
          metaPixelID
          metaConversionsApiAccessToken
          currency
          bingAdsAccount
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientsCapacitySettingsId
          clientsBidderSettingsId
          clientsCostSyncSettingsId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const capacitiesByBusinessUnitID = /* GraphQL */ `
  query CapacitiesByBusinessUnitID(
    $businessUnitID: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCapacitiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    capacitiesByBusinessUnitID(
      businessUnitID: $businessUnitID
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        openHours
        totalHours
        businessUnitID
        businessUnit {
          id
          name
          ClientID
          active
          maxCPC
          capacityWindow
          jobDuration
          monthlyBudget
          bidderBlockedBy
          bidderBlocked
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const bidderSettingsByClientID = /* GraphQL */ `
  query BidderSettingsByClientID(
    $clientID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBidderSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bidderSettingsByClientID(
      clientID: $clientID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        blacklistedBusinessUnits
        blacklistedGoogleAdsCampaigns
        excludeFromCapacityBusinessUnits
        budgetDistributer
        active
        clientID
        client {
          id
          name
          googleServiceAdsAccounts
          googleAdsCustomerID
          googleAnalyticsViewID
          ga4MeasurementID
          ga4Secret
          ga4PropertyID
          metaAdsAccount
          metaPixelID
          metaConversionsApiAccessToken
          currency
          bingAdsAccount
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientsCapacitySettingsId
          clientsBidderSettingsId
          clientsCostSyncSettingsId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const budgetAdjustmentsByBusinessUnitID = /* GraphQL */ `
  query BudgetAdjustmentsByBusinessUnitID(
    $businessUnitID: ID!
    $dateApplied: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBudgetAdjustmentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    budgetAdjustmentsByBusinessUnitID(
      businessUnitID: $businessUnitID
      dateApplied: $dateApplied
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        businessUnitID
        businessUnit {
          id
          name
          ClientID
          active
          maxCPC
          capacityWindow
          jobDuration
          monthlyBudget
          bidderBlockedBy
          bidderBlocked
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        adjustment
        reason
        dateApplied
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const budgetLogsByClientID = /* GraphQL */ `
  query BudgetLogsByClientID(
    $clientID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBudgetLogsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    budgetLogsByClientID(
      clientID: $clientID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bidType
        bidProperty
        propertyId
        previousValue
        updatedValue
        description
        createdAt
        clientID
        client {
          id
          name
          googleServiceAdsAccounts
          googleAdsCustomerID
          googleAnalyticsViewID
          ga4MeasurementID
          ga4Secret
          ga4PropertyID
          metaAdsAccount
          metaPixelID
          metaConversionsApiAccessToken
          currency
          bingAdsAccount
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientsCapacitySettingsId
          clientsBidderSettingsId
          clientsCostSyncSettingsId
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const clientUsersByClientsId = /* GraphQL */ `
  query ClientUsersByClientsId(
    $clientsId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelClientUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clientUsersByClientsId(
      clientsId: $clientsId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientsId
        usersId
        clients {
          id
          name
          googleServiceAdsAccounts
          googleAdsCustomerID
          googleAnalyticsViewID
          ga4MeasurementID
          ga4Secret
          ga4PropertyID
          metaAdsAccount
          metaPixelID
          metaConversionsApiAccessToken
          currency
          bingAdsAccount
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientsCapacitySettingsId
          clientsBidderSettingsId
          clientsCostSyncSettingsId
          __typename
        }
        users {
          id
          name
          username
          email
          phone
          type
          clientView
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const clientUsersByUsersId = /* GraphQL */ `
  query ClientUsersByUsersId(
    $usersId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelClientUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clientUsersByUsersId(
      usersId: $usersId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientsId
        usersId
        clients {
          id
          name
          googleServiceAdsAccounts
          googleAdsCustomerID
          googleAnalyticsViewID
          ga4MeasurementID
          ga4Secret
          ga4PropertyID
          metaAdsAccount
          metaPixelID
          metaConversionsApiAccessToken
          currency
          bingAdsAccount
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientsCapacitySettingsId
          clientsBidderSettingsId
          clientsCostSyncSettingsId
          __typename
        }
        users {
          id
          name
          username
          email
          phone
          type
          clientView
          TenantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const employeeLeadsByLeadsId = /* GraphQL */ `
  query EmployeeLeadsByLeadsId(
    $leadsId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEmployeeLeadsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    employeeLeadsByLeadsId(
      leadsId: $leadsId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        leadsId
        employeesId
        leads {
          id
          name
          description
          type
          details
          value
          status
          state
          twillioSID
          email
          phoneNumber
          crmID
          source
          createdAt
          PhoneNumberID
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          callDuration
          entrySource
          whatconvertsWebSession
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        employees {
          id
          name
          jobTitle
          ClientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const employeeLeadsByEmployeesId = /* GraphQL */ `
  query EmployeeLeadsByEmployeesId(
    $employeesId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEmployeeLeadsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    employeeLeadsByEmployeesId(
      employeesId: $employeesId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        leadsId
        employeesId
        leads {
          id
          name
          description
          type
          details
          value
          status
          state
          twillioSID
          email
          phoneNumber
          crmID
          source
          createdAt
          PhoneNumberID
          WebSessionID
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          ContactID
          callDuration
          entrySource
          whatconvertsWebSession
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        employees {
          id
          name
          jobTitle
          ClientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const employeeJobsByJobsId = /* GraphQL */ `
  query EmployeeJobsByJobsId(
    $jobsId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEmployeeJobsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    employeeJobsByJobsId(
      jobsId: $jobsId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        jobsId
        employeesId
        jobs {
          id
          name
          description
          status
          value
          completedOn
          crmID
          source
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          LocationID
          LeadID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        employees {
          id
          name
          jobTitle
          ClientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const employeeJobsByEmployeesId = /* GraphQL */ `
  query EmployeeJobsByEmployeesId(
    $employeesId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEmployeeJobsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    employeeJobsByEmployeesId(
      employeesId: $employeesId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        jobsId
        employeesId
        jobs {
          id
          name
          description
          status
          value
          completedOn
          crmID
          source
          createdAt
          BusinessUnitID
          ChannelID
          CampaignID
          ClientID
          LocationID
          LeadID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        employees {
          id
          name
          jobTitle
          ClientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
