import PropTypes from "prop-types";
import { useRef, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { openSnackbar } from "store/reducers/snackbar";
import { API, graphqlOperation, Hub } from "aws-amplify";
import {
  Box,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Dialog,
  Grid,
  Paper,
  Popper,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";

// project import
import Avatar from "components/@extended/Avatar";
import MainCard from "components/MainCard";
import Transitions from "components/@extended/Transitions";
import IconButton from "components/@extended/IconButton";
import useAuth from "hooks/useAuth";
import ProfileTab from "./ProfileTab";
import SettingTab from "./SettingTab";

// assets
import avatar1 from "assets/images/users/avatar-1.png";
import {
  LogoutOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import UpdateUsers from "sections/user-list/UpdateUser";
import FCUsers from "components/Forms/UsersTemplate";
import { getUsers } from "graphql/queries";
// tab panel wrapper
function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`profile-tabpanel-${index}`}
      aria-labelledby={`profile-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `profile-tab-${index}`,
    "aria-controls": `profile-tabpanel-${index}`,
  };
}

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
  const { logout, user } = useAuth();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [update, setUpdate] = useState(false); //?

  function useUser() {
    let id = user?.id;
    return useQuery({
      enabled: !!id,
      queryKey: ["User", id],
      queryFn: async () => {
        const response = await API.graphql({
          query: getUsers,
          variables: {
            id: id,
          },
        });
        return response.data.getUsers;
      },
    });
  }
  const { status: statusUser, data: dataUser, error: errorUser } = useUser();

  let handleSnacks = (item, type, error) => {
    let types = {
      Add: ["Added", "Adding"],
      Update: ["Updated", "Updating"],
      Del: ["Deleted", "Deleting"],
    };
    let sMsg = `${item} ${types[type][0]} Successfully!`;
    let eMsg = `Error ${types[type][1]} ${item}!`;
    if (error) {
      dispatch(
        openSnackbar({
          open: true,
          message: eMsg,
          variant: "alert",
          alert: {
            color: "error",
          },
          close: false,
        })
      );
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: sMsg,
          variant: "alert",
          alert: {
            color: "success",
          },
          close: false,
        })
      );
    }
  };

  const handleUpdate = () => {
    setUpdate(!update);
    // if (thisUser && !update) setThisUser(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
    } catch (err) {
      console.error(err);
    }
  };

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const iconBackColorOpen =
    theme.palette.mode === "dark" ? "grey.200" : "grey.300";

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <ButtonBase
        sx={{
          p: 0.25,
          bgcolor: open ? iconBackColorOpen : "transparent",
          borderRadius: 1,
          "&:hover": {
            bgcolor:
              theme.palette.mode === "dark"
                ? "secondary.light"
                : "secondary.lighter",
          },
          "&:focus-visible": {
            outline: `2px solid ${theme.palette.secondary.dark}`,
            outlineOffset: 2,
          },
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? "profile-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
          {/* <Avatar alt="profile user" src={avatar1} size="xs" /> */}
          <Typography variant="subtitle1">{dataUser?.username}</Typography>
        </Stack>
      </ButtonBase>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            {open && (
              <Paper
                sx={{
                  boxShadow: theme.customShadows.z1,
                  width: 290,
                  minWidth: 240,
                  maxWidth: 290,
                  [theme.breakpoints.down("md")]: {
                    maxWidth: 250,
                  },
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MainCard elevation={0} border={false} content={false}>
                    <CardContent sx={{ px: 2.5, pt: 3 }}>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid
                          item
                          xs={10}
                          sx={{
                            p: 2,
                            cursor: "pointer",
                            "&:hover": { boxShadow: theme.customShadows.z1 },
                          }}
                          onClick={() => {
                            handleUpdate();
                          }}
                        >
                          <Stack
                            direction="row"
                            spacing={1.25}
                            alignItems="center"
                          >
                            {/* <Avatar
                              alt="profile user"
                              src={avatar1}
                              sx={{ width: 32, height: 32 }}
                            /> */}
                            <Stack>
                              <Typography variant="h6">
                                {dataUser?.name}
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                {dataUser?.username}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Grid>
                        <Grid item xs={2}>
                          <Tooltip title="Logout">
                            <IconButton
                              size="large"
                              sx={{ color: "text.primary" }}
                              onClick={handleLogout}
                            >
                              <LogoutOutlined />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <FCUsers
                      // dataUsers={dataUsers}
                      handleSnacks={handleSnacks}
                      open={update}
                      onClose={() => {
                        setUpdate(false);
                      }}
                      data={dataUser}
                    />
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Profile;
