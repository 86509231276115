import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

// third-party
import { Provider as ReduxProvider } from "react-redux";

// scroll bar
import "simplebar/src/simplebar.css";

// apex-chart
import "assets/third-party/apex-chart.css";
import "assets/third-party/react-table.css";

// load mock apis
import "_api";

// project import
import App from "./App";
import { store } from "store";
import { FetchProvider } from "contexts/FetchContext";
import { ConfigProvider } from "contexts/ConfigContext";
import { AuthProvider } from "contexts/AmplifyAuthContext";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
import { Authenticator } from "@aws-amplify/ui-react";
import { ThemeProvider } from "@aws-amplify/ui-react";
import { studioTheme } from "ui-components";
import "@aws-amplify/ui-react/styles.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

Amplify.configure(awsconfig);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const container = document.getElementById("root");
const root = createRoot(container);

// const root = ReactDOM.createRoot(document.getElementById('root'));

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

root.render(
  <ReduxProvider store={store}>
    <ConfigProvider>
      <ThemeProvider theme={studioTheme}>
        <Authenticator.Provider>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <FetchProvider>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </FetchProvider>
            </AuthProvider>
          </QueryClientProvider>
        </Authenticator.Provider>
      </ThemeProvider>
    </ConfigProvider>
  </ReduxProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
