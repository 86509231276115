import { useRoutes } from "react-router-dom";

// project import
import AllRoutes from "./AllRoutes";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([AllRoutes]);
}
