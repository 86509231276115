// project import
import Routes from "routes";
import ThemeCustomization from "themes";
import Locales from "components/Locales";
// import RTLLayout from 'components/RTLLayout';
import ScrollTop from "components/ScrollTop";
import Snackbar from "components/@extended/Snackbar";
import { ThemeProvider } from "@aws-amplify/ui-react";
import { studioTheme } from "ui-components";

// auth provider
import { FirebaseProvider as AuthProvider } from "contexts/FirebaseContext";
import useAuth from "hooks/useAuth";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { unstable_HistoryRouter } from "react-router-dom";
import "./App.css";
import { DataStore, Hub } from "aws-amplify";

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {
  const { user } = useAuth();
  const location = useLocation();
  const params = useParams();
  const [userType, setUserType] = useState(null);
  const [reload, setReload] = useState();

  useEffect(() => {
    async function Sync() {
      await DataStore.start();
      return DataStore.state;
    }
    Sync();
    // .then((res) => console.log(res))
    // .catch((err) => console.log(err));
  }, []);

  // useEffect(() => {
  //   const listener = Hub.listen("datastore", async (hubData) => {
  //     const { event, data } = hubData.payload;
  //     if (event === "ready" || (event === "outboxStatus" && data.isEmpty)) {
  //       console.log("READY!!!", data);
  //       setReload(reload + 1);
  //     } else {
  //       console.log(event, data);
  //     }
  //   });
  // }, []);

  return (
    <ThemeProvider theme={studioTheme}>
      <ThemeCustomization>
        {/* <RTLLayout> */}
        <Locales>
          <ScrollTop>
            <AuthProvider>
              <>
                <Routes />
                <Snackbar />
              </>
            </AuthProvider>
          </ScrollTop>
        </Locales>
        {/* </RTLLayout> */}
      </ThemeCustomization>
    </ThemeProvider>
  );
};

export default App;
